import { billingConfig } from '@blissbook/common/billing/config'
import { immerable } from 'immer'
import last from 'lodash/last'
import { fromJSON, mapJSONValues } from '../util/json'
import { CreditCard } from './creditCard'
import { getTermLabel } from './util'

export default class Invoice {
  static get ATTRIBUTES() {
    return {
      paidAt: (v) => fromJSON.date(v),
      lastErrorAt: (v) => fromJSON.date(v),
      createdAt: (v) => fromJSON.date(v),
      updatedAt: (v) => fromJSON.date(v),
      creditCard: (v) => CreditCard.fromJSON(v),
    }
  }

  static mapJSON(json) {
    return mapJSONValues(json, Invoice.ATTRIBUTES)
  }

  static fromJSON(json) {
    return Object.assign(new Invoice(), Invoice.mapJSON(json))
  }

  get url() {
    return '/invoices/' + this.id
  }

  get printUrl() {
    return this.url + '.pdf'
  }

  get termDays() {
    return Math.round(this.termMonths * billingConfig.daysPerMonth)
  }

  get termLabel() {
    return getTermLabel(this.termMonths)
  }
}

Invoice[immerable] = true

export const getCurrentInvoice = (invoices) => {
  const invoice = last(invoices)
  if (invoice && !invoice.paidAt) return invoice
}
