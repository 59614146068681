import { formatPrice } from '@blissbook/common/billing/price'
import { Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import moment from 'moment'
import React from 'react'
import {
  BillingEmailLayout,
  type BillingEmailProps,
  getBillingUrl,
  getInvoicePdfUrl,
} from '../template'

export const type = 'billing/check/overdue-30-days'

export function renderEmail(props: BillingEmailProps) {
  const { invoice, organization, to } = props
  const billingUrl = getBillingUrl(organization)
  const invoicePdfUrl = getInvoicePdfUrl(organization, invoice)
  return {
    subject: 'Your Blissbook invoice is overdue',
    body: (
      <BillingEmailLayout {...props}>
        <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
        <Paragraph />
        <Paragraph>
          Your recent invoice (see attached) for{' '}
          {formatPrice(invoice.totalPrice)} USD was due on{' '}
          {moment(invoice.dueDate).format('YYYY-MM-DD')}.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          Can you see if a check was sent yet? It’s 30 days past due and our
          kids are getting hungry. =)
        </Paragraph>
        <Paragraph />
        <Paragraph>
          If you're an Admin on your Blissbook account, you can view, download,
          or print this invoice here:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={invoicePdfUrl}>{invoicePdfUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          And you can see all of your subscription details on your subscription
          page here:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={billingUrl}>{billingUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          If you have any questions, need help, or want to arrange alternative
          payment, please reply to this email or contact us at
          support@blissbook.com.
        </Paragraph>
        <Paragraph />
        <Paragraph>Thank you!</Paragraph>
        <Paragraph />
        <Paragraph>The Blissbook Team</Paragraph>
      </BillingEmailLayout>
    ),
  }
}
