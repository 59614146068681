import { getPeople } from '@blissbook/application/actions'
import { useSavedSegments } from '@blissbook/application/graph'
import { useGroups, usePersonName } from '@blissbook/application/hooks'
import { Person } from '@blissbook/common/person'
import { Variant, variantTypeMetas } from '@blissbook/lib/expression'
import { isValidEmailAddress } from '@blissbook/lib/sanitize'
import { colors } from '@blissbook/ui/branding'
import { Popper, SearchInput } from '@blissbook/ui/lib'
import compoundSubject from 'compound-subject'
import React, { forwardRef, useMemo, useState } from 'react'

export const includeColor = colors['green-800']
export const excludeColor = colors['red-700']

const mapGroupToOption = (group) => ({
  groupId: group.id,
  icon: ['far', 'user-friends'],
  key: group.uid,
  label: group.name,
  object: group,
  title: `Group: ${group.name}`,
})

const mapPersonToOption = (person) => ({
  email: person.email,
  icon: ['far', 'user'],
  key: person.uid,
  label: `${person.fullName} <${person.loginId}>`,
  object: person,
  personId: person.id,
})

const mapSavedSegmentToOption = (savedSegment) => ({
  icon: ['far', variantTypeMetas.savedSegment.icon],
  key: new Variant('savedSegment', savedSegment.id).uid,
  label: `Saved Segment: ${savedSegment.name}`,
  object: savedSegment,
  savedSegmentId: savedSegment.id,
})

export const EmailSearchInput = forwardRef(
  ({ notEmails = [], onSelect, ...props }, ref) => {
    // Determine callback get options
    const getOptions = async (search) => {
      search = search.toLowerCase()
      const options = []

      // Existing People
      const people = await getPeople({
        canNotify: true,
        search,
      })
      options.push(...people.map(mapPersonToOption))

      // New Person
      const existingPerson = options.some((option) => option.email === search)
      if (!existingPerson && isValidEmailAddress(search)) {
        options.push({
          email: search,
          key: 'new-person',
          icon: ['far', 'user-plus'],
          label: `Add ${search}`,
        })
      }

      return options.filter((option) => !notEmails.includes(option.email))
    }

    return (
      <SearchInput
        placeholder='Enter a Name or Email Address'
        {...props}
        getOptions={getOptions}
        onSelect={(option) => onSelect(option.email)}
        ref={ref}
      />
    )
  },
)

export const EmailAddButton = ({
  menuPlacement = 'bottom-start',
  notEmails,
  onSelect,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Popper.Provider isOpen={isOpen} setOpen={setOpen}>
      <Popper.ToggleButton {...props} />

      <Popper.Menu css={{ width: 400 }} placement={menuPlacement}>
        <h3 className='tw-mt-0'>Who do you want to add?</h3>
        <EmailSearchInput
          autoFocus
          notEmails={notEmails}
          onSelect={(email) => {
            onSelect(email)
            setOpen(false)
          }}
        />
      </Popper.Menu>
    </Popper.Provider>
  )
}

export const PersonName = React.memo(({ id, showAdmin }) => {
  return usePersonName(id, showAdmin)
})

export const VariantSearchInput = forwardRef(
  (
    {
      notVariants = [],
      onSelect,
      options = { groups: true, people: true },
      ...props
    },
    ref,
  ) => {
    // Parse options
    const showOptions = options

    // Get available groups / saved segments
    const allGroups = useGroups()
    const allSavedSegments = useSavedSegments()

    // Determine the default placeholder
    const placeholder = useMemo(() => {
      const types = [
        showOptions.groups && 'group',
        showOptions.people && 'name',
        showOptions.people && 'email',
        showOptions.savedSegments && 'segment',
      ].filter(Boolean)

      const text = compoundSubject(types).delimitAll().endWith('or').make()
      return `Enter a ${text}...`
    }, [showOptions.groups, showOptions.people, showOptions.savedSegments])

    // Determine callback get options
    async function getOptions(text) {
      const options = []
      const search = text.toLowerCase()

      // Saved Segments
      if (showOptions.savedSegments) {
        const notIds = Variant.getIds(notVariants, 'savedSegment')
        const savedSegments = allSavedSegments.filter(
          (savedSegment) =>
            !savedSegment.archivedAt &&
            !notIds.includes(savedSegment.id) &&
            savedSegment.name.toLowerCase().includes(search),
        )
        options.push(...savedSegments.map(mapSavedSegmentToOption))
      }

      // Groups
      if (showOptions.groups) {
        const notIds = Variant.getIds(notVariants, 'group')
        const groups = allGroups.filter(
          (group) =>
            !group.archived &&
            !notIds.includes(group.id) &&
            group.name.toLowerCase().includes(search),
        )
        options.push(...groups.map(mapGroupToOption))
      }

      // People
      if (showOptions.people) {
        const notIds = Variant.getIds(notVariants, 'person')
        const people = await getPeople({
          limit: 50,
          notIds,
          search,
        })
        options.push(...people.map(mapPersonToOption))
      }

      // New Person
      const existingPerson = options.some(
        (option) => option.object.email === search,
      )
      if (showOptions.newPerson && !existingPerson) {
        const person = Person.fromText(text)
        options.push({
          key: 'new-person',
          icon: ['far', 'user-plus'],
          label: `Add ${text}`,
          object: person,
        })
      }

      return options
    }

    return (
      <SearchInput
        placeholder={placeholder}
        {...props}
        getOptions={getOptions}
        onSelect={(option) => onSelect(option.object)}
        ref={ref}
      />
    )
  },
)

export const VariantAddButton = ({
  Form,
  formProps,
  notVariants,
  onSubmit,
  options,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false)
  const [object, setObject] = useState()
  return (
    <Popper.Provider
      isOpen={isOpen}
      setOpen={(isOpen) => {
        if (!isOpen) setObject(undefined)
        setOpen(isOpen)
      }}
    >
      <Popper.ToggleButton {...props} />
      <Popper.Menu style={{ minWidth: 400 }}>
        <Choose>
          <When condition={!object}>
            <h3 className='tw-mt-0'>Who do you want to add?</h3>
            <VariantSearchInput
              autoFocus
              notVariants={notVariants}
              onSelect={setObject}
              options={options}
            />
          </When>
          <Otherwise>
            <Form
              {...formProps}
              onClose={() => {
                setObject(undefined)
                setOpen(false)
              }}
              object={object}
            />
          </Otherwise>
        </Choose>
      </Popper.Menu>
    </Popper.Provider>
  )
}
