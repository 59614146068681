import { immerable } from 'immer'
import { fromJSON, mapJSONValues } from '../util/json'

// biome-ignore lint/complexity/noStaticOnlyClass: requires some rework, for models it has to be classes
export default class Signature {
  static get ATTRIBUTES() {
    return {
      createdAt: (v) => fromJSON.date(v),
      signedAt: (v) => fromJSON.date(v),
    }
  }

  static mapJSON(json) {
    return mapJSONValues(json, Signature.ATTRIBUTES)
  }

  static fromJSON(json) {
    return Object.assign(new Signature(), Signature.mapJSON(json))
  }
}

Signature[immerable] = true
