import { EmailLayout, Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import React from 'react'
import type { EmailBranding } from '../branding'
import type { IEmailOrganization, IEmailPerson } from '../types'

export type TestEmailArgs = {
  branding: EmailBranding
  organization: IEmailOrganization
  person?: IEmailPerson
}

export const TestEmail: React.FC<TestEmailArgs> = ({
  organization,
  person,
  ...props
}) => (
  <EmailLayout
    {...props}
    callToAction={{
      text: 'Call to Action Button',
      href: organization.origin + '/email/branding',
    }}
  >
    <Paragraph>{renderGreeting(person?.fullName)},</Paragraph>
    <Paragraph />
    <Paragraph>
      This is a preview of an email message sent to a member of{' '}
      {organization.name}. You triggered it to test its branding or another
      email setting.
    </Paragraph>
    <Paragraph />
    <Paragraph>
      All email messages have a call-to-action button at the top that goes to
      the handbook with a customizable message underneath, just like this email
      does.
    </Paragraph>
    <Paragraph />
    <Paragraph>
      If you need any help, please email the Blissbook team at{' '}
      <Link href='mailto:support@blissbook.com'>support@blissbook.com</Link>.
    </Paragraph>
    <Paragraph />
    <Paragraph>Have a great day!</Paragraph>
    <Paragraph />
    <Paragraph>
      Team Blissbook
      <br />
      support@blissbook.com
    </Paragraph>
  </EmailLayout>
)

export function renderTestEmail(props: TestEmailArgs) {
  return {
    subject: 'Blissbook Test Email',
    body: <TestEmail {...props} />,
  }
}
