import Organization from '@blissbook/common/organization'
import { Session } from '@blissbook/common/session'
export * from '@blissbook/ui/util/store'
import { Store } from '@blissbook/ui/util/store'
import * as reducers from './reducers'

const { organization, session } = window.__STATE__

export const initialState = {
  handbooksById: {},
  handbookRecipients: {},
  peopleById: {},
  organization: organization && Organization.fromJSON(organization),
  session: Session.fromJSON(session),
}

window.__STORE__ = Store.create(initialState)
export const store = window.__STORE__

export const dispatch = (type, payload) => {
  const reducer = reducers[type]
  if (!reducer) return store.state

  const state = reducer(store.state, payload)
  return store.setState(state)
}
