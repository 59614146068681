import { gql } from '@apollo/client'
import {
  AudienceDashboardSettingsFragmentDoc,
  CustomDomainFragmentDoc,
  OrganizationFragmentDoc,
  OrganizationTaskFragmentDoc,
  SecuritySettingsFragmentDoc,
  SingleSignOnFragmentDoc,
  TriggerHrisSyncDocument,
} from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'

// People ---------------------------------------------------------------------

export const syncPeople = async () => {
  const { data } = await client.mutate({
    mutation: TriggerHrisSyncDocument,
  })
  return data.triggerHrisSync
}

export const setAudienceDashboardSettings = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setAudienceDashboardSettings(
        $input: AudienceDashboardSettingsInput!
      ) {
        audienceDashboardSettings: setAudienceDashboardSettings(input: $input) {
          ...audienceDashboardSettings
        }
      }

      ${AudienceDashboardSettingsFragmentDoc}
    `,
    variables: { input },
  })
  return data.audienceDashboardSettings
}

export const setSubdomain = async (subdomain) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setSubdomain($subdomain: Subdomain!) {
        organization: setSubdomain(subdomain: $subdomain) {
          ...organization
        }
      }

      ${OrganizationFragmentDoc}
    `,
    variables: { subdomain },
  })
  return data.organization
}

export const updateOrganization = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateOrganization($input: OrganizationInput!) {
        organization: updateOrganization(input: $input) {
          ...organization
        }
      }

      ${OrganizationFragmentDoc}
    `,
    variables: { input },
  })
  return data.organization
}

export const updateOrganizationAdmin = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateOrganizationAdmin($input: OrganizationAdminInput!) {
        organization: updateOrganizationAdmin(input: $input) {
          ...organization
        }
      }

      ${OrganizationFragmentDoc}
    `,
    variables: { input },
  })
  return data.organization
}

export const setOrganizationTaskComplete = async (taskId, complete) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setOrganizationTaskComplete(
        $taskId: OrganizationTaskId!
        $complete: Boolean!
      ) {
        task: setOrganizationTaskComplete(
          taskId: $taskId
          complete: $complete
        ) {
          ...organizationTask
        }
      }

      ${OrganizationTaskFragmentDoc}
    `,
    variables: { taskId, complete },
  })
  return data.task
}

export const addSingleSignOn = async (providerId, input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation addSingleSignOn(
        $providerId: SingleSignOnProviderId!
        $input: NewSingleSignOnInput
      ) {
        singleSignOn: addSingleSignOn(providerId: $providerId, input: $input) {
          ...singleSignOn
        }
      }

      ${SingleSignOnFragmentDoc}
    `,
    variables: { providerId, input },
  })

  return data.singleSignOn
}

export const patchSingleSignOn = async (providerId, input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation patchSingleSignOn(
        $providerId: SingleSignOnProviderId!
        $input: SingleSignOnInput!
      ) {
        singleSignOn: patchSingleSignOn(
          providerId: $providerId
          input: $input
        ) {
          ...singleSignOn
        }
      }

      ${SingleSignOnFragmentDoc}
    `,
    variables: { providerId, input },
  })

  return data.singleSignOn
}

export const removeSingleSignOn = async (providerId) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation removeSingleSignOn($providerId: SingleSignOnProviderId!) {
        securitySettings: removeSingleSignOn(providerId: $providerId) {
          ...securitySettings
        }
      }

      ${SecuritySettingsFragmentDoc}
    `,
    variables: { providerId },
  })

  return data.securitySettings
}

export const addSingleSignOnCertificate = async (providerId, certificate) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation addSingleSignOnCertificate(
        $providerId: SingleSignOnProviderId!
        $certificate: String!
      ) {
        securitySettings: addSingleSignOnCertificate(
          providerId: $providerId
          certificate: $certificate
        ) {
          ...securitySettings
        }
      }

      ${SecuritySettingsFragmentDoc}
    `,
    variables: { providerId, certificate },
  })

  return data.securitySettings
}

export const archiveSingleSignOnCertificate = async (providerId, digest) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation archiveSingleSignOnCertificate(
        $providerId: SingleSignOnProviderId!
        $digest: String!
      ) {
        securitySettings: archiveSingleSignOnCertificate(
          providerId: $providerId
          digest: $digest
        ) {
          ...securitySettings
        }
      }

      ${SecuritySettingsFragmentDoc}
    `,
    variables: { providerId, digest },
  })

  return data.securitySettings
}

export const deleteSingleSignOnCertificate = async (providerId, digest) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation deleteSingleSignOnCertificate(
        $providerId: SingleSignOnProviderId!
        $digest: String!
      ) {
        securitySettings: deleteSingleSignOnCertificate(
          providerId: $providerId
          digest: $digest
        ) {
          ...securitySettings
        }
      }

      ${SecuritySettingsFragmentDoc}
    `,
    variables: { providerId, digest },
  })

  return data.securitySettings
}

export const unarchiveSingleSignOnCertificate = async (providerId, digest) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation unarchiveSingleSignOnCertificate(
        $providerId: SingleSignOnProviderId!
        $digest: String!
      ) {
        securitySettings: unarchiveSingleSignOnCertificate(
          providerId: $providerId
          digest: $digest
        ) {
          ...securitySettings
        }
      }

      ${SecuritySettingsFragmentDoc}
    `,
    variables: { providerId, digest },
  })

  return data.securitySettings
}

export const setEmailAuthSettings = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setEmailAuthSettings($input: EmailAuthSettingsInput!) {
        securitySettings: setEmailAuthSettings(input: $input) {
          ...securitySettings
        }
      }

      ${SecuritySettingsFragmentDoc}
    `,
    variables: { input },
  })

  return data.securitySettings
}

export const setKioskModeSettings = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setKioskModeSettings($input: KioskModeSettingsInput!) {
        securitySettings: setKioskModeSettings(input: $input) {
          ...securitySettings
        }
      }

      ${SecuritySettingsFragmentDoc}
    `,
    variables: { input },
  })

  return data.securitySettings
}

export const setPhoneAuthSettings = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setPhoneAuthSettings($input: PhoneAuthSettingsInput!) {
        securitySettings: setPhoneAuthSettings(input: $input) {
          ...securitySettings
        }
      }

      ${SecuritySettingsFragmentDoc}
    `,
    variables: { input },
  })

  return data.securitySettings
}

export const sendTestPhoneMessage = async (phone) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation sendTestPhoneMessage($phone: PhoneNumber!) {
        sendTestPhoneMessage(phone: $phone)
      }
    `,
    variables: { phone },
  })

  return data.sendTestPhoneMessage
}

export const setCustomDomain = async (variables) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setCustomDomain(
        $domain: Domain!
        $certificate: CertificateInput
      ) {
        customDomain: setCustomDomain(
          domain: $domain
          certificate: $certificate
        ) {
          ...customDomain
        }
      }

      ${CustomDomainFragmentDoc}
    `,
    variables,
  })

  return data.customDomain
}

export const removeCustomDomain = async () => {
  await client.mutate({
    mutation: gql`
      mutation removeCustomDomain {
        removeCustomDomain
      }
    `,
  })
}

export const addCustomDomainCertificate = async (certificate) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation addCustomDomainCertificate($certificate: CertificateInput!) {
        customDomain: addCustomDomainCertificate(certificate: $certificate) {
          ...customDomain
        }
      }

      ${CustomDomainFragmentDoc}
    `,
    variables: { certificate },
  })

  return data.customDomain
}

export const setCustomDomainCertificate = async (digest) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setCustomDomainCertificate($digest: String!) {
        customDomain: setCustomDomainCertificate(digest: $digest) {
          ...customDomain
        }
      }

      ${CustomDomainFragmentDoc}
    `,
    variables: { digest },
  })

  return data.customDomain
}

export const deleteCustomDomainCertificate = async (digest) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation deleteCustomDomainCertificate($digest: String!) {
        customDomain: deleteCustomDomainCertificate(digest: $digest) {
          ...customDomain
        }
      }

      ${CustomDomainFragmentDoc}
    `,
    variables: { digest },
  })

  return data.customDomain
}
