import type { EmailBranding, IEmailOrganization } from '@blissbook/lib/email'
import {
  EmailLayout,
  HorizontalRule,
  Paragraph,
} from '@blissbook/lib/email/renderer'
import pluralize from 'pluralize'
import React from 'react'

export type AuthenticationEmailProps = {
  authCode: string
  authTokenHours: number
  authUrl: string
  branding: EmailBranding
  organization: IEmailOrganization
}

export const AuthenticationEmail: React.FC<AuthenticationEmailProps> = ({
  authCode,
  authTokenHours,
  authUrl,
  branding,
  organization,
}) => {
  const { footerTextColor } = branding
  return (
    <EmailLayout
      branding={branding}
      callToAction={{
        afterEl: (
          <Paragraph style={{ color: footerTextColor }}>
            or enter {authCode} on the sign in page
          </Paragraph>
        ),
        href: authUrl,
        text: 'Click Here to Sign In',
      }}
    >
      <Paragraph>
        Above is the link/code you requested to get into your{' '}
        {organization.name} account.
      </Paragraph>
      <HorizontalRule />
      <Paragraph>
        For your security, this link & code expires in{' '}
        {pluralize('hour', authTokenHours, true)}.
      </Paragraph>
      <Paragraph />
      <Paragraph>
        If you're not sure what this is about, you can ignore this email.
      </Paragraph>
      <Paragraph />
      <Paragraph>Have a great day!</Paragraph>
    </EmailLayout>
  )
}

export function renderAuthenticationEmail(props: AuthenticationEmailProps) {
  const { organization } = props
  return {
    subject: `Sign In to Your ${organization.name} Account`,
    body: <AuthenticationEmail {...props} />,
  }
}
