import { cx } from '@emotion/css'
import React, { type ComponentProps } from 'react'
import { ProfileView } from './ProfileView'
import type { UserProfile } from './UserProfile'

export type UserProfileViewProps = ComponentProps<'div'> & {
  disableTooltip?: boolean
  size: number
  user: UserProfile
}

export const UserProfileView = ({
  className,
  disableTooltip,
  user,
  style,
  ...props
}: UserProfileViewProps) => {
  return (
    <ProfileView
      {...props}
      avatarURL={user.avatarURL}
      className={cx(user.className, className)}
      text={user.initials}
      tooltip={disableTooltip ? undefined : user.fullName}
    />
  )
}

UserProfileView.displayName = 'UserProfileView'
