import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink'
import { ButtonView, type LinkCommand, Plugin } from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { getIconPath } from './util'

export const LINK_KEYSTROKE = 'Ctrl+K'

export class LinkUI extends Plugin {
  init() {
    const editor = this.editor as BlissbookEditor
    const { t } = editor

    editor.ui.componentFactory.add('link', (locale) => {
      const buttonView = new ButtonView(locale)
      const linkCommand: LinkCommand = editor.commands.get('link')

      buttonView.set({
        icon: getIconPath(faLink),
        label: t('Link'),
        keystroke: LINK_KEYSTROKE,
        tooltip: true,
      })

      // Callback executed once the button is clicked
      buttonView.on('execute', () => {
        editor.setInlineToolbarType('link')
      })

      // @ts-ignore Bind the state of the button to the command.
      buttonView.bind('isOn', 'isEnabled').to(linkCommand, 'value', 'isEnabled')

      return buttonView
    })

    editor.keystrokes.set(LINK_KEYSTROKE, () => {
      editor.setInlineToolbarType('link')
    })
  }
}
