import {
  type EmailContent,
  type IEmailBilling,
  type IEmailInvoice,
  type IEmailOrganization,
  type IEmailPerson,
  blissbookEmailBranding,
} from '@blissbook/lib/email'
import { EmailLayout, Link } from '@blissbook/lib/email/renderer'
import React from 'react'

export type BillingEmailProps = {
  billing: IEmailBilling
  handbooksCount: number
  invoice?: IEmailInvoice
  organization: IEmailOrganization
  renewsAt?: Date
  to: IEmailPerson
}

export type BillingEmailTemplate = {
  renderEmail: (props: BillingEmailProps) => EmailContent
  type: string
}

export function getBillingUrl(organization: IEmailOrganization) {
  return `${organization.origin}/subscription`
}

export function getInvoicePdfUrl(
  organization: IEmailOrganization,
  invoice?: IEmailInvoice,
) {
  if (!invoice) return
  return `${organization.origin}/invoices/${invoice.id}.pdf`
}

export const BillingEmailLayout: React.FC<BillingEmailProps> = ({
  children,
  organization,
}) => (
  <EmailLayout
    branding={{
      ...blissbookEmailBranding,
      footerText: (
        <>
          Blissbook: Online employee handbooks & digital policy management.
          <br />
          You received this email because you're defined as a recipient of
          billing emails on your{' '}
          <Link href={`${organization.origin}/subscription`}>
            subscription page
          </Link>
          .
        </>
      ),
    }}
  >
    {children}
  </EmailLayout>
)
