import * as changesSignTemplate from './changes-sign'
import * as changesViewTemplate from './changes-view'
import * as invitationEditTemplate from './invitation-edit'
import * as invitationSignTemplate from './invitation-sign'
import * as invitationViewTemplate from './invitation-view'
import * as invitationViewSignTemplate from './invitation-view-sign'
export * as managerDigestTemplate from './manager-digest'
import * as reminderSignTemplate from './reminder-sign'
import * as reminderViewTemplate from './reminder-view'
export { renderHandbookEmailNote } from './template'
import type { HandbookEmailTemplate } from './types'
export * from './types'

export const handbookEmailTemplates: HandbookEmailTemplate[] = [
  changesSignTemplate,
  changesViewTemplate,
  invitationEditTemplate,
  invitationSignTemplate,
  invitationViewSignTemplate,
  invitationViewTemplate,
  reminderSignTemplate,
  reminderViewTemplate,
]
