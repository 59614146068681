import { ApolloError } from '@apollo/client'
import { sendTestPhoneMessage } from '@blissbook/application/actions'
import { Field, FormSubmit, Popper } from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import { addToast } from '@blissbook/ui/util/toaster'
import { cx } from '@emotion/css'
import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

export const TestPhoneMessageButton = ({ className, ...props }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const onSubmit = async (values, { setStatus }) => {
    const { phone } = values
    setStatus({ isSubmitting: true })

    try {
      await sendTestPhoneMessage(phone)
      addToast('Test message sent!', { type: 'success' })
      setMenuOpen(false)
    } catch (error) {
      if (error instanceof ApolloError) {
        addToast(`Invalid phone number: ${phone}`, { type: 'error' })
      } else {
        handleError(error)
      }
      setStatus()
    }
  }

  return (
    <Popper.Provider isOpen={isMenuOpen} setOpen={setMenuOpen}>
      <Popper.ToggleButton {...props} className={cx('btn-link', className)}>
        Send a Test Message
      </Popper.ToggleButton>

      <Popper.Menu style={{ width: 400 }}>
        <h3 className='tw-mt-0'>Enter a Phone Number</h3>

        <Formik
          initialValues={{
            phone: '',
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object().shape({
            phone: Yup.string().required(),
          })}
        >
          {({ handleSubmit, status: { isSubmitting } = {} }) => (
            <form noValidate onSubmit={handleSubmit}>
              <fieldset disabled={isSubmitting}>
                <Field name='phone' placeholder='123 456 7890' autoFocus />

                <FormSubmit
                  isSubmitting={isSubmitting}
                  onCancel={() => setMenuOpen(false)}
                >
                  Send Test Message
                </FormSubmit>
              </fieldset>
            </form>
          )}
        </Formik>
      </Popper.Menu>
    </Popper.Provider>
  )
}
