import {
  type ResizeEdge,
  type Size,
  bindResize,
  resizeEdges,
} from '@blissbook/ui/editor/resize'
import type { Element } from 'ckeditor5'
import throttle from 'lodash/throttle'
import React, { useEffect, useRef, useState } from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { CKEditorSelection } from '../CKEditorSelection'

const minWidth = 50
const maxWidth = 770

export function ResizeToolbar({
  editor,
  element,
}: {
  editor: BlissbookEditor
  element: Element
}) {
  const [selectionNode, setSelectionNode] = useState<HTMLDivElement>()

  // Use a ref to avoid unnecessary re-renders, but keep the element in sync
  const elementRef = useRef(element)
  elementRef.current = element

  useEffect(() => {
    if (!selectionNode) return

    const lockAspectRatio = element.name !== 'pdf'

    function onChange(size: Size, initialSize: Size, axis: ResizeEdge) {
      const element = elementRef.current

      const aspectRatio = lockAspectRatio
        ? initialSize.width / initialSize.height
        : size.width / size.height

      if (axis.xFactor) {
        size.width = Math.max(minWidth, Math.min(size.width, maxWidth))
        size.height = size.width * aspectRatio

        editor.model.change((writer) => {
          writer.setAttribute('width', size.width + 'px', element)
        })
      } else if (axis.yFactor && !lockAspectRatio) {
        const aspectRatio = `${size.width} / ${size.height}`

        editor.model.change((writer) => {
          writer.setAttribute('aspectRatio', aspectRatio, element)
          writer.setAttribute('width', size.width + 'px', element)
        })
      }

      return size
    }

    const onChangeThrottled = throttle(onChange, 50)

    bindResize(selectionNode, {
      edges: resizeEdges,
      setSize: onChangeThrottled,
      showSize: true,
    })
  }, [editor, selectionNode])

  return (
    <>
      <CKEditorSelection
        editor={editor}
        rangeClassName='tw-bg-transparent'
        ref={setSelectionNode}
      />
    </>
  )
}
