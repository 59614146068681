import { formatPrice } from '@blissbook/common/billing/price'
import { Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'
import {
  BillingEmailLayout,
  type BillingEmailProps,
  getBillingUrl,
  getInvoicePdfUrl,
} from '../template'

export const type = 'billing/check/invoice'

export function renderEmail(props: BillingEmailProps) {
  const { billing, invoice, organization, to } = props
  const billingUrl = getBillingUrl(organization)
  const invoicePdfUrl = getInvoicePdfUrl(organization, invoice)
  return {
    subject: 'Your Blissbook invoice',
    body: (
      <BillingEmailLayout {...props}>
        <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
        <Paragraph />
        <Paragraph>
          Thanks for being a Blissbook customer! Your subscription has renewed
          for the next {pluralize('month', billing.termMonths, true)} and is
          ready for payment.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          The amount outstanding of {formatPrice(invoice.totalPrice)}USD is due
          on {moment(invoice.dueDate).format('YYYY-MM-DD')}.
        </Paragraph>
        <Paragraph />
        <Paragraph>Your invoice is attached.</Paragraph>
        <Paragraph />
        <Paragraph>
          If you're an Admin on your Blissbook account, you can view, download,
          or print this invoice here:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={invoicePdfUrl}>{invoicePdfUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          And you can see all of your subscription details on your subscription
          page here:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={billingUrl}>{billingUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          If you have any questions or need help, please reply to this email or
          contact us at support@blissbook.com.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          Thank you - we hope Blissbook continues to serve you well!
        </Paragraph>
        <Paragraph />
        <Paragraph>The Blissbook Team</Paragraph>
      </BillingEmailLayout>
    ),
  }
}
