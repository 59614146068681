import type { AssetPickerContext } from './AssetPickerContext'
import { useImagePicker } from './useImagePicker'
import { useMediaPicker } from './useMediaPicker'
import { usePdfPicker } from './usePdfPicker'

export type AssetPickers = {
  imagePicker: ReturnType<typeof useImagePicker>
  pdfPicker: ReturnType<typeof usePdfPicker>
  mediaPicker: ReturnType<typeof useMediaPicker>
}

export function useAssetPickers({
  context,
  videoOnly,
}: {
  context?: AssetPickerContext
  videoOnly?: boolean
} = {}) {
  const imagePicker = useImagePicker(context)
  const pdfPicker = usePdfPicker(context)
  const mediaPicker = useMediaPicker({ videoOnly })

  return {
    imagePicker,
    pdfPicker,
    mediaPicker,
  }
}
