import {
  type Node,
  emailSchema,
  renderText,
  textSchema,
} from '@blissbook/lib/document'
import {
  type EmailBranding,
  type IEmailOrganization,
  type IEmailPerson,
  formatAuthUrl,
} from '@blissbook/lib/email'
import {
  EmailLayout,
  Paragraph,
  renderEmailContent,
} from '@blissbook/lib/email/renderer'
import { getFirstName } from '@blissbook/lib/name'
import React from 'react'

export const showSentByBlissbook = true
export const type = 'manager-digest'

export const renderBeforeCallToAction = (
  nonCompliantCount: number | string,
) => (
  <Paragraph style={{ fontWeight: 'bold' }}>
    {nonCompliantCount} of your reports need to sign their document.
  </Paragraph>
)

export const subjectContent = textSchema.htmlToJSON(
  'Reminder: Some members of your staff still need to sign something!',
)

export const ccManagers = false

export const buttonContent = textSchema.htmlToJSON(
  'Open your Manager Dashboard',
)

export const messageContent = emailSchema.htmlToJSON(`
  <hr />
  <p></p>
  <p>Hi <variable name="to.firstName"></variable>!</p>
  <p></p>
  <p>Some of your team members have yet to acknowledge receipt of their documents. Signing is mandatory to meet compliance and keep their records current.</p>
  <p></p>
  <p>We appreciate your prompt action on this—it helps our organization achieve our compliance goals.</p>
  <p></p>
  <p>Have a great day,</p>
  <p></p>
  <p><strong>— <variable name="from.fullName"></variable></strong></p>
`)

export type ManagerDigestManager = IEmailPerson & {
  employeeStatistics: {
    nonCompliantCount: number
  }
}

export type ManagerDigestVariablesArgs = {
  from: IEmailPerson
  organization: IEmailOrganization
  to: ManagerDigestManager
}

export type ManagerDigestEmailProps = ManagerDigestVariablesArgs & {
  branding: EmailBranding
  buttonContent: Node[]
  messageContent: Node[]
  isAuthEnabled: boolean
  subjectContent: Node[]
}

function buildVariables({
  from,
  organization,
  to,
}: ManagerDigestVariablesArgs) {
  return {
    'from.firstName': getFirstName(from.fullName),
    'from.fullName': from.fullName,
    'organization.name': organization.name,
    'to.firstName': getFirstName(to.fullName),
    'to.fullName': to.fullName,
  }
}

export function renderEmail({
  branding,
  buttonContent,
  from,
  isAuthEnabled,
  messageContent,
  organization,
  subjectContent,
  to,
}: ManagerDigestEmailProps) {
  const variables = buildVariables({ from, organization, to })
  return {
    subject: renderText(subjectContent),
    body: (
      <EmailLayout
        branding={branding}
        callToAction={{
          beforeEl: renderBeforeCallToAction(
            to.employeeStatistics.nonCompliantCount,
          ),
          href: formatAuthUrl(organization.origin + '/manager', isAuthEnabled),
          text: renderText(buttonContent, variables),
        }}
        showSentByBlissbook={showSentByBlissbook}
      >
        {renderEmailContent(messageContent, { variables })}
      </EmailLayout>
    ),
  }
}
