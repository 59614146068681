import { useEmailTemplate, useOrganization } from '@blissbook/application/hooks'
import {
  fromVariables,
  handbookVariables,
  toVariables,
} from '@blissbook/lib/blissbook'
import { replaceVariables } from '@blissbook/lib/document'
import type { ButtonProps } from '@blissbook/ui/lib'
import snakeCase from 'lodash/snakeCase'
import React from 'react'
import {
  EditNotificationModal,
  type NotificationValue,
} from './EditNotificationModal'

export type EditHandbookNotificationModalProps = {
  alert?: React.ReactNode
  fromName: string
  isSendingEmails?: boolean
  submitButtonText?: string
  title: string
  to: React.ReactNode
}

export type EditHandbookNotificationModalButtonProps = ButtonProps & {
  handbook: IHandbook
  modalProps?: EditHandbookNotificationModalProps
  onSubmit?: (values: Partial<NotificationValue>) => void
  templateType: string
}

export function EditHandbookNotificationModalButton({
  handbook,
  modalProps: { fromName, submitButtonText, ...modalProps },
  onSubmit,
  templateType,
  ...props
}: EditHandbookNotificationModalButtonProps) {
  const { isSendingEmails } = modalProps
  const organization = useOrganization()
  const variableValues = {
    'from.fullName': fromName,
    'handbook.name': handbook.name,
    'organization.name': organization.name,
  }

  const templateId = snakeCase(templateType)
  const emailTemplate = useEmailTemplate(templateId)
  const buttonContent = replaceVariables(
    emailTemplate.buttonContent,
    variableValues,
  )
  const messageContent = replaceVariables(
    emailTemplate.messageContent,
    variableValues,
  )
  const subjectContent = replaceVariables(
    emailTemplate.subjectContent,
    variableValues,
  )

  const messageVariables = []
  if (!isSendingEmails) {
    messageVariables.push(...fromVariables)
  }
  messageVariables.push(...toVariables, ...handbookVariables)

  return (
    <EditNotificationModal.Button
      {...props}
      modalProps={{
        ...modalProps,
        fromName: `${fromName} (via Blissbook)`,
        key: templateId,
        messageVariables,
        notification: {
          buttonContent,
          messageContent,
          subjectContent,
        },
        onSubmit,
        submitButtonText,
      }}
    />
  )
}
