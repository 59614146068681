import { useEffect } from 'react'

type OnClickAnnotationIdFunction = (annotationId: string) => void

export function useClickAnnotation(
  node: HTMLElement | undefined,
  onClickAnnotationId: OnClickAnnotationIdFunction | undefined,
) {
  return useEffect(() => {
    if (!node || !onClickAnnotationId) return

    function handleClickAnnotation(event: MouseEvent) {
      const target = event.target as HTMLElement
      if (!('dataset' in target)) return

      const { annotationId } = target.dataset
      if (!annotationId) return

      onClickAnnotationId(annotationId)
    }

    node.addEventListener('click', handleClickAnnotation)
    return () => node.removeEventListener('click', handleClickAnnotation)
  }, [node, onClickAnnotationId])
}
