import { emailSchema, textSchema } from '@blissbook/lib/document'
import { renderHandbookEmail, toSigner } from './template'
import type { HandbookEmailInput } from './types'

export const type = 'reminder-sign'
export const editButtonText =
  'Customize the reminder sent to someone who needs to view and then sign an acknowledgement'
export const title = 'Manual Reminder to View and Sign'
export const to = toSigner
export const subjectContent = textSchema.htmlToJSON(
  'Reminder: You were invited to acknowledge the <variable name="handbook.name"></variable>!',
)
export const ccManagers = false
export const buttonContent = textSchema.htmlToJSON(
  'View & Sign the <variable name="handbook.name"></variable>',
)

export const messageContent = emailSchema.htmlToJSON(`
  <p>Please acknowledge your receipt of the <variable name="organization.name"></variable> <variable name="handbook.name"></variable>. To do so, click the button above and then click the “Sign It” button.</p>
  <hr />
  <p>Hi <variable name="to.firstName"></variable>,</p>
  <p></p>
  <p>The <variable name="handbook.name"></variable> still requires your signature. Please take a moment now to read it and then sign the digital acknowledgement form within.</p>
  <p></p>
  <p>Remember, you can always access the <variable name="handbook.name"></variable> by signing in at <variable name="handbook.viewingLink"></variable>.</p>
  <p></p>
  <p>If you have any questions, let us know!</p>
  <p></p>
  <p>Have a great day,</p>
  <p>Your HR Team</p>
`)

export function renderEmail(props: HandbookEmailInput) {
  const { handbook } = props
  return renderHandbookEmail({
    ...props,
    authUrl: handbook.publishedUrl,
  })
}
