import {
  useEmailSettings,
  useOrganization,
  useSession,
} from '@blissbook/application/hooks'
// @ts-ignore: WIP Imports
import { EmailAddButton } from '@blissbook/application/lib'
import { toVariables } from '@blissbook/lib/blissbook'
import {
  type Node,
  type VariableOption,
  emailSchema,
  textSchema,
} from '@blissbook/lib/document'
import { getEmailBranding, getEmailCss } from '@blissbook/lib/email'
import {
  type CallToAction,
  EmailLayout,
  prependEmailMargin,
} from '@blissbook/lib/email/renderer'
import { ActiveEditorProvider, ActiveEditorToolbar } from '@blissbook/ui/editor'
import {
  Badge,
  Button,
  Dropdown,
  // @ts-ignore: WIP Imports
  Field,
  FormSubmit,
  Link,
  Modal,
  // @ts-ignore: WIP imports
  ScrollContainer,
  Tooltip,
} from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { cx } from '@emotion/css'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArray, Formik } from 'formik'
import pick from 'lodash/pick'
import React from 'react'
import { Fragment, useState } from 'react'
import * as Yup from 'yup'

type HeaderRowProps = {
  children: React.ReactNode
  className?: string
  disabled?: boolean
  hasError?: boolean
  label: string
}

const HeaderRow = ({
  children,
  className,
  disabled,
  hasError,
  label,
}: HeaderRowProps) => (
  <div
    className={cx(
      'tw-flex tw-items-start tw-mb-2',
      !disabled && 'tw-border-t tw-border-gray-300 tw-pt-2',
      className,
    )}
  >
    <div
      className={cx(
        'font-italic tw-font-bold tw-mr-4',
        hasError ? 'tw-text-red-700' : 'tw-text-gray-500',
      )}
      style={{
        minWidth: 60,
      }}
    >
      {label}
    </div>
    <div className={cx('tw-flex-1', { 'tw-text-gray-500': disabled })}>
      {children}
    </div>
  </div>
)

type FromHeaderRowProps = {
  fromName?: string
}

function FromHeaderRow({ fromName }: FromHeaderRowProps) {
  const emailSettings = useEmailSettings()
  const { fromEmail } = emailSettings
  fromName ||= emailSettings.fromName
  const session = useSession()
  const canEditFrom = session.can('integrations.edit')

  return (
    <HeaderRow disabled label='from'>
      {`${fromName} <${fromEmail}>`}

      <If condition={canEditFrom}>
        <Link
          className='tw-ml-2'
          href='/email/settings'
          rel='noopener noreferrer'
          target='_blank'
        >
          change sending account
        </Link>
      </If>
    </HeaderRow>
  )
}

type CCHeaderRowProps = {
  cc?: string[]
  ccManagers?: boolean
  disabled?: boolean
}

function CCHeaderRow({ cc, ccManagers, disabled }: CCHeaderRowProps) {
  const marginBottom = 4

  return (
    <HeaderRow disabled={disabled} label='CC'>
      <div
        className='tw-flex tw-items-start tw-gap-4 tw-justify-between'
        style={{ marginTop: 1, minHeight: 21 }}
      >
        <If condition={cc !== undefined}>
          <div
            className='clearfix'
            style={{
              marginBottom: -marginBottom,
            }}
          >
            <FieldArray
              name='cc'
              children={(arrayHelpers) => (
                <Fragment>
                  {cc.map((email, index) => (
                    <Tooltip content='Remove' key={index}>
                      <Badge
                        className='tw-uppercase tw-float-left tw-mr-2'
                        onRemove={() => {
                          arrayHelpers.remove(index)
                        }}
                        style={{ marginBottom }}
                      >
                        {email}
                      </Badge>
                    </Tooltip>
                  ))}

                  <EmailAddButton
                    className='tw-float-left btn-link'
                    notEmails={cc}
                    onSelect={(email: string) => {
                      arrayHelpers.push(email)
                    }}
                    style={{
                      fontSize: 12,
                      marginBottom: 1,
                      marginTop: 1,
                    }}
                  >
                    <FontAwesomeIcon
                      className='tw-mr-1'
                      icon={['far', 'plus-circle']}
                    />
                    Add
                  </EmailAddButton>
                </Fragment>
              )}
            />
          </div>
        </If>

        <If condition={ccManagers !== undefined}>
          <Field
            className='tw-my-0 tw-whitespace-nowrap'
            label={`CC the recipient's manager`}
            name='ccManagers'
            size='sm'
            type='checkbox'
          />
        </If>
      </div>
    </HeaderRow>
  )
}

type HeaderFieldOption = {
  label: string
  value: string
}

type HeaderField = {
  key: string
  label: string
  options: HeaderFieldOption[]
  validationSchema: Yup.Schema
}

type HeaderFieldRowProps = {
  disabled?: boolean
  field: HeaderField
  onChange: (value: HeaderFieldOption) => void
  value: HeaderFieldOption
}

function HeaderFieldRow({
  disabled,
  field,
  onChange,
  value,
}: HeaderFieldRowProps) {
  return (
    <HeaderRow
      className='tw-items-center'
      disabled={disabled}
      label={field.label}
    >
      <Dropdown.Provider>
        <Dropdown.ToggleButton className='btn btn-input tw-w-full'>
          {value ? value.label : '---SELECT---'}
        </Dropdown.ToggleButton>
        <Dropdown.Menu sameWidth>
          {field.options.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => {
                onChange(option)
              }}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown.Provider>
    </HeaderRow>
  )
}

export type NotificationValue = {
  buttonContent: Node[]
  cc: string[]
  ccManagers: boolean
  messageContent: Node[]
  subjectContent: Node[]
}

const contentSchema = Yup.array().of(Yup.object().required()).required()

const validationShape = {
  buttonContent: contentSchema,
  cc: Yup.array().of(Yup.string().email().required()),
  ccManagers: Yup.boolean().required(),
  messageContent: contentSchema,
  subjectContent: contentSchema,
}

const notificationKeys = Object.keys(validationShape)

function getInitialValues(
  notification: Partial<NotificationValue>,
  headerFields: HeaderField[],
) {
  const headerKeys = headerFields.map((field) => field.key)
  return pick(notification, notificationKeys, headerKeys)
}

function getValidationSchema(
  notification: Partial<NotificationValue>,
  headerFields: HeaderField[],
) {
  // Start from the validation schema
  const keys = Object.keys(notification)
  const schema = pick(validationShape, keys)

  // Add header fields
  for (const field of headerFields) {
    // @ts-ignore: Allow dyanmic keys
    schema[field.key] = field.validationSchema
  }

  // Build the full schema
  return Yup.object().shape(schema)
}

export type EditNotificationModalProps = {
  /** Optional alert above the submit button */
  alert?: React.ReactNode
  buttonVariables?: VariableOption[]
  callToAction?: Partial<CallToAction>
  fromName?: string
  handbook?: IHandbook
  headerFields?: HeaderField[]
  /** Emails will be sent upon submit */
  isSendingEmails?: boolean
  key?: string
  messageVariables?: VariableOption[]
  notification: Partial<NotificationValue>
  onDelete?: () => Promise<void>
  onSubmit: (values: Partial<NotificationValue>) => void
  renderTable?: (values: Partial<NotificationValue>) => React.ReactNode
  showSentByBlissbook?: boolean
  submitButtonText?: string
  subjectVariables?: VariableOption[]
  title: string
  to?: React.ReactNode
}

export const EditNotificationModal = Modal.wrap<EditNotificationModalProps>(
  ({
    alert,
    buttonVariables,
    callToAction,
    fromName,
    handbook,
    headerFields = [],
    isSendingEmails,
    messageVariables = toVariables,
    notification,
    onDelete,
    onSubmit,
    renderTable,
    showSentByBlissbook,
    submitButtonText = 'Save',
    subjectVariables,
    title,
    to,
    ...props
  }) => {
    const { onClose } = props
    const emailSettings = useEmailSettings()
    const organization = useOrganization()
    const session = useSession()
    const branding = getEmailBranding(emailSettings)
    const canEditBranding = session.can('organization.admin')

    // Message customization
    const [messageState, setMessageState] = useState({
      content: notification.messageContent,
      isCustomizing: !isSendingEmails,
      key: new Date(),
    })
    const { isCustomizing } = messageState
    const canSubmit = !isSendingEmails || !isCustomizing

    const showVariableContent =
      handbook?.publishedAt && handbook?.hasVariableContent

    const toolbarEl = (
      <ActiveEditorToolbar tools='bold italic underline bulletList orderedList indent link horizontalRule variable' />
    )

    return (
      <Modal.Component {...props} width={800}>
        <ActiveEditorProvider>
          <Formik
            initialValues={getInitialValues(notification, headerFields)}
            onSubmit={async (values, { setStatus }) => {
              setStatus({ isSubmitting: true })

              try {
                await onSubmit(values)
                onClose()
              } catch (error) {
                handleError(error)
                setStatus()
              }
            }}
            validationSchema={getValidationSchema(notification, headerFields)}
          >
            {({
              errors,
              handleSubmit,
              setFieldValue,
              status: { isSubmitting } = {},
              values,
            }) => {
              // Errors
              const errorKey = Object.keys(errors)[0] as keyof NotificationValue
              const error = errors[errorKey]

              // CC
              const { cc } = values
              const hasManagers = organization.managersCount > 0
              const ccManagers = hasManagers ? values.ccManagers : undefined
              const showCc = cc !== undefined || ccManagers !== undefined

              const editBrandingButtonEl = (
                <If condition={canEditBranding}>
                  <Link
                    className='btn btn-outline-primary btn-sm tw-absolute tw-top-2 tw-right-10'
                    href='/email/branding'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Edit Email Branding
                    <FontAwesomeIcon
                      className='tw-ml-2'
                      icon='external-link'
                      style={{ fontSize: 14 }}
                    />
                  </Link>
                </If>
              )

              const messageEditorEl = (
                <>
                  <EmailLayout
                    branding={branding}
                    callToAction={{
                      ...callToAction,
                      textEditor: (
                        <Field
                          disabled={!isCustomizing || isSubmitting}
                          name='buttonContent'
                          placeholder='Call to Action'
                          schema={textSchema}
                          type='editor'
                          variables={buttonVariables}
                        />
                      ),
                    }}
                    css={css`
                      ${getEmailCss(branding)}
                    `}
                    padding='60px 30px'
                    showSentByBlissbook={showSentByBlissbook}
                  >
                    <div className='email-message'>
                      <Field
                        className='tw-p-0'
                        disabled={!isCustomizing || isSubmitting}
                        key={messageState.key}
                        name='messageContent'
                        placeholder='Body'
                        schema={emailSchema}
                        type='editor'
                        variables={messageVariables}
                      />

                      {renderTable && prependEmailMargin(renderTable(values))}
                    </div>
                  </EmailLayout>
                  <If condition={showVariableContent}>
                    <p
                      className='tw-text-gray-500 tw-mt-2'
                      style={{ fontSize: 14 }}
                    >
                      * Because you're using variable content access control,
                      some users may not be able to see all changes.
                    </p>
                  </If>
                </>
              )

              const submitButtonEl = (
                <Tooltip content={error} disabled={!error}>
                  <div>
                    <FormSubmit
                      className='tw-mt-0'
                      disabled={!canSubmit || isSubmitting || !!error}
                      isSubmitting={isSubmitting}
                      onCancel={isSendingEmails ? onClose : undefined}
                      // @ts-ignore: Difference in event type
                      onSubmit={handleSubmit}
                    >
                      {isSendingEmails && (
                        <FontAwesomeIcon
                          className='tw-mr-2'
                          icon={
                            isSubmitting ? 'spinner' : ['far', 'paper-plane']
                          }
                          spin={isSubmitting}
                        />
                      )}
                      {submitButtonText}
                    </FormSubmit>
                  </div>
                </Tooltip>
              )

              const deleteButtonEl = (
                <If condition={onDelete !== undefined}>
                  <Button
                    color='danger'
                    disabled={isSubmitting}
                    onClick={async () => {
                      try {
                        await onDelete()
                        onClose()
                      } catch (error) {
                        handleError(error)
                      }
                    }}
                    outline
                  >
                    Delete
                  </Button>
                </If>
              )

              /** Toggle customizing the message on/off */
              function handleToggleCustomize() {
                if (!isCustomizing) {
                  setMessageState({
                    ...messageState,
                    content: values.messageContent,
                    isCustomizing: true,
                  })
                } else {
                  setMessageState({
                    ...messageState,
                    isCustomizing: false,
                  })
                }
              }

              /** Cancel customize the message and reset the editor/value */
              async function handleCancelCustomize() {
                try {
                  await setFieldValue('messageContent', messageState.content)

                  setMessageState({
                    ...messageState,
                    isCustomizing: false,
                    key: new Date(),
                  })
                } catch (error) {
                  handleError(error)
                }
              }

              return (
                <Modal.Content {...props}>
                  <Modal.Header className='tw-pb-0' closeButtonText='cancel'>
                    <Modal.Title className='tw-mb-4'>{title}</Modal.Title>

                    <div className='tw-border-b tw-border-gray-300'>
                      <FromHeaderRow fromName={fromName} />

                      {to && (
                        <HeaderRow
                          className='tw-items-center'
                          disabled
                          label='to'
                        >
                          {to}
                        </HeaderRow>
                      )}

                      {showCc && (
                        <CCHeaderRow
                          cc={cc}
                          ccManagers={ccManagers}
                          disabled={isSubmitting}
                        />
                      )}

                      {headerFields.map((field) => (
                        <HeaderFieldRow
                          field={field}
                          key={field.key}
                          onChange={(newValue) => {
                            setFieldValue(field.key, newValue).catch(logUIError)
                          }}
                          // @ts-ignore: Allow dynamic keys
                          value={values[field.key] as HeaderFieldOption}
                        />
                      ))}

                      <HeaderRow
                        hasError={!!errors.subjectContent}
                        label='subject'
                      >
                        <Field
                          className='tw-mb-0 tw-mt-0.5 tw-text-sm'
                          disabled={isSubmitting}
                          name='subjectContent'
                          schema={textSchema}
                          type='editor'
                          variables={subjectVariables}
                        />
                      </HeaderRow>
                    </div>
                  </Modal.Header>

                  <Modal.Body
                    scrollClassName='tw-relative'
                    style={{ marginBottom: -1, marginTop: -1 }}
                  >
                    <Choose>
                      <When condition={!isSendingEmails}>
                        {editBrandingButtonEl}
                        {messageEditorEl}
                      </When>
                      <Otherwise>
                        <div
                          className={cx(
                            'tw-flex tw-flex-col tw-h-full tw-border',
                            isCustomizing
                              ? 'tw-border-blurple-500 tw-rounded tw-overflow-hidden'
                              : 'tw-border-gray-300 tw-border-b-gray-400',
                          )}
                        >
                          <ScrollContainer
                            className='tw-flex-1 tw-min-h-0'
                            innerClassName='tw-relative'
                          >
                            {editBrandingButtonEl}
                            {messageEditorEl}
                          </ScrollContainer>
                          <div className='tw-flex tw-items-center tw-border-t tw-border-gray-300 tw-gap-8 tw-py-4 tw-px-6'>
                            <div className='tw-flex tw-items-center tw-gap-4'>
                              <Button
                                className='tw-my-2'
                                color='primary'
                                disabled={isSubmitting}
                                onClick={handleToggleCustomize}
                                outline={!isCustomizing}
                              >
                                {isCustomizing ? 'Save' : 'Customize'}
                              </Button>

                              {isCustomizing && (
                                <Button
                                  className='btn-cancel'
                                  onClick={handleCancelCustomize}
                                >
                                  Cancel
                                </Button>
                              )}
                            </div>

                            {isCustomizing && toolbarEl}
                          </div>
                        </div>
                      </Otherwise>
                    </Choose>
                  </Modal.Body>

                  <Modal.Footer className='tw-flex tw-flex-col tw-items-stretch tw-gap-4 tw-border-t tw-border-solid tw-border-gray-400 tw-py-4'>
                    {alert}

                    <div className='tw-flex tw-items-center tw-justify-between'>
                      <div className='tw-flex tw-items-center tw-gap-8'>
                        {submitButtonEl}
                        {!isSendingEmails && toolbarEl}
                      </div>

                      {deleteButtonEl}
                    </div>
                  </Modal.Footer>
                </Modal.Content>
              )
            }}
          </Formik>
        </ActiveEditorProvider>
      </Modal.Component>
    )
  },
)
