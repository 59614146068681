import { gql } from '@apollo/client'
import HandbookSignatureRound from '@blissbook/common/handbook/signature-round'
import {
  CloneHandbookDocument,
  CreateHandbookDocument,
  type CreateHandbookMutation,
  type CreateHandbookMutationVariables,
  DeleteHandbookDocument,
  HandbookPreviewLinkFragmentDoc,
  HandbookSharingFragmentDoc,
  HandbookSignatureRoundFragmentDoc,
  type HandbookSignatureRoundInput,
  HandbookSummaryFragmentDoc,
  HandbookTaskFragmentDoc,
  InitializeHandbookAudienceDocument,
  InitializeHandbookContentDocument,
  PatchHandbookDocument,
  PublishHandbookDocument,
  type PublishHandbookMutationVariables,
  PublishHandbookNotificationFragmentDoc,
} from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'

// Queries ====================================================================

export async function createHandbook(name: string) {
  const { data } = await client.mutate<
    CreateHandbookMutation,
    CreateHandbookMutationVariables
  >({
    mutation: CreateHandbookDocument,
    variables: { name: name.trim() },
  })
  return data.handbook
}

export async function cloneHandbook(handbookId: number, name: string) {
  const { data } = await client.mutate({
    mutation: CloneHandbookDocument,
    variables: { handbookId, name },
  })
  return data.handbook
}

export async function initializeHandbookAudience(
  id: number,
  hasAudience: boolean,
  hasSignatures: boolean,
) {
  const { data } = await client.mutate({
    mutation: InitializeHandbookAudienceDocument,
    variables: {
      id,
      hasAudience,
      hasSignatures,
    },
  })
  return data.handbook
}

export async function initializeHandbookContent(
  id: number,
  seedType: any,
  branding: any,
) {
  const { data } = await client.mutate({
    mutation: InitializeHandbookContentDocument,
    variables: {
      branding,
      id,
      seedType,
    },
  })
  return data.handbook
}

export async function patchHandbook(handbookId: number, input: any) {
  const { data } = await client.mutate({
    mutation: PatchHandbookDocument,
    variables: { handbookId, input },
  })
  return data.handbook
}

export const deleteHandbook = (handbookId: number) =>
  client.mutate({
    mutation: DeleteHandbookDocument,
    variables: { handbookId },
  })

export async function setPublishHandbookNotification(
  handbookId: number,
  input: any,
) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setPublishHandbookNotification(
        $handbookId: Int!
        $input: PublishHandbookNotificationInput!
      ) {
        notification: setPublishHandbookNotification(
          handbookId: $handbookId
          input: $input
        ) {
          ...publishHandbookNotification
        }
      }

      ${PublishHandbookNotificationFragmentDoc}
    `,
    variables: { handbookId, input },
  })
  return data.notification
}

export async function publishHandbook(
  variables: PublishHandbookMutationVariables,
) {
  const { data } = await client.mutate({
    mutation: PublishHandbookDocument,
    variables,
  })
  return data.handbook
}

export async function setHandbookCustomDomain(
  handbookId: number,
  customDomain: string,
) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setHandbookCustomDomain(
        $handbookId: Int!
        $customDomain: Domain
      ) {
        handbook: setHandbookCustomDomain(
          handbookId: $handbookId
          customDomain: $customDomain
        ) {
          id
          customDomain
          customDomainOrigin
          publishedUrl
        }
      }
    `,
    variables: {
      handbookId,
      customDomain,
    },
  })
  return data.handbook
}

export async function patchHandbookAudience(variables: any) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation patchHandbookAudience(
        $handbookId: Int!
        $audience: HandbookAudienceInput!
        $sendNotifications: Boolean!
      ) {
        handbook: patchHandbookAudience(
          handbookId: $handbookId
          audience: $audience
          sendNotifications: $sendNotifications
        ) {
          ...handbookSharing
        }
      }

      ${HandbookSharingFragmentDoc}
    `,
    variables,
  })
  return data.handbook
}

export async function resetHandbookPublicToken(handbookId: number) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation resetHandbookPublicToken($handbookId: Int!) {
        handbook: resetHandbookPublicToken(handbookId: $handbookId) {
          ...handbookSummary
        }
      }

      ${HandbookSummaryFragmentDoc}
    `,
    variables: { handbookId },
  })
  return data.handbook
}

export async function createHandbookPreviewLink(
  handbookId: number,
  personId: number,
) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createHandbookPreviewLink($handbookId: Int!, $personId: Int!) {
        previewLink: createHandbookPreviewLink(
          handbookId: $handbookId
          personId: $personId
        ) {
          ...handbookPreviewLink
        }
      }

      ${HandbookPreviewLinkFragmentDoc}
    `,
    variables: { handbookId, personId },
  })

  return data.previewLink
}

export async function resetHandbookPreviewLinkTokens(handbookId: number) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation resetHandbookPreviewLinkTokens($handbookId: Int!) {
        previewLinks: resetHandbookPreviewLinkTokens(handbookId: $handbookId) {
          ...handbookPreviewLink
        }
      }

      ${HandbookPreviewLinkFragmentDoc}
    `,
    variables: { handbookId },
  })
  return data.previewLinks
}

export const deleteHandbookPreviewLink = async (
  handbookId: number,
  personId: number,
) =>
  client.mutate({
    mutation: gql`
      mutation deleteHandbookPreviewLink($handbookId: Int!, $personId: Int!) {
        deleteHandbookPreviewLink(handbookId: $handbookId, personId: $personId)
      }
    `,
    variables: { handbookId, personId },
  })

export async function setHandbookTaskComplete(
  handbookId: number,
  taskId: string,
  complete: boolean,
) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setHandbookTaskComplete(
        $handbookId: Int!
        $taskId: HandbookTaskId!
        $complete: Boolean!
      ) {
        task: setHandbookTaskComplete(
          handbookId: $handbookId
          taskId: $taskId
          complete: $complete
        ) {
          ...handbookTask
        }
      }

      ${HandbookTaskFragmentDoc}
    `,
    variables: { handbookId, taskId, complete },
  })
  return data.task
}

export const setHandbookPositions = async (handbookIds: number[]) => {
  await client.mutate({
    mutation: gql`
      mutation setHandbookPositions($handbookIds: [Int!]!) {
        handbooks: setHandbookPositions(handbookIds: $handbookIds) {
          id
          position
        }
      }
    `,
    variables: { handbookIds },
  })
}

export async function createHandbookSignatureRound(
  handbookId: number,
  input: HandbookSignatureRoundInput,
  notification: any,
) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createHandbookSignatureRound(
        $handbookId: Int!
        $input: HandbookSignatureRoundInput!
        $notification: NotificationInput
      ) {
        signatureRound: createHandbookSignatureRound(
          handbookId: $handbookId
          input: $input
          notification: $notification
        ) {
          ...handbookSignatureRound
        }
      }

      ${HandbookSignatureRoundFragmentDoc}
    `,
    variables: { handbookId, input, notification },
  })
  return HandbookSignatureRound.fromJSON(data.signatureRound)
}

export async function updateHandbookSignatureRound(
  id: number,
  input: HandbookSignatureRoundInput,
) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateHandbookSignatureRound(
        $id: Int!
        $input: HandbookSignatureRoundInput!
      ) {
        signatureRound: updateHandbookSignatureRound(id: $id, input: $input) {
          handbookId
          ...handbookSignatureRound
        }
      }

      ${HandbookSignatureRoundFragmentDoc}
    `,
    variables: { id, input },
  })
  return HandbookSignatureRound.fromJSON(data.signatureRound)
}

export async function archiveHandbookSignatureRound(id: number) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation archiveHandbookSignatureRound($id: Int!) {
        signatureRound: archiveHandbookSignatureRound(id: $id) {
          handbookId
          ...handbookSignatureRound
        }
      }

      ${HandbookSignatureRoundFragmentDoc}
    `,
    variables: { id },
  })
  return HandbookSignatureRound.fromJSON(data.signatureRound)
}

export async function unarchiveHandbookSignatureRound(id: number) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation unarchiveHandbookSignatureRound($id: Int!) {
        signatureRound: unarchiveHandbookSignatureRound(id: $id) {
          handbookId
          ...handbookSignatureRound
        }
      }

      ${HandbookSignatureRoundFragmentDoc}
    `,
    variables: { id },
  })
  return HandbookSignatureRound.fromJSON(data.signatureRound)
}

export const deleteHandbookSignatureRound = (id: number) =>
  client.mutate({
    mutation: gql`
      mutation deleteHandbookSignatureRound($id: Int!) {
        deleteHandbookSignatureRound(id: $id)
      }
    `,
    variables: { id },
  })
