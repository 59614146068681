import { digestEmailTemplates } from './digests'
import * as invitationViewTemplate from './handbook/invitation-view'
import * as invitationViewSignTemplate from './handbook/invitation-view-sign'
import * as reminderSignTemplate from './handbook/reminder-sign'
import * as reminderViewTemplate from './handbook/reminder-view'
import type { EmailTemplate } from './types'

export const customizableEmailTemplates: EmailTemplate[] = [
  ...digestEmailTemplates,
  invitationViewTemplate,
  invitationViewSignTemplate,
  reminderViewTemplate,
  reminderSignTemplate,
]
