import { type Editor, EditorView } from '@blissbook/ui/editor'
import { Button } from '@blissbook/ui/lib'
import { faCircleArrowUp } from '@fortawesome/pro-solid-svg-icons/faCircleArrowUp'
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons/faCircleXmark'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export function AnnotationForm({
  disabled,
  onCancel,
  onSubmit,
  pmEditor,
}: {
  disabled?: boolean
  onCancel?: () => void
  onSubmit: () => void
  pmEditor: Editor
}) {
  const isEmpty = false
  return (
    <div className='tw-relative'>
      {pmEditor && (
        <EditorView
          autoFocus
          className='form-control tw-w-full tw-my-2 tw-border-blurple-500 tw-text-base'
          editor={pmEditor}
          editorClassName='tw-min-h-12'
          placeholder='What does your audience need to know?'
        />
      )}

      <div className='tw-absolute tw-right-0 tw-bottom-0 tw-flex tw-items-center tw-gap-1 tw-px-1'>
        {onCancel && (
          <Button
            className='btn-icon tw-text-gray-500 tw-text-lg'
            disabled={disabled || isEmpty}
            onClick={onCancel}
          >
            <FontAwesomeIcon icon={faCircleXmark} />
          </Button>
        )}

        <Button
          className='btn-icon tw-text-blurple-500 tw-text-lg'
          disabled={disabled || isEmpty}
          onClick={onSubmit}
        >
          <FontAwesomeIcon icon={faCircleArrowUp} />
        </Button>
      </div>
    </div>
  )
}
