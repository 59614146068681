import { useState } from 'react'

export type PickMediaFunction = (
  initialValue?: string,
) => Promise<string | undefined>

export type MediaPickerArgs = {
  videoOnly?: boolean
}
export type MediaPickerModalState = {
  onChange: (value: string | undefined) => void
  onClose: () => void
  value: string
}

export type MediaPicker = {
  modalState: MediaPickerModalState | undefined
  pickValue: PickMediaFunction
  videoOnly?: boolean
}

export function useMediaPicker({ videoOnly }: MediaPickerArgs): MediaPicker {
  const [modalState, setModalState] = useState<MediaPickerModalState>()

  const pickValue = (value?: string) =>
    new Promise<string>((resolve) => {
      setModalState({
        onChange: resolve,
        onClose: () => {
          setModalState(undefined)
          resolve(undefined)
        },
        value,
      })
    })

  return { modalState, pickValue, videoOnly }
}
