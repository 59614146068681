import type { DocumentSelection, Element } from 'ckeditor5'
import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import type { BlissbookEditor } from '../BlissbookEditor'

export type SelectionState = {
  annotationId: string | undefined
  element: Element | undefined
  linkHref: string | undefined
}

function getSelectedAnnotationId(selection: DocumentSelection) {
  return selection.getAttribute('annotationId') as string | undefined
}

function getSelectedHref(selection: DocumentSelection) {
  if (!selection.isCollapsed) return
  return selection.getAttribute('linkHref') as string | undefined
}

function getSelectionState(editor: BlissbookEditor) {
  const { selection } = editor.model.document
  return {
    annotationId: getSelectedAnnotationId(selection),
    element: selection.getSelectedElement(),
    linkHref: getSelectedHref(selection),
  }
}

export function useSelectedState(editor: BlissbookEditor) {
  const [state, setState] = useState<SelectionState>(() =>
    getSelectionState(editor),
  )

  useEffect(() => {
    function onChange() {
      const state = getSelectionState(editor)
      setState(state)
    }
    const handleChange = debounce(onChange, 10)

    const { document } = editor.model
    const { selection } = document
    document.on('change:data', handleChange)
    selection.on('change', handleChange)
    return () => {
      selection.off('change', handleChange)
      document.off('change:data', handleChange)
    }
  }, [editor])

  return state
}
