import { gql } from '@apollo/client'
import { Group } from '@blissbook/common/group'
import { Person } from '@blissbook/common/person'
import {
  GroupFragmentDoc,
  PeopleDocument,
  PersonFragmentDoc,
} from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'

export const getPeople = async (variables) => {
  const { data } = await client.query({
    fetchPolicy: 'no-cache',
    query: PeopleDocument,
    variables,
  })
  return data.people.map(Person.fromJSON)
}

export const createGroup = async (name) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createGroup($name: String!) {
        group: createGroup(name: $name) {
          ...group
        }
      }

      ${GroupFragmentDoc}
    `,
    variables: { name },
  })
  return Group.fromJSON(data.group)
}

export const updateGroup = async (id, input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateGroup($id: Int!, $input: GroupInput!) {
        group: updateGroup(id: $id, input: $input) {
          ...group
        }
      }

      ${GroupFragmentDoc}
    `,
    variables: { id, input },
  })
  return Group.fromJSON(data.group)
}

export const archiveGroup = (id) =>
  client.mutate({
    mutation: gql`
      mutation archiveGroup($id: Int!) {
        archiveGroup(id: $id)
      }
    `,
    variables: { id },
  })

export const upsertPeople = async (variables) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation upsertPeople(
        $newPeople: [NewPersonInput!]
        $people: [PersonInput!]
        $sendNotifications: Boolean!
      ) {
        upsertPeople(
          newPeople: $newPeople
          people: $people
          sendNotifications: $sendNotifications
        ) {
          ...person
        }
      }

      ${PersonFragmentDoc}
    `,
    variables,
  })
  return data.upsertPeople.map(Person.fromJSON)
}

export const archivePerson = async (id) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation archivePerson($id: Int!) {
        archivePerson(id: $id) {
          ...person
        }
      }

      ${PersonFragmentDoc}
    `,
    variables: { id },
  })
  return Person.fromJSON(data.archivePerson)
}

export const unarchivePerson = async (id) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation unarchivePerson($id: Int!) {
        unarchivePerson(id: $id) {
          ...person
        }
      }

      ${PersonFragmentDoc}
    `,
    variables: { id },
  })
  return Person.fromJSON(data.unarchivePerson)
}

export const deletePerson = async (id) =>
  client.mutate({
    mutation: gql`
      mutation deletePerson($id: Int!) {
        deletePerson(id: $id)
      }
    `,
    variables: { id },
  })
