import { gql } from '@apollo/client'
import {
  EmailDomainFragmentDoc,
  EmailSettingsFragmentDoc,
  EmailTemplateFragmentDoc,
  HandbookReminderFragmentDoc,
  ManagerDigestSettingsFragmentDoc,
} from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'

// updateEmailBranding --------------------------------------------------------

export const updateEmailBranding = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateEmailBranding($input: EmailBrandingInput!) {
        emailSettings: updateEmailBranding(input: $input) {
          ...emailSettings
        }
      }

      ${EmailSettingsFragmentDoc}
    `,
    variables: { input },
  })
  return data.emailSettings
}

// updateEmailSettings --------------------------------------------------------

export const updateEmailSettings = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateEmailSettings($input: EmailSettingsInput!) {
        emailSettings: updateEmailSettings(input: $input) {
          ...emailSettings
        }
      }

      ${EmailSettingsFragmentDoc}
    `,
    variables: { input },
  })
  return data.emailSettings
}

// addEmailDomain --------------------------------------------------------

export const addEmailDomain = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation addEmailDomain($input: EmailDomainInput!) {
        emailDomain: addEmailDomain(input: $input) {
          ...emailDomain
        }
      }

      ${EmailDomainFragmentDoc}
    `,
    variables: { input },
  })

  return data.emailDomain
}

// updateEmailDomain --------------------------------------------------------

export const updateEmailDomain = async (id, input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateEmailDomain($id: Int!, $input: EmailDomainInput!) {
        emailDomain: updateEmailDomain(id: $id, input: $input) {
          ...emailDomain
        }
      }

      ${EmailDomainFragmentDoc}
    `,
    variables: { id, input },
  })

  return data.emailDomain
}

// deleteEmailDomain --------------------------------------------------------

export const deleteEmailDomain = (id) =>
  client.mutate({
    mutation: gql`
      mutation deleteEmailDomain($id: Int!) {
        deleteEmailDomain(id: $id)
      }
    `,
    variables: { id },
  })

// sendTestEmail --------------------------------------------------------------

export const sendTestEmail = (variables) =>
  client.mutate({
    mutation: gql`
      mutation sendTestEmail($fromEmail: EmailAddress) {
        sendTestEmail(fromEmail: $fromEmail)
      }
    `,
    variables,
  })

// updateEmailTemplate --------------------------------------------------------

export const updateEmailTemplate = async (id, input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateEmailTemplate(
        $id: EmailTemplateId!
        $input: EmailTemplateInput!
      ) {
        emailTemplate: updateEmailTemplate(id: $id, input: $input) {
          ...emailTemplate
        }
      }

      ${EmailTemplateFragmentDoc}
    `,
    variables: { id, input },
  })
  return data.emailTemplate
}

// createHandbookReminder -----------------------------------------------------

export const createHandbookReminder = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation createHandbookReminder($input: HandbookReminderInput!) {
        handbookReminder: createHandbookReminder(input: $input) {
          ...handbookReminder
        }
      }

      ${HandbookReminderFragmentDoc}
    `,
    variables: { input },
  })
  return data.handbookReminder
}

// updateHandbookReminder -----------------------------------------------------

export const updateHandbookReminder = async (id, input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateHandbookReminder(
        $id: Int!
        $input: HandbookReminderInput!
      ) {
        handbookReminder: updateHandbookReminder(id: $id, input: $input) {
          ...handbookReminder
        }
      }

      ${HandbookReminderFragmentDoc}
    `,
    variables: { id, input },
  })
  return data.handbookReminder
}

// setHandbookReminderEnabled -------------------------------------------------

export const setHandbookReminderEnabled = async (id, isEnabled) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setHandbookReminderEnabled($id: Int!, $isEnabled: Boolean!) {
        handbookReminder: setHandbookReminderEnabled(
          id: $id
          isEnabled: $isEnabled
        ) {
          ...handbookReminder
        }
      }

      ${HandbookReminderFragmentDoc}
    `,
    variables: { id, isEnabled },
  })
  return data.handbookReminder
}

// deleteHandbookReminder -----------------------------------------------------

export const deleteHandbookReminder = (id) =>
  client.mutate({
    mutation: gql`
      mutation deleteHandbookReminder($id: Int!) {
        deleteHandbookReminder(id: $id)
      }
    `,
    variables: { id },
  })

// setManagerDigestSettings ---------------------------------------------------

export const setManagerDigestSettings = async (input) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation setManagerDigestSettings($input: ManagerDigestSettingsInput!) {
        managerDigestSettings: setManagerDigestSettings(input: $input) {
          ...managerDigestSettings
        }
      }

      ${ManagerDigestSettingsFragmentDoc}
    `,
    variables: { input },
  })
  return data.managerDigestSettings
}
