import { Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import pluralize from 'pluralize'
import React from 'react'
import {
  BillingEmailLayout,
  type BillingEmailProps,
  getBillingUrl,
} from '../template'

export const type = 'billing/credit-card/grace-period-ending'

export function renderEmail(props: BillingEmailProps) {
  const { billing, handbooksCount, organization, to } = props
  const { creditCard } = billing
  const billingUrl = getBillingUrl(organization)
  return {
    subject: 'Your Blissbook grace period ends tomorrow',
    body: (
      <BillingEmailLayout {...props}>
        <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
        <Paragraph />
        <Paragraph>
          Your Blissbook subscription is expiring because we were unable to
          charge the{' '}
          {creditCard ? `${creditCard.brand} credit card` : 'credit card'} on
          file and your 1-week grace period ends tomorrow. At that point, your
          users will no longer be able to access {organization.name}'s published{' '}
          {pluralize('handbook', handbooksCount)}.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          To keep your subscription active, click the red button on your
          organization’s subscription page:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={billingUrl}>{billingUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          Your current invoice is attached. If you have any questions or need
          help, please reply to this email or contact us at
          support@blissbook.com.
        </Paragraph>
        <Paragraph />
        <Paragraph>Thank you - we hope to continue serving you!</Paragraph>
        <Paragraph />
        <Paragraph>The Blissbook Team</Paragraph>
      </BillingEmailLayout>
    ),
  }
}
