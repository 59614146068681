import { emailSchema, textSchema } from '@blissbook/lib/document'
import { buildTextContent, renderHandbookEmail, toSigner } from './template'
import type { BuildHandbookNotificationArgs, HandbookEmailInput } from './types'

export const type = 'invitation-view-sign'
export const editButtonText =
  'Customize the invitation sent to someone who needs to view and then sign an acknowledgement'
export const title = 'Invitation to View and Sign'
export const to = toSigner
export const subjectContent = textSchema.htmlToJSON(
  'Welcome to your <variable name="handbook.name"></variable>!',
)
export const ccManagers = false
export const buttonContent = textSchema.htmlToJSON(
  'View & Sign the <variable name="handbook.name"></variable>',
) // (NOTE: used by invitation-sign as well)

export const messageContent = emailSchema.htmlToJSON(`
  <p>You're invited to view and acknowledge your receipt of the <variable name="organization.name"></variable> <variable name="handbook.name"></variable>. To do so, click the button above and then click the “Sign It” button.</p>
  <hr />
  <p>Hi <variable name="to.firstName"></variable>,</p>
  <p></p>
  <p>Please take some time to view your digital <variable name="handbook.name"></variable> and sign the acknowledgement form within.</p>
  <p></p>
  <p>You can always access the <variable name="handbook.name"></variable> by signing in at <variable name="handbook.viewingLink"></variable>.</p>
  <p></p>
  <p>If you have any questions, let us know!</p>
  <p></p>
  <p>Have a great day,</p>
  <p>Your HR Team</p>
`)

export const buildNotification = (args: BuildHandbookNotificationArgs) => {
  const { from, handbook, organization } = args
  return {
    subjectContent: buildTextContent(subjectContent, args),
    buttonContent: buildTextContent(buttonContent, args),
    messageContent: emailSchema.htmlToJSON(`
      <p>You're invited to view and acknowledge your receipt of the ${
        organization.name
      } ${handbook.name}. To do so, click the button above and then click the “Sign It” button.</p>
      <hr />
      <p>Hi <variable name="to.firstName"></variable>,</p>
      <p></p>
      <p>We're excited to announce the launch of our brand new digital ${
        handbook.name
      }! It has everything you need to know about working at ${
        organization.name
      } and, because it's digital, you can access it 24/7 from any device.</p>
      <p></p>
      <p>Please take a moment to read through the ${handbook.name} and acknowledge your receipt of the handbook.</p>
      <p></p>
      <p>If you have any questions, let me know!</p>
      <p></p>
      <p>Have a great day,</p>
      <p>${from ? from.fullName : 'Your HR Team'}</p>
    `),
  }
}

export function renderEmail(props: HandbookEmailInput) {
  const { handbook } = props
  return renderHandbookEmail({
    ...props,
    authUrl: handbook.publishedUrl,
  })
}
