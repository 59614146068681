import { emailSchema, textSchema } from '@blissbook/lib/document'
import {
  buttonContent,
  messageContent,
  renderEmail,
} from './invitation-view-sign'
import { buildTextContent, toSigner } from './template'
import type { BuildHandbookNotificationArgs } from './types'
export { renderEmail }

export const type = 'invitation-sign'
export const to = toSigner
export const subjectContent = textSchema.htmlToJSON(
  'The <variable name="handbook.name"></variable> needs your signature',
)
export const ccManagers = false
export { buttonContent, messageContent }

export const buildNotification = (args: BuildHandbookNotificationArgs) => {
  const { from, handbook, organization } = args
  return {
    subjectContent: buildTextContent(subjectContent, args),
    buttonContent: buildTextContent(buttonContent, args),
    messageContent: emailSchema.htmlToJSON(`
      <p>You're invited to view and acknowledge your receipt of the ${
        organization.name
      } ${handbook.name}. To do so, click the button above and then click the “Sign It” button.</p>
      <hr />
      <p>Hi <variable name="to.firstName"></variable>,</p>
      <p></p>
      <p>Please take a moment to view the current version of the ${
        handbook.name
      } and sign the acknowledgement form within.</p>
      <p></p>
      <p>If you have any questions, let us know!</p>
      <p></p>
      <p>Have a great day,</p>
      <p>${from ? from.fullName : 'Your HR Team'}</p>
    `),
  }
}
