import {
  BubbleToolbar,
  type BubbleToolbarProps,
} from '@blissbook/ui/editor/toolbar/BubbleToolbar'
import { useMouseDownOutside } from '@blissbook/ui/hooks/useMouseDownOutside'
import React, { useState } from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'

export type CKEditorBubbleToolbarProps = BubbleToolbarProps & {
  editor: BlissbookEditor
}

export function CKEditorBubbleToolbar({
  editor,
  ...props
}: CKEditorBubbleToolbarProps) {
  const [toolbarNode, setToolbarNode] = useState<HTMLElement>()

  useMouseDownOutside(() => {
    editor.setInlineToolbarType(undefined)
  }, [toolbarNode])

  return <BubbleToolbar {...props} ref={setToolbarNode} />
}
