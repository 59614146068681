import './NotificationEmailView.scss'
import { sanitizeHtml } from '@blissbook/lib/sanitize'
import classnames from 'classnames'
import each from 'lodash/each'
import React from 'react'
import { useEffect, useState } from 'react'

export type NotificationEmailViewProps = {
  body: string
  from?: string
  subject: string
  to: string
} & React.HTMLAttributes<HTMLDivElement>

export const NotificationEmailView = ({
  body,
  className,
  from,
  subject,
  to,
  ...props
}: NotificationEmailViewProps) => (
  <div {...props} className={classnames('notification-email', className)}>
    <table className='tw-mb-2'>
      <tbody>
        {subject && (
          <tr>
            <td>Subject:</td>
            <td>{subject}</td>
          </tr>
        )}
        <tr>
          <td>To:</td>
          <td>{to}</td>
        </tr>
        <If condition={from !== undefined}>
          <tr>
            <td>From:</td>
            <td>{from}</td>
          </tr>
        </If>
      </tbody>
    </table>

    {body && <NotificationBodyView className='disable-links' html={body} />}
  </div>
)

const previewWidth = 720

type NotificationBodyViewProps = {
  className?: string
  html: string
}

function NotificationBodyView({ html, ...props }: NotificationBodyViewProps) {
  const [iframe, setIframe] = useState<HTMLIFrameElement>()
  const [node, setNode] = useState<HTMLElement>()

  useEffect(() => {
    if (!iframe || !node) return

    // Set the html
    let doc: Document
    if (iframe.contentDocument) {
      doc = iframe.contentDocument
    } else {
      doc = iframe.contentWindow.document
    }

    doc.open()
    doc.write(sanitizeHtml(html))
    doc.close()

    // Override body styles
    doc.body.style.margin = '0'
    doc.body.style.overflow = 'hidden'
    doc.body.style.width = `${previewWidth}px`

    // Blissbook images may have expired. Bring them back, so we can show what the email looked like when it was sent
    const bbImages =
      doc.body.querySelectorAll<HTMLImageElement>('img[data-image-id]')
    each(bbImages, (node) => {
      const { imageId } = node.dataset
      node.dataset.originalSrc = node.src
      node.src = `/images/${imageId}`
    })

    // Disable links
    const links = doc.body.querySelectorAll('a')
    each(links, (node) => {
      node.style.cursor = 'default'
      node.style.pointerEvents = 'none'
    })

    // Calculate / set the scale
    const scale = node.offsetWidth / previewWidth
    iframe.style.transform = `scale(${scale})`
    iframe.style.transformOrigin = '0 0'

    // Calculate the height
    const updateHeight = () => {
      const height = doc.body.offsetHeight
      iframe.style.height = `${height}px`
      node.style.height = `${height * scale}px`
    }
    updateHeight()

    // Update height when images load
    const images = doc.body.querySelectorAll('img')
    each(images, (node) => {
      node.onload = updateHeight
    })
  }, [node])

  return (
    <div ref={setNode}>
      <iframe
        {...props}
        css={{
          border: 'none',
          height: '100%',
        }}
        ref={setIframe}
        width={previewWidth}
      />
    </div>
  )
}
