import { useForceRender } from '@blissbook/ui/hooks/useForceRender'
import { Dropdown } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { DecoupledEditor } from 'ckeditor5'
import React, { useEffect } from 'react'
import { useCKEditorContext } from '../CKEditorContext'

export type CKEditorModeDropdownProps = {
  editor: DecoupledEditor
}

type CKEditorModeOption = {
  className: string
  icon: React.ReactNode
  label: string
  value: boolean
}

const options: CKEditorModeOption[] = [
  {
    className: 'tw-border-blurple-500 tw-text-blurple-500',
    icon: <FontAwesomeIcon icon={['far', 'pen']} />,
    label: 'Edit',
    value: false,
  },
  {
    className: 'tw-border-green-700 tw-text-green-700',
    icon: <FontAwesomeIcon icon={['far', 'pen-field']} />,
    label: 'Suggest',
    value: true,
  },
]

export function CKEditorModeDropdown({ editor }: CKEditorModeDropdownProps) {
  const { trackingChanges, setTrackingChanges } = useCKEditorContext()
  const trackChanges = editor.commands.get('trackChanges')

  // Keep the editor in the desired state
  useEffect(() => {
    if (trackChanges.value !== trackingChanges) {
      editor.execute('trackChanges')
    }
  }, [trackChanges.value, trackingChanges])

  // Get the active option
  const activeOption = options.find(
    (option) => option.value === trackingChanges,
  )

  // Set the active option
  function setActiveOption(option: CKEditorModeOption) {
    setTrackingChanges(option.value)
  }

  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        className={cx(
          'btn btn-input btn-sm tw-inline-flex tw-gap-2',
          activeOption.className,
        )}
        caret={false}
      >
        {activeOption.icon}
        {activeOption.label}
        <FontAwesomeIcon
          className='tw-text-sm'
          icon={['fas', 'chevron-down']}
        />
      </Dropdown.ToggleButton>
      <Dropdown.Menu>
        {options.map((option, index) => (
          <Dropdown.Item key={index} onClick={() => setActiveOption(option)}>
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
