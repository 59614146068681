import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import type { ButtonView, Editor, Element } from 'ckeditor5'

export function getIconPath(icon: IconDefinition) {
  const [width, height, , , svgPath] = icon.icon
  return `<svg viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg"><path d="${svgPath}"/></svg>`
}

export function getElementAttributes(element: Element) {
  const attributes: Record<string, unknown> = {}
  for (const [key, value] of element.getAttributes()) {
    attributes[key] = value
  }
  return attributes
}

export function bindButtonView(
  buttonView: ButtonView,
  {
    editor,
    isOn,
  }: {
    editor: Editor
    isOn?: (selectedElement: Element) => boolean
  },
) {
  function handleChange() {
    const { isReadOnly } = editor
    const selectedElement = editor.model.document.selection.getSelectedElement()
    buttonView.isEnabled = !isReadOnly
    buttonView.isOn = isOn && selectedElement ? isOn(selectedElement) : false
  }

  // Listen for changes in the document
  editor.model.document.selection.on('change', handleChange)
  editor.model.document.on('change:data', handleChange)
  handleChange()
}
