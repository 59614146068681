import { type ImageUtils, Plugin, type ViewElement } from 'ckeditor5'
import { imageShapes, imageShapesById } from './ImageShape'

const imageTypes = ['imageBlock', 'imageInline']

export class ImageEditing extends Plugin {
  init() {
    const { editor } = this
    const imageUtils: ImageUtils = editor.plugins.get('ImageUtils')
    const { conversion } = editor

    for (const imageType of imageTypes) {
      editor.model.schema.extend(imageType, {
        allowAttributes: ['imageShape'],
      })

      conversion.for('downcast').add((dispatcher) => {
        dispatcher.on(
          `attribute:imageShape:${imageType}`,
          (_event, data, conversionApi) => {
            const viewWriter = conversionApi.writer
            const viewElement = conversionApi.mapper.toViewElement(data.item)
            const imageElement = imageUtils.findViewImgElement(viewElement)
            if (!imageElement) return

            // Remove any previous shapes
            for (const shape of imageShapes) {
              viewWriter.removeClass(shape.className, imageElement)
            }

            // If we have a new value, set the class
            const shape = imageShapesById.get(data.attributeNewValue)
            if (shape) {
              viewWriter.addClass(shape.className, imageElement)
            }
          },
        )
      })

      conversion.for('upcast').attributeToAttribute({
        view: {
          key: 'class',
          value: /img-[\S]+/,
        },
        model: {
          key: 'imageShape',
          value: (viewElement: ViewElement) => {
            const regexp = /img-([\S]+)/
            const match = viewElement.getAttribute('class').match(regexp)

            return match[1]
          },
        },
      })
    }
  }
}
