import { Plugin, Widget } from 'ckeditor5'

export class IndentBlockUI extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    const { editor } = this
    const indent = editor.commands.get('indent')
    const outdent = editor.commands.get('outdent')

    editor.keystrokes.set('Tab', (_data, cancel) => {
      if (indent.isEnabled) {
        indent.execute()
      }
      cancel()
    })

    editor.keystrokes.set('Shift+Tab', (_data, cancel) => {
      if (outdent.isEnabled) {
        outdent.execute()
      }
      cancel()
    })
  }
}
