import { cx } from '@emotion/css'
import React from 'react'
import type { BlissbookEditor } from '../BlissbookEditor'
import { useSelectedState, useSelectionKey } from '../hooks'
import {
  AddLinkToolbar,
  EditLinkToolbar,
  type InlineToolbarType,
  ResizeToolbar,
} from './toolbar'

const resizeNames = ['pdf', 'imageBlock', 'imageInline', 'media']

export function CKEditorTools({
  className,
  editor,
  toolbarType,
}: {
  className?: string
  editor: BlissbookEditor
  toolbarType: InlineToolbarType | undefined
}) {
  const selected = useSelectedState(editor)
  const selectionKey = useSelectionKey(editor)

  let toolbar: React.ReactNode
  if (toolbarType === 'link') {
    toolbar = selected.linkHref ? (
      <EditLinkToolbar editor={editor} initialValue={selected.linkHref} />
    ) : (
      <AddLinkToolbar editor={editor} />
    )
  } else if (selected.linkHref) {
    toolbar = (
      <EditLinkToolbar editor={editor} initialValue={selected.linkHref} />
    )
  } else if (resizeNames.includes(selected.element?.name)) {
    toolbar = <ResizeToolbar editor={editor} element={selected.element} />
  }

  return (
    <div
      className={cx('tw-pointer-events-none tw-p-3', className)}
      key={selectionKey}
    >
      {toolbar}
    </div>
  )
}
