import { getColor } from '@blissbook/ui/branding'
import { cx } from '@emotion/css'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Typography } from './Typography'

const layoutSizes = {
  lg: {
    headingProps: {
      className: 'tw-mb-2 tw-mt-0.5',
      variant: 'h4',
    },
    iconClassName: 'tw-text-blurple-500 tw-mr-2 tw-text-2xl',
    statusClassName: 'tw-text-sm',
  },
  sm: {
    headingProps: {
      className: 'tw-mb-2 tw-mt-0',
      variant: 'h6',
    },
    iconClassName: 'tw-text-black-faded tw-mr-2 tw-text-lg',
    statusClassName: 'tw-text-xs',
  },
}

export type HeadingLayoutSize = keyof typeof layoutSizes

const themes = {
  sunshine: {
    className:
      'tw-inline-flex tw-border-2 tw-rounded tw-border-sunshine-500 tw-bg-sunshine-50 tw-p-3 tw-pr-4',
    iconClassName: 'tw-text-sunshine-500',
  },
  blurple: {
    className:
      'tw-inline-flex tw-min-w-[500px] tw-border-2 tw-rounded tw-border-blurple-500 tw-bg-blurple-50 tw-p-3 tw-pr-4',
    iconClassName: 'tw-text-sunshine-500',
  },
}

export type HeadingLayoutTheme = keyof typeof themes

export type HeadingLayoutProps = {
  action?: React.ReactNode
  children?: React.ReactNode
  className?: string
  compact?: boolean
  heading: string
  icon?: IconProp
  size?: HeadingLayoutSize
  status?: string
  statusColor?: string
  theme?: HeadingLayoutTheme
  variant?: string
}

export const HeadingLayout = ({
  action,
  children,
  className,
  compact,
  heading,
  icon,
  size = 'lg',
  status,
  statusColor,
  theme,
  ...props
}: HeadingLayoutProps) => {
  const { headingProps, iconClassName, statusClassName } = layoutSizes[size]
  const themeProps = themes[theme]

  return (
    <div {...props}>
      <div
        className={cx(
          'tw-flex',
          'tw-items-start',
          themeProps?.className,
          className,
        )}
      >
        <If condition={icon !== undefined}>
          <FontAwesomeIcon
            className={cx(iconClassName, themeProps?.iconClassName)}
            fixedWidth
            icon={icon}
          />
        </If>

        <div className='tw-flex-1 tw-min-w-0'>
          <Typography {...headingProps}>
            {heading}
            <If condition={status !== undefined}>
              <span
                children={status}
                className={cx('tw-uppercase tw-ml-2', statusClassName)}
                style={{
                  color: getColor(statusColor),
                  letterSpacing: 2,
                }}
              />
            </If>
          </Typography>

          {children}

          <If condition={action && compact}>
            <div className='tw-mt-2'>{action}</div>
          </If>
        </div>

        <If condition={action && !compact}>
          <div className='tw-ml-6'>{action}</div>
        </If>
      </div>
    </div>
  )
}
