import { Command } from 'ckeditor5'

export const annotationAttributeKey = 'annotationId'

export class AddAnnotationCommand extends Command {
  execute(options: { annotationId: string }) {
    const { annotationId } = options
    const { model } = this.editor
    const { selection } = model.document

    model.change((writer) => {
      if (selection.isCollapsed) return

      // Wrap selected text in an annotation element
      const ranges = model.schema.getValidRanges(
        selection.getRanges(),
        annotationAttributeKey,
      )

      // Add the annotation to each range
      for (const range of ranges) {
        writer.setAttribute(annotationAttributeKey, annotationId, range)
      }

      // Move the cursor to the end of the selection
      const range = selection.getFirstRange()
      writer.setSelection(range.end)
    })
  }
}
