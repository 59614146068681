import { WhodunnitView } from '@blissbook/application/lib'
import type { Annotation } from '@blissbook/lib/blissbook'
import { useScrollIntoView } from '@blissbook/ui/hooks/useScrollIntoView'
import { Dropdown } from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { AnnotationForm } from './AnnotationForm'
import { useAnnotationEditor } from './useAnnotationEditor'

export const annotationClassName =
  'tw-group tw-px-2 tw-py-2 tw-bg-white tw-rounded tw-shadow-md'

export function AnnotationView({
  active,
  annotation,
  editor,
  index,
  onSetActive,
}: {
  active: boolean
  annotation: Annotation
  editor?: BlissbookEditor
  index: number
  onSetActive: () => void
}) {
  const [isEditing, setEditing] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [, setNode] = useScrollIntoView({
    behavior: 'smooth',
    block: 'nearest',
  })
  const { html, pmEditor } = useAnnotationEditor(annotation.html)

  function handleEditAnnotation() {
    setEditing(true)
  }

  function handleRemoveAnnotation() {
    editor.execute('replaceAnnotation', {
      annotationId: annotation.id,
      newAnnotationId: undefined,
    })
    editor.focus()
  }

  async function handleSubmit() {
    setSubmitting(true)

    try {
      const newAnnotation = await editor.createAnnotation(html)
      editor.execute('replaceAnnotation', {
        annotationId: annotation.id,
        newAnnotationId: newAnnotation.id,
      })
      editor.focus()
      setEditing(false)
    } catch (error) {
      handleError(error)
    }

    setSubmitting(false)
  }

  return (
    <div
      className={cx(
        annotationClassName,
        'tw-border',
        !isEditing && 'tw-cursor-pointer',
        active && !isEditing ? 'tw-border-blurple-500' : 'tw-border-gray-100',
      )}
      onClick={onSetActive}
      onKeyUp={(event) => {
        if (event.key === 'Enter') onSetActive()
      }}
      ref={active ? setNode : undefined}
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-gap-1 tw-w-full'>
        <div className='tw-font-semibold tw-text-gray-600 tw-text-sm'>
          {isEditing
            ? `Edit Annotation #${index + 1}`
            : `Annotation #${index + 1}`}
        </div>

        {editor && !isEditing && (
          <Dropdown.Provider>
            <Dropdown.ToggleButton
              caret={false}
              className='btn btn-input tw-border tw-py-1 tw-px-1.5 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity'
            >
              <FontAwesomeIcon icon={['far', 'ellipsis']} />
            </Dropdown.ToggleButton>
            <Dropdown.Menu placement='bottom-end'>
              <Dropdown.Item onClick={handleEditAnnotation}>
                <FontAwesomeIcon
                  className='dropdown-item-icon'
                  icon={['far', 'pencil']}
                />
                Edit Annotation
              </Dropdown.Item>
              <Dropdown.Item onClick={handleRemoveAnnotation}>
                <FontAwesomeIcon
                  className='dropdown-item-icon'
                  icon={['far', 'trash-alt']}
                />
                Remove Annotation
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown.Provider>
        )}
      </div>

      <Choose>
        <When condition={!isEditing}>
          <div
            className='rw-wysiwyg tw-my-1'
            // biome-ignore lint/security/noDangerouslySetInnerHtml: filtered manually
            dangerouslySetInnerHTML={{ __html: annotation?.html }}
          />
        </When>
        <When condition={pmEditor !== undefined}>
          <AnnotationForm
            disabled={isSubmitting}
            onCancel={() => setEditing(false)}
            onSubmit={handleSubmit}
            pmEditor={pmEditor}
          />
        </When>
      </Choose>

      {!isEditing && (
        <div className='tw-text-gray-500 tw-text-sm'>
          <WhodunnitView
            dateFormat='absolute'
            timestamp={annotation.createdAt}
            whodunnit={annotation.createdBy}
          />
        </div>
      )}
    </div>
  )
}
