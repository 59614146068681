import { Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import pluralize from 'pluralize'
import React from 'react'
import {
  BillingEmailLayout,
  type BillingEmailProps,
  getBillingUrl,
} from '../template'

export const type = 'billing/credit-card/grace-period-ended'

export function renderEmail(props: BillingEmailProps) {
  const { billing, handbooksCount, organization, to } = props
  const { creditCard } = billing
  const billingUrl = getBillingUrl(organization)
  return {
    subject: `Your Blissbook ${
      handbooksCount === 1 ? 'handbook has' : 'handbooks have'
    } been unpublished`,
    body: (
      <BillingEmailLayout {...props}>
        <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
        <Paragraph />
        <Paragraph>
          Your Blissbook subscription expired because we were unable to charge
          the {creditCard ? `${creditCard.brand} credit card` : 'credit card'}{' '}
          on file and your 1-week grace period has ended. Your users may no
          longer access {organization.name}'s published{' '}
          {pluralize('handbook', handbooksCount)}.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          To restart your organization's subscription or to learn more, please
          visit your subscription page:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={billingUrl}>{billingUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          Your current invoice is attached. If you have any questions or need
          help, please reply to this email or contact us at
          support@blissbook.com.
        </Paragraph>
        <Paragraph />
        <Paragraph>Thank you - we hope to have you back sometime!</Paragraph>
        <Paragraph />
        <Paragraph>The Blissbook Team</Paragraph>
      </BillingEmailLayout>
    ),
  }
}
