import { type Node, renderText, textSchema } from '@blissbook/lib/document'
import {
  type EmailBranding,
  EmailBrandingContext,
  formatAuthUrl,
} from '@blissbook/lib/email'
import {
  EmailLayout,
  Link,
  Paragraph,
  prependEmailMargin,
  renderEmailContent,
} from '@blissbook/lib/email/renderer'
import { getFirstName } from '@blissbook/lib/name'
import React from 'react'
import type { ReactNode } from 'react'
import type { HandbookEmailInput, HandbookEmailVariables } from './types'

export const toEditor = 'Someone who can edit'
export const toSigner =
  'Someone who needs to view and then sign an acknowledgement'
export const toViewer = 'Someone who can view'
export const toManager =
  'A manager with direct reports who need to sign an acknowledgement'

export type HandbookEmailProps = HandbookEmailInput & {
  authUrl: string
}

export function renderHandbookEmail({
  buttonContent,
  messageContent,
  subjectContent,
  ...props
}: HandbookEmailProps) {
  return {
    subject: renderTextContent(subjectContent, props),
    body: (
      <HandbookEmailBody
        {...props}
        buttonContent={buttonContent}
        messageContent={messageContent}
      />
    ),
  }
}

function getTextVariables({
  handbook,
  organization,
  to,
}: HandbookEmailVariables) {
  return {
    'handbook.name': handbook.name,
    'organization.name': organization.name,
    'to.firstName': getFirstName(to?.fullName),
    'to.fullName': to?.fullName,
  }
}

export function buildTextContent(
  content: Node[],
  input: HandbookEmailVariables,
) {
  const text = renderTextContent(content, input)
  return textSchema.htmlToJSON(text)
}

export function renderTextContent(
  content: Node[],
  input: HandbookEmailVariables,
) {
  const variables = getTextVariables(input)
  return renderText(content, variables)
}

export type HandbookEmailBodyProps = Omit<
  HandbookEmailProps,
  'subjectContent'
> & {
  table?: ReactNode
}

export const HandbookEmailBody: React.FC<HandbookEmailBodyProps> = ({
  authUrl,
  buttonContent,
  branding,
  isAuthEnabled,
  messageContent,
  note,
  table,
  ...props
}) => (
  <EmailLayout
    branding={branding}
    callToAction={{
      href: formatAuthUrl(authUrl, isAuthEnabled),
      text: renderTextContent(buttonContent, props),
    }}
  >
    <div className='email-message'>
      {messageContent && renderMessageContent(messageContent, props)}

      {table && prependEmailMargin(table)}

      {note}
    </div>
  </EmailLayout>
)

type HandbookEmailNodeProps = {
  branding: EmailBranding
  note: string
}
export function renderHandbookEmailNote({
  branding,
  note,
}: HandbookEmailNodeProps) {
  return (
    <EmailBrandingContext.Provider value={branding}>
      {prependEmailMargin(
        <Paragraph>
          <em>
            <strong>{note}</strong>
          </em>
        </Paragraph>,
      )}
    </EmailBrandingContext.Provider>
  )
}

function getEmailVariables(input: HandbookEmailVariables) {
  const { handbook, recipient } = input
  const editHandbookUrl = handbook.url + '/edit'
  return {
    ...getTextVariables(input),
    'handbook.editorLink': (
      <Link href={editHandbookUrl}>{editHandbookUrl}</Link>
    ),
    'handbook.viewingLink': (
      <Link href={handbook.publishedUrl}>{handbook.publishedUrl}</Link>
    ),
    signatureDueDate: recipient?.signatureDueDate,
    signatureRequestedAt: recipient?.signatureRequestedAt,
  }
}

export function renderMessageContent(
  content: Node[],
  input: HandbookEmailVariables,
) {
  const variables = getEmailVariables(input)
  return renderEmailContent(content, { variables })
}
