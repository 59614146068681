import { cx } from '@emotion/css'
import React from 'react'
import { Dropdown } from '../popper'
import { ProfileView } from './ProfileView'
import type { UserProfile } from './UserProfile'
import { UserProfileView } from './UserProfileView'

export type UserProfilesViewProps = {
  className?: string
  size: number
  users: UserProfile[]
}

export const UserProfilesView = ({
  className,
  size,
  users,
  ...props
}: UserProfilesViewProps) => {
  const usersToDisplay = users.length > 3 ? users.slice(0, 3) : users
  const hiddenUsers = users.length > 3 ? users.slice(3) : []

  return (
    <div
      {...props}
      className='tw-inline-flex tw-items-center tw-justify-center'
    >
      {usersToDisplay.map((user, index) => (
        <UserProfileView
          className={cx(index > 0 && '-tw-ml-2')}
          key={index}
          size={size}
          user={user}
        />
      ))}
      {hiddenUsers.length > 0 && (
        <Dropdown.Provider>
          <Dropdown.ToggleButton className='-tw-ml-2' caret={false}>
            <ProfileView
              className='tw-bg-black'
              size={size}
              text={`+${hiddenUsers.length}`}
            />
          </Dropdown.ToggleButton>
          <Dropdown.Menu>
            {hiddenUsers.map((user, index) => (
              <Dropdown.Item
                key={index}
                className='tw-inline-flex tw-items-center tw-justify-center tw-gap-1'
              >
                <UserProfileView disableTooltip size={size} user={user} />
                {user.fullName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown.Provider>
      )}
    </div>
  )
}

UserProfilesView.displayName = 'UserProfilesView'
