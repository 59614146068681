import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export const HoverEllipsis: React.FC<{
  className?: string
}> = ({ className, ...props }) => (
  <div
    {...props}
    className={cx(
      'tw-absolute tw-bottom-0 tw-right-0 tw-top-0 tw-flex tw-items-center tw-pointer-events-none tw-text-gray-500 tw-opacity-100 tw-transition-opacity group-hover:tw-opacity-0',
      className,
    )}
  >
    <FontAwesomeIcon icon={['far', 'ellipsis-h']} />
  </div>
)
