import { LinkToolbarForm } from '@blissbook/ui/editor/marks/link/LinkToolbarForm'
import React, { useState } from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { CKEditorSelection } from '../CKEditorSelection'
import { CKEditorBubbleToolbar } from './CKEditorBubbleToolbar'

export function AddLinkToolbar(props: {
  editor: BlissbookEditor
}) {
  const { editor } = props
  const [selectionNode, setSelectionNode] = useState<HTMLDivElement>()

  function handleCancel() {
    editor.setInlineToolbarType(undefined)
    editor.focus()
  }

  function handleSubmit(href: string) {
    editor.execute('link', href)
    editor.setInlineToolbarType(undefined)
    editor.focus()
  }

  return (
    <>
      <CKEditorBubbleToolbar {...props} offset={4} refEl={selectionNode}>
        <LinkToolbarForm
          bookmarks={editor.bookmarks}
          bookmarkLabel='document name'
          onCancel={handleCancel}
          onSubmit={handleSubmit}
        />
      </CKEditorBubbleToolbar>

      <CKEditorSelection editor={editor} ref={setSelectionNode} />
    </>
  )
}
