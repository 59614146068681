import {
  useBilling,
  useHandbooks,
  useOrganization,
  useSession,
} from '@blissbook/application/hooks'
import {
  bootIntercom,
  shutdownIntercom,
} from '@blissbook/ui/util/integrations/intercom'
import { useEffect } from 'react'

export const IntercomChat = () => {
  const billing = useBilling()
  const handbooks = useHandbooks()
  const organization = useOrganization()
  const session = useSession()
  const canChat =
    session.can('organization.admin') ||
    handbooks.some((h) => h.can('handbook.draft.edit'))

  useEffect(() => {
    if (!canChat) return
    bootIntercom({ billing, organization, session })
    return shutdownIntercom
  }, [canChat])

  return null
}
