import { useEffect, useState } from 'react'

export function useScrollIntoView(arg?: boolean | ScrollIntoViewOptions) {
  const [node, setNode] = useState<HTMLElement>()

  useEffect(() => {
    if (!node) return
    node.scrollIntoView(arg)
  }, [arg, node])

  return [node, setNode] as const
}
