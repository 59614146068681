import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { faVideoPlus } from '@fortawesome/pro-regular-svg-icons/faVideoPlus'
import { ButtonView, Plugin } from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { getIconPath } from './util'

export class MediaUI extends Plugin {
  init() {
    const editor = this.editor as BlissbookEditor
    const { t } = editor

    editor.ui.componentFactory.add('insertMedia', (locale) => {
      const buttonView = new ButtonView(locale)
      const command = editor.commands.get('mediaEmbed')

      buttonView.set({
        icon: getIconPath(faVideoPlus),
        label: t('Insert Media'),
        tooltip: true,
      })

      buttonView.bind('isEnabled').to(command, 'isEnabled')

      // Callback executed once the button is clicked
      buttonView.on('execute', async () => {
        const mediaUrl = await editor.pickMedia()

        if (mediaUrl) {
          editor.model.change((writer) => {
            // Insert at the current selection or at the end of the content
            const mediaElement = writer.createElement('media', {
              url: mediaUrl,
              width: '100%',
            })

            // Insert in the current selection or at the end of the editor's content
            editor.model.insertContent(
              mediaElement,
              editor.model.document.selection,
            )
          })
        }

        editor.focus()
      })

      return buttonView
    })

    editor.ui.componentFactory.add('editMedia', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        icon: getIconPath(faPenToSquare),
        label: t('Replace Media'),
        tooltip: true,
      })

      // Callback executed once the button is clicked
      view.on('execute', async () => {
        const { selection } = editor.model.document
        const media = selection.getSelectedElement()
        const prevUrl = media.getAttribute('url') as string

        const url = await editor.pickMedia(prevUrl)
        if (url) {
          editor.model.change((writer) => {
            writer.setAttribute('url', url, media)
          })
        }

        editor.focus()
      })

      return view
    })

    editor.ui.componentFactory.add('removeMedia', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        icon: getIconPath(faTrashAlt),
        label: t('Remove Media'),
        tooltip: true,
      })

      // Callback executed once the button is clicked
      view.on('execute', async () => {
        const { selection } = editor.model.document
        const media = selection.getSelectedElement()

        if (media) {
          editor.model.change((writer) => {
            writer.remove(media)
          })
        }

        editor.focus()
      })

      return view
    })
  }
}
