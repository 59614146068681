import { gql } from '@apollo/client'
import { Integration } from '@blissbook/common/integration'
import {
  IntegrationDetailsFragmentDoc,
  IntegrationFragmentDoc,
} from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'

export const INTEGRATIONS_QUERY = gql`
  query getIntegrations {
    integrations {
      ...integrationDetails
    }
  }

  ${IntegrationDetailsFragmentDoc}
`

export async function addBambooHRIntegration(variables: any) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation addBambooHRIntegration(
        $email: EmailAddress!
        $hostname: String!
        $password: String!
        $subdomain: String!
      ) {
        integration: addBambooHRIntegration(
          email: $email
          hostname: $hostname
          password: $password
          subdomain: $subdomain
        ) {
          ...integration
        }
      }

      ${IntegrationFragmentDoc}
    `,
    variables,
  })

  return Integration.fromJSON(data.integration)
}

export async function addSftpIntegration() {
  const { data } = await client.mutate({
    mutation: gql`
      mutation addSftpIntegration {
        integration: addSftpIntegration {
          ...integration
          fingerprints {
            sha256
            md5
          }
          username
          password
        }
      }

      ${IntegrationFragmentDoc}
    `,
  })

  return Integration.fromJSON(data.integration)
}

export async function updateBambooHRIntegration(id: number, input: any) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateBambooHRIntegration(
        $id: Int!
        $input: UpdateBambooHRIntegrationInput!
      ) {
        integration: updateBambooHRIntegration(id: $id, input: $input) {
          ...integration
        }
      }

      ${IntegrationFragmentDoc}
    `,
    update(cache) {
      cache.evict({ fieldName: 'metadataKeys' })
      cache.evict({ fieldName: 'metadataValues' })
    },
    variables: { id, input },
  })

  return Integration.fromJSON(data.integration)
}

export async function updateRipplingIntegration(id: number, input: any) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation updateRipplingIntegration(
        $id: Int!
        $input: UpdateRipplingIntegrationInput!
      ) {
        integration: updateRipplingIntegration(id: $id, input: $input) {
          ...integration
        }
      }

      ${IntegrationFragmentDoc}
    `,
    variables: { id, input },
  })

  return Integration.fromJSON(data.integration)
}

export async function deleteIntegration(id: number) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation deleteIntegration($id: Int!) {
        deleteIntegration(id: $id)
      }
    `,
    variables: { id },
  })

  return data
}
