import * as api from '@blissbook/application/api'
import {
  ApplicationDocument,
  HandbookDocument,
  HandbookSignatureRoundsDocument,
  HandbooksDocument,
  OrganizationDocument,
} from '@blissbook/application/graph'
import { getCurrentInvoice } from '@blissbook/common/billing/invoice'
import Handbook from '@blissbook/common/handbook'
import Organization from '@blissbook/common/organization'
import config from '@blissbook/ui-config'
import { client } from '@blissbook/ui/util/apollo'
import { updateIntercom } from '@blissbook/ui/util/integrations/intercom'
/* global StripeCheckout */
import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import last from 'lodash/last'
import pick from 'lodash/pick'
import uniq from 'lodash/uniq'
import { dispatch, store } from './store'

const dispatchQuery = async ({ cache, query, variables }) => {
  // Query data
  const res = await client.query({
    fetchPolicy: 'no-cache',
    query,
    variables,
  })

  // Add to Apollo InMemoryCache (if desired)
  if (cache) {
    client.cache.writeQuery({
      query,
      data: res.data,
      variables,
    })
  }

  // Add to custom store
  const data = api.parseQueryResponseData(res.data)
  return dispatch('mergeRoot', { data })
}

export const loadApplication = () =>
  dispatchQuery({
    cache: true,
    query: ApplicationDocument,
  })

// BILLING

export const loadBillingSummary = () =>
  dispatchQuery({
    cache: true,
    query: api.BILLING_SUMMARY_QUERY,
  })

export const loadBillingDetails = () =>
  dispatchQuery({
    query: api.BILLING_DETAILS_QUERY,
  })

export const updateBilling = async (changes) => {
  const billing = await api.updateBilling(changes)
  dispatch('updateBilling', { billing })

  const { state } = store
  updateIntercom(state)
}

export const updateBillingAdmin = async (changes) => {
  const billing = await api.updateBillingAdmin(changes)
  dispatch('updateBilling', { billing })

  const { state } = store
  updateIntercom(state)
}

export const updateSubscription = async (subscription) => {
  const { isPaid } = store.state.billing
  const { startCount, months, monthlyGrowthRate } = subscription

  const billing = await api.updateSubscription({
    termStartCount: isPaid ? null : startCount,
    termMonths: months,
    termMonthlyGrowthRate: monthlyGrowthRate,
  })
  dispatch('updateBilling', { billing })
}

export const setPayByCheck = async () => {
  const billing = await api.setPayByCheck()
  dispatch('updateBilling', { billing })
}

export const setPayByCreditCard = async (token) => {
  const billing = await api.setPayByCreditCard(token)
  dispatch('updateBilling', { billing })
}

export const cancelSubscription = async () => {
  await api.cancelSubscription()
  return loadBillingSummary()
}

export const payByCheck = async (subscription) => {
  const invoiceWindow = window.open('about:blank', '_blank')

  // Set pay by check and load invoice
  await updateSubscription(subscription)
  await setPayByCheck()
  await loadBillingDetails()

  // Show the invoice
  const { invoicesById } = store.state
  const invoices = Object.values(invoicesById)
  const currentInvoice = getCurrentInvoice(invoices)
  if (currentInvoice) {
    invoiceWindow.location = currentInvoice.printUrl
  } else {
    invoiceWindow.close()
  }
}

export const payByCreditCard = async (subscription) => {
  const { price } = subscription
  await updateSubscription(subscription)
  return enterCreditCard({
    amount: price * 100,
    description: 'Purchase Subscription',
    panelLabel: 'Pay',
  })
}

// Show stripe dialog
// See here for options: https://stripe.com/docs/checkout#required
const getCreditCard = (options) =>
  new Promise((resolve, reject) => {
    const { session } = store.state
    let token

    StripeCheckout.open({
      email: session.email,
      key: config.stripe.publishableKey,
      allowRememberMe: false,
      currency: 'usd',
      name: 'Blissbook',
      token: (t) => {
        token = t
      },
      closed: () => {
        token ? resolve(token.id) : reject()
      },
      ...options,
    })
  })

export const enterCreditCard = async (options) => {
  const token = await getCreditCard(options)
  await setPayByCreditCard(token)
  return loadBillingDetails()
}

export const setInvoicePaid = async (invoiceId, checkId) => {
  await api.setInvoicePaid(invoiceId, checkId)
  return loadBillingDetails()
}

export const addCredits = async (action, delta) => {
  await api.addCredits({ action, delta })
  return loadBillingDetails()
}

// AUDIENCE

export const updateAudienceDashboardSettings = async (changes) => {
  const result = await api.setAudienceDashboardSettings(changes)
  dispatch('patchAudienceDashboardSettings', { changes: result })
}

// CUSTOM DOMAIN

export const setCustomDomain = async (input) => {
  const customDomain = await api.setCustomDomain(input)
  dispatch('setCustomDomain', { customDomain })
}

export const removeCustomDomain = async () => {
  const { organization } = store.state
  await api.removeCustomDomain()
  window.location.href = organization.blissbookOrigin + window.location.pathname
}

export const addCustomDomainCertificate = async (certificate) => {
  const customDomain = await api.addCustomDomainCertificate(certificate)
  dispatch('setCustomDomain', { customDomain })
  return customDomain
}

export const setCustomDomainCertificate = async (digest) => {
  const customDomain = await api.setCustomDomainCertificate(digest)
  dispatch('setCustomDomain', { customDomain })
  return customDomain
}

export const deleteCustomDomainCertificate = async (digest) => {
  const customDomain = await api.deleteCustomDomainCertificate(digest)
  dispatch('setCustomDomain', { customDomain })
  return customDomain
}

// EMAILS

export const updateEmailBranding = async (changes) => {
  const result = await api.updateEmailBranding(changes)
  dispatch('patchEmailSettings', { changes: result })
}

export const updateEmailSettings = async (changes) => {
  const result = await api.updateEmailSettings(changes)
  dispatch('patchEmailSettings', { changes: result })
}

export const addEmailDomain = async (input) => {
  const emailDomain = await api.addEmailDomain(input)
  dispatch('setEmailDomain', { emailDomain })
}

export const updateEmailDomain = async (id, input) => {
  const emailDomain = await api.updateEmailDomain(id, input)
  dispatch('setEmailDomain', { emailDomain })
}

export const deleteEmailDomain = async (id) => {
  await api.deleteEmailDomain(id)
  dispatch('deleteEmailDomain', { id })
}

export const sendTestEmail = async (variables) => {
  await api.sendTestEmail(variables)
}

export const updateEmailTemplate = async (id, input) => {
  const emailTemplate = await api.updateEmailTemplate(id, input)
  dispatch('setEmailTemplate', { emailTemplate })
}

export const createHandbookReminder = async (input) => {
  const reminder = await api.createHandbookReminder(input)
  dispatch('setHandbookReminder', { reminder })
}

export const updateHandbookReminder = async (id, input) => {
  const reminder = await api.updateHandbookReminder(id, input)
  dispatch('setHandbookReminder', { reminder })
}

export const setHandbookReminderEnabled = async (id, isEnabled) => {
  const reminder = await api.setHandbookReminderEnabled(id, isEnabled)
  dispatch('setHandbookReminder', { reminder })
}

export const deleteHandbookReminder = async (id) => {
  await api.deleteHandbookReminder(id)
  dispatch('deleteHandbookReminder', { id })
}

export const updateManagerDigestSettings = async (input) => {
  const managerDigestSettings = await api.setManagerDigestSettings({
    ...pick(
      store.state.managerDigestSettings,
      'isEnabled',
      'isDirectReportsOnly',
      'daysAfter',
      'date',
    ),
    ...input,
  })
  dispatch('setManagerDigestSettings', { managerDigestSettings })
}

// GROUPS

export const findOrCreateGroup = async (name) => {
  const { state } = store
  const groups = filter(state.groupsById, (group) => !group.archived)
  let group = groups.find((group) => group.name === name)
  if (!group) {
    group = await api.createGroup(name)
    dispatch('setGroup', { group })
  }
  return group
}

export const updateGroup = async (groupId, changes) => {
  dispatch('updateGroup', { groupId, changes })
  return api.updateGroup(groupId, changes)
}

export const archiveGroup = async (groupId) => {
  await api.archiveGroup(groupId)
  dispatch('archiveGroup', { groupId })
  await resetPeopleCounts()
}

// HANDBOOKS

export const loadHandbooks = () =>
  dispatchQuery({ cache: true, query: HandbooksDocument })

export const loadHandbook = (handbookId) =>
  dispatchQuery({
    cache: true,
    query: HandbookDocument,
    variables: { handbookId },
  })

export const createHandbook = async (params) => {
  const json = await api.createHandbook(params)
  const handbook = Handbook.fromJSON(json)
  dispatch('setHandbook', { handbook })
  return handbook
}

export const cloneHandbook = async (sourceId, name) => {
  const json = await api.cloneHandbook(sourceId, name)
  const handbook = Handbook.fromJSON(json)
  dispatch('setHandbook', { handbook })
  return handbook
}

export const initializeHandbookAudience = async (
  id,
  hasAudience,
  hasSignatures,
) => {
  const json = await api.initializeHandbookAudience(
    id,
    hasAudience,
    hasSignatures,
  )
  const handbook = Handbook.fromJSON(json)
  dispatch('setHandbook', { handbook })
  return handbook
}

export const initializeHandbookContent = async (id, seedType, branding) => {
  const json = await api.initializeHandbookContent(id, seedType, branding)
  const handbook = Handbook.fromJSON(json)
  dispatch('setHandbook', { handbook })
  return handbook
}

export const patchHandbook = async (handbookId, input) => {
  dispatch('patchHandbook', { handbookId, changes: input })
  const changes = await api.patchHandbook(handbookId, input)
  dispatch('patchHandbook', { handbookId, changes })
}

export const setHandbookCustomDomain = async (handbookId, customDomain) => {
  const changes = await api.setHandbookCustomDomain(handbookId, customDomain)
  dispatch('patchHandbook', { handbookId, changes })
}

export const patchHandbookAudience = async (
  handbookId,
  audience,
  variables,
) => {
  const changes = await api.patchHandbookAudience({
    ...variables,
    audience,
    handbookId,
  })
  dispatch('patchHandbook', { handbookId, changes })
  await loadHandbook(handbookId)
}

export const resetHandbookPublicToken = async (handbookId) => {
  const changes = await api.resetHandbookPublicToken(handbookId)
  dispatch('patchHandbook', { handbookId, changes })
}

export const createHandbookPreviewLink = async (handbookId, personId) => {
  const previewLink = await api.createHandbookPreviewLink(handbookId, personId)
  dispatch('addHandbookPreviewLink', { handbookId, previewLink })
}

export const resetHandbookPreviewLinkTokens = async (handbookId) => {
  const previewLinks = await api.resetHandbookPreviewLinkTokens(handbookId)
  dispatch('setHandbookPreviewLinks', { handbookId, previewLinks })
}

export const deleteHandbookPreviewLink = async (handbookId, personId) => {
  await api.deleteHandbookPreviewLink(handbookId, personId)
  dispatch('removeHandbookPreviewLink', { handbookId, personId })
}

export const setHandbookTaskComplete = async (handbookId, taskId, complete) => {
  const task = await api.setHandbookTaskComplete(handbookId, taskId, complete)
  dispatch('setHandbookTask', { handbookId, task })
}

export const setHandbookPositions = async (handbookIds) => {
  dispatch('setHandbookPositions', { handbookIds })
  await api.setHandbookPositions(handbookIds)
}

export const deleteHandbook = async (handbookId) => {
  await api.deleteHandbook(handbookId)
  dispatch('deleteHandbook', { handbookId })
}

export const setPublishHandbookNotification = async (handbookId, input) => {
  const publishNotification = await api.setPublishHandbookNotification(
    handbookId,
    input,
  )
  const changes = { publishNotification }
  dispatch('patchHandbook', { handbookId, changes })
}

export const publishHandbook = async (handbookId, input) => {
  const json = await api.publishHandbook({ ...input, handbookId })
  await loadOrganization()

  const handbook = Handbook.fromJSON(json)
  dispatch('setHandbook', { handbook })
  return handbook
}

// Branding

export const addHandbookFont = (handbookId, font) => {
  dispatch('addHandbookFont', { handbookId, font })
  return font
}

// Signature Rounds

export const loadHandbookSignatureRounds = async (handbookId) =>
  dispatchQuery({
    query: HandbookSignatureRoundsDocument,
    variables: { handbookId },
  })

export const createHandbookSignatureRound = async (
  handbookId,
  input,
  notification,
) => {
  await api.createHandbookSignatureRound(handbookId, input, notification)
  const signatureRounds = await loadHandbookSignatureRounds(handbookId)
  return last(signatureRounds)
}

export const updateHandbookSignatureRound = async (id, input) => {
  const round = await api.updateHandbookSignatureRound(id, input)
  return loadHandbookSignatureRounds(round.handbookId)
}

export const archiveHandbookSignatureRound = async (id) => {
  const round = await api.archiveHandbookSignatureRound(id)
  return loadHandbookSignatureRounds(round.handbookId)
}

export const unarchiveHandbookSignatureRound = async (id) => {
  const round = await api.unarchiveHandbookSignatureRound(id)
  return loadHandbookSignatureRounds(round.handbookId)
}

export const deleteHandbookSignatureRound = async (handbookId, id) => {
  await api.deleteHandbookSignatureRound(id)
  return loadHandbookSignatureRounds(handbookId)
}

// INTEGRATIONS

export const loadIntegrations = () =>
  dispatchQuery({ cache: true, query: api.INTEGRATIONS_QUERY })

export const addBambooHRIntegration = async (input) => {
  const integration = await api.addBambooHRIntegration(input)
  setIntegration(integration)
  return integration
}

export const addSftpIntegration = async () => {
  const integration = await api.addSftpIntegration()
  setIntegration(integration)
  return integration
}

export const updateBambooHRIntegration = async (id, changes) => {
  const integration = await api.updateBambooHRIntegration(id, changes)
  setIntegration(integration)
  return integration
}

export const updateRipplingIntegration = async (id, changes) => {
  const integration = await api.updateRipplingIntegration(id, changes)
  setIntegration(integration)
  return integration
}

export const setIntegration = (integration) => {
  dispatch('setIntegration', { integration })
}

export const deleteIntegration = async (integrationId) => {
  await api.deleteIntegration(integrationId)
  dispatch('deleteIntegration', { integrationId })
}

// INVOICES

export const reloadInvoice = async (invoiceId) => {
  const invoice = await api.getInvoice(invoiceId)
  dispatch('setInvoice', { invoice })
}

// ORGANIZATION

export const loadOrganization = () =>
  dispatchQuery({ cache: true, query: OrganizationDocument })

export const resetPeopleCounts = () =>
  dispatchQuery({ cache: true, query: api.GET_PEOPLE_COUNTS })

export const setSubdomain = async (subdomain) => {
  const json = await api.setSubdomain(subdomain)
  const organization = Organization.fromJSON(json)
  dispatch('setOrganization', { organization })
  return organization
}

export const updateOrganization = async (changes) => {
  const json = await api.updateOrganization(changes)
  const organization = Organization.fromJSON(json)
  dispatch('setOrganization', { organization })
  return organization
}

export const updateOrganizationAdmin = async (changes) => {
  const json = await api.updateOrganizationAdmin(changes)
  const organization = Organization.fromJSON(json)
  dispatch('setOrganization', { organization })
  return organization
}

export const setOrganizationTaskComplete = async (taskId, complete) => {
  const task = await api.setOrganizationTaskComplete(taskId, complete)
  dispatch('setOrganizationTask', { task })
}

// PEOPLE

export const addPeople = async (newPeople, sendNotifications, notification) => {
  const people = await api.upsertPeople({
    newPeople,
    notification,
    sendNotifications,
  })
  dispatch('addPeople', { people })
  await resetPeopleCounts()
  return people
}

export const syncPeople = async () => {
  await api.syncPeople()
  await loadOrganization()
  dispatch('resetPeople')
}

export const updatePerson = async (
  personId,
  changes,
  sendNotifications,
  notification,
) => {
  const newPeople = await api.upsertPeople({
    notification,
    people: [{ ...changes, id: personId }],
    sendNotifications,
  })
  dispatch('addPeople', { people: newPeople })
  await resetPeopleCounts()
  return newPeople[0]
}

export const archivePerson = async (personId) => {
  const person = await api.archivePerson(personId)
  dispatch('addPeople', { people: [person] })
  await resetPeopleCounts()
  return person
}

export const unarchivePerson = async (personId) => {
  const person = await api.unarchivePerson(personId)
  dispatch('addPeople', { people: [person] })
  await resetPeopleCounts()
}

export const deletePerson = async (personId) => {
  await api.deletePerson(personId)
  await resetPeopleCounts()
}

// Get people by this query, storing results in the store
export const getPeople = async (variables) => {
  const people = await api.getPeople(variables)
  dispatch('addPeople', { people })
  return people
}

let bulkPersonIds = []
let bulkPeoplePromise
export const getPeopleById = async (ids) => {
  // Add to bulk load
  bulkPersonIds = uniq([...bulkPersonIds, ...ids])

  // Kick the process
  bulkPeoplePromise =
    bulkPeoplePromise ||
    new Promise((resolve) => {
      setTimeout(async () => {
        const people = await getPeople({
          ids: bulkPersonIds,
          showArchived: true,
        })
        bulkPersonIds = []
        bulkPeoplePromise = undefined
        resolve(people)
      })
    })

  // Return the people
  const people = await bulkPeoplePromise
  return keyBy(people, 'id')
}

// Preferences ----------------------------------------------------------------

export const setPreference = async (key, value) => {
  // Cannot set preferences when becoming a user
  const { session } = store.state
  if (!session.isImpersonating) {
    await api.setPreferences({ [key]: value })
  }

  dispatch('setPreferences', { [key]: value })
}

export const hideProTip = async (tipId) => {
  const hideProTips = await api.hideProTip(tipId)
  dispatch('setPreferences', { hideProTips })
}

// Security Settings ----------------------------------------------------------

export const addSingleSignOn = async (providerId, input) => {
  const singleSignOn = await api.addSingleSignOn(providerId, input)
  dispatch('setSingleSignOn', { singleSignOn })
  return singleSignOn
}

export const patchSingleSignOn = async (providerId, input) => {
  const singleSignOn = await api.patchSingleSignOn(providerId, input)
  dispatch('setSingleSignOn', { singleSignOn })
  return singleSignOn
}

export const removeSingleSignOn = async (providerId) => {
  const securitySettings = await api.removeSingleSignOn(providerId)
  dispatch('setSecuritySettings', { securitySettings })
  return securitySettings
}

export const addSingleSignOnCertificate = async (providerId, certificate) => {
  const securitySettings = await api.addSingleSignOnCertificate(
    providerId,
    certificate,
  )
  dispatch('setSecuritySettings', { securitySettings })
  return securitySettings
}

export const archiveSingleSignOnCertificate = async (providerId, digest) => {
  const securitySettings = await api.archiveSingleSignOnCertificate(
    providerId,
    digest,
  )
  dispatch('setSecuritySettings', { securitySettings })
  return securitySettings
}

export const deleteSingleSignOnCertificate = async (providerId, digest) => {
  const securitySettings = await api.deleteSingleSignOnCertificate(
    providerId,
    digest,
  )
  dispatch('setSecuritySettings', { securitySettings })
  return securitySettings
}

export const unarchiveSingleSignOnCertificate = async (providerId, digest) => {
  const securitySettings = await api.unarchiveSingleSignOnCertificate(
    providerId,
    digest,
  )
  dispatch('setSecuritySettings', { securitySettings })
  return securitySettings
}

export const setEmailAuthSettings = async (input) => {
  const securitySettings = await api.setEmailAuthSettings(input)
  dispatch('setSecuritySettings', { securitySettings })
  return securitySettings
}

export const setKioskModeSettings = async (input) => {
  const securitySettings = await api.setKioskModeSettings(input)
  dispatch('setSecuritySettings', { securitySettings })
  return securitySettings
}

export const setPhoneAuthSettings = async (input) => {
  const securitySettings = await api.setPhoneAuthSettings(input)
  dispatch('setSecuritySettings', { securitySettings })
  return securitySettings
}

export const sendTestPhoneMessage = async (phone) => {
  await api.sendTestPhoneMessage(phone)
}
