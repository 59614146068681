import { emailSchema, textSchema } from '@blissbook/lib/document'
import { blissbookEmailBranding, formatAuthUrl } from '@blissbook/lib/email'
import {
  EmailLayout,
  HorizontalRule,
  Paragraph,
  renderFrom,
} from '@blissbook/lib/email/renderer'
import React from 'react'
import { renderMessageContent, renderTextContent, toEditor } from './template'
import type { HandbookEmailInput } from './types'

export const type = 'invitation-edit'
export const to = toEditor
export const subjectContent = textSchema.htmlToJSON(
  'You’re invited to collaborate on the <variable name="organization.name"></variable> <variable name="handbook.name"></variable>',
)
export const ccManagers = false
export const buttonContent = textSchema.htmlToJSON('Accept Your Invitation')
export const greetingContent = emailSchema.htmlToJSON(
  `<p>Hi <variable name="to.fullName"></variable>,</p>`,
)
export const messageContent = emailSchema.htmlToJSON(
  `<p>I’d love your help editing our new <variable name="handbook.name"></variable>!</p>`,
)

export function renderEmail(props: HandbookEmailInput) {
  const {
    buttonContent,
    from,
    handbook,
    isAuthEnabled,
    messageContent,
    organization,
    subjectContent,
  } = props
  return {
    subject: renderTextContent(subjectContent, props),
    body: (
      <EmailLayout
        branding={{
          ...blissbookEmailBranding,
          footerText: (
            <>
              Blissbook: Online employee handbooks & digital policy management.
              <br />
              You received this email because someone invited you to collaborate
              on a handbook in Blissbook.
            </>
          ),
        }}
        callToAction={{
          href: formatAuthUrl(`${handbook.url}/edit`, isAuthEnabled),
          text: renderTextContent(buttonContent, props),
        }}
      >
        <Paragraph>
          You’re invited to collaborate on the {organization.name}{' '}
          {handbook.name}:
        </Paragraph>
        <HorizontalRule />
        {renderMessageContent(greetingContent, props)}
        <Paragraph />
        {renderMessageContent(messageContent, props)}
        <Paragraph />
        <Paragraph>Thanks,</Paragraph>
        <Paragraph>{renderFrom(from)}</Paragraph>
      </EmailLayout>
    ),
  }
}
