import { Command } from 'ckeditor5'
import { annotationAttributeKey } from './AddAnnotationCommand'

export class ReplaceAnnotationCommand extends Command {
  execute(options: { annotationId: string; newAnnotationId?: string }) {
    const { editor } = this
    const { annotationId, newAnnotationId } = options
    const { model } = editor

    model.change((writer) => {
      // Get the root element (usually the whole document).
      const root = editor.model.document.getRoot()

      // Create a range covering the entire document.
      const range = writer.createRangeIn(root)

      // Loop through all items in the range.
      for (const item of range.getItems()) {
        if (item.getAttribute(annotationAttributeKey) !== annotationId) continue

        if (newAnnotationId) {
          writer.setAttribute(annotationAttributeKey, newAnnotationId, item)
        } else {
          writer.removeAttribute(annotationAttributeKey, item)
        }
      }
    })
  }
}
