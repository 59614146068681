import {
  type ClipboardContentInsertionData,
  type ClipboardPipeline,
  type DowncastConversionApi,
  type Node,
  Plugin,
  UpcastWriter,
  type ViewAttributeElement,
  type ViewElement,
  Widget,
} from 'ckeditor5'
import {
  AddAnnotationCommand,
  annotationAttributeKey,
} from './AddAnnotationCommand'
import { ReplaceAnnotationCommand } from './ReplaceAnnotationCommand'

const dataAttributeKey = 'data-annotation-id'

export class AnnotationEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()

    this.editor.commands.add(
      'addAnnotation',
      new AddAnnotationCommand(this.editor),
    )
    this.editor.commands.add(
      'replaceAnnotation',
      new ReplaceAnnotationCommand(this.editor),
    )
  }

  _defineSchema() {
    const { editor } = this
    const { schema } = editor.model

    schema.extend('$text', { allowAttributes: 'annotationId' })

    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        attributes: dataAttributeKey,
      },
      model: {
        key: annotationAttributeKey,
        value: (viewElement: ViewElement) =>
          viewElement.getAttribute(dataAttributeKey),
      },
    })

    editor.conversion.for('downcast').attributeToElement({
      model: annotationAttributeKey,
      view: (
        annotationId: string,
        { writer }: DowncastConversionApi,
      ): ViewAttributeElement =>
        writer.createAttributeElement('span', {
          [dataAttributeKey]: annotationId,
        }),
    })

    // Remove annotation attributes from clipboard content
    const clipboardPipeline: ClipboardPipeline =
      editor.plugins.get('ClipboardPipeline')
    this.listenTo(
      clipboardPipeline,
      'contentInsertion',
      (_event, data: ClipboardContentInsertionData) => {
        const viewFragment = data.content
        const writer = new UpcastWriter(editor.editing.view.document)

        // Function to remove unwanted attributes from elements recursively
        function removeAttributes(element: Node) {
          if (element.is('element')) {
            // Recursively check and process child elements
            for (const child of element.getChildren()) {
              removeAttributes(child)
            }
          } else if (element.is('$text')) {
            writer.removeAttribute(
              annotationAttributeKey,
              element as unknown as ViewElement,
            )
          }
        }

        // Apply the function to each element in the document fragment
        for (const child of viewFragment.getChildren()) {
          removeAttributes(child)
        }
      },
    )
  }
}
