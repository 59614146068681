import { LinkToolbarForm } from '@blissbook/ui/editor/marks/link/LinkToolbarForm'
import { findAttributeRange } from 'ckeditor5'
import React, { useState } from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { CKEditorSelection } from '../CKEditorSelection'
import { CKEditorBubbleToolbar } from './CKEditorBubbleToolbar'

export function EditLinkToolbar({
  editor,
  initialValue,
}: {
  editor: BlissbookEditor
  initialValue: string
}) {
  const { model } = editor
  const [selectionNode, setSelectionNode] = useState<HTMLDivElement>()

  function handleChange(href: string | undefined) {
    editor.execute('link', href)
    editor.setInlineToolbarType(undefined)
    editor.focus()
  }

  function handleRemove() {
    const { selection } = editor.model.document

    model.change((writer) => {
      const rangesToUnlink = selection.isCollapsed
        ? [
            findAttributeRange(
              selection.getFirstPosition(),
              'linkHref',
              selection.getAttribute('linkHref'),
              model,
            ),
          ]
        : model.schema.getValidRanges(selection.getRanges(), 'linkHref')

      // Remove `linkHref` attribute from specified ranges.
      for (const range of rangesToUnlink) {
        writer.removeAttribute('linkHref', range)
      }
    })

    handleChange(undefined)
  }

  return (
    <>
      <CKEditorBubbleToolbar editor={editor} offset={4} refEl={selectionNode}>
        <LinkToolbarForm
          bookmarks={editor.bookmarks}
          bookmarkLabel='document name'
          initialValue={initialValue}
          onRemove={handleRemove}
          onSubmit={handleChange}
        />
      </CKEditorBubbleToolbar>

      <CKEditorSelection editor={editor} ref={setSelectionNode} />
    </>
  )
}
