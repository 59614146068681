import { useMouseDownOutside } from '@blissbook/ui/hooks/useMouseDownOutside'
import { useScrollIntoView } from '@blissbook/ui/hooks/useScrollIntoView'
import { handleError } from '@blissbook/ui/util/errors'
import React, { useState } from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { AnnotationForm } from './AnnotationForm'
import { annotationClassName } from './AnnotationView'
import { useAnnotationEditor } from './useAnnotationEditor'

export function AddAnnotationView(props: {
  editor: BlissbookEditor
}) {
  const { editor } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const [node, setNode] = useScrollIntoView({ block: 'end' })
  const { html, pmEditor } = useAnnotationEditor()

  // Close if clicking outside
  useMouseDownOutside(() => {
    editor.setShowNewAnnotation(false)
  }, [node])

  async function handleSubmit() {
    setSubmitting(true)

    try {
      const annotation = await editor.createAnnotation(html)
      editor.execute('addAnnotation', { annotationId: annotation.id })
      editor.setShowNewAnnotation(false)
      editor.focus()
    } catch (error) {
      handleError(error)
    }

    setSubmitting(false)
  }

  return (
    <div className={annotationClassName} ref={setNode}>
      <div className='tw-font-semibold tw-text-gray-500 tw-text-sm'>
        Add Annotation
      </div>

      <AnnotationForm
        disabled={isSubmitting}
        onSubmit={handleSubmit}
        pmEditor={pmEditor}
      />
    </div>
  )
}
