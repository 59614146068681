import { hideProTip } from '@blissbook/application/actions'
import {
  useCustomDomain,
  useEmailDomains,
  useEmailSettings,
  useHandbookReminders,
  useHandbooks,
  useIntegrations,
  usePreference,
  useSecuritySettings,
} from '@blissbook/application/hooks'
import {
  ProTip as BaseProTip,
  ProTipTooltip as BaseProTipTooltip,
  Link,
} from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import React, { Fragment } from 'react'

export const emailBrandingProTip = {
  id: 'email-branding',
  icon: ['far', 'paintbrush-pencil'],
  show: () => {
    const emailSettings = useEmailSettings()
    return !emailSettings.hasCustomBranding
  },
  children: (
    <Fragment>
      <Link to='/email/branding'>Customize your email branding</Link> and design
      to increase employee trust.
    </Fragment>
  ),
}

export const customDomainProTip = {
  id: 'custom-domain',
  show: () => {
    const customDomain = useCustomDomain()
    return !customDomain
  },
  icon: ['far', 'shield-check'],
  children: (
    <Fragment>
      Add a <Link to='/organization/settings'>custom domain</Link> to ensure
      your content is trusted, branded, and easy to find.
    </Fragment>
  ),
}

export const emailDomainProTip = {
  id: 'email-domain',
  show: () => {
    const emailDomains = useEmailDomains()
    return !emailDomains.length
  },
  icon: ['far', 'mailbox'],
  children: (
    <Fragment>
      Increase employee trust and improve deliverability with a{' '}
      <Link to='/email/settings'>custom email domain</Link>.
    </Fragment>
  ),
}

const emailInvitationsProTip = {
  id: 'email-invitations',
  show: () => {
    const emailSettings = useEmailSettings()
    return !emailSettings.autoSendInvitations
  },
  icon: ['far', 'envelope-open-text'],
  children: (
    <Fragment>
      Automatically invite new employees to your handbook with{' '}
      <Link to='/email/invitations-and-reminders'>Automated Invitations</Link>.
    </Fragment>
  ),
}

export const handbookRemindersProTip = {
  id: 'handbook-reminders',
  show: () => {
    const handbooks = useHandbooks()
    const handbookReminders = useHandbookReminders()
    return (
      !handbookReminders.length &&
      !handbooks.some(
        (handbook) => handbook.publishedAt && !handbook.signatureRounds.length,
      )
    )
  },
  icon: ['far', 'calendar-exclamation'],
  children: (
    <Fragment>
      Use <Link to='/email/invitations-and-reminders'>Automated Reminders</Link>{' '}
      to collect more signatures with less effort.
    </Fragment>
  ),
}

export const ssoProTip = {
  id: 'sso',
  show: () => {
    const { singleSignOns } = useSecuritySettings()
    return !singleSignOns.length
  },
  icon: ['far', 'key'],
  children: (
    <Fragment>
      Make your handbooks easier to access and more secure with{' '}
      <Link to='/organization/settings'>Single Sign-On</Link>.
    </Fragment>
  ),
}

export const syncProTip = {
  id: 'sync',
  show: () => {
    const integrations = useIntegrations()
    return !integrations.length
  },
  icon: ['far', 'sync-alt'],
  children: (
    <Fragment>
      Save time and{' '}
      <Link to='/employees/sync'>sync your employee list automatically</Link>.
    </Fragment>
  ),
}

// Map a tip to show or not show it
const mapTip = (tip) => {
  if (!tip) return
  const { show, ...props } = tip
  if (!show || show()) return props
}

export const proTips = [
  ssoProTip,
  syncProTip,
  handbookRemindersProTip,
  customDomainProTip,
  emailDomainProTip,
  emailInvitationsProTip,
  emailBrandingProTip,
]

export const useProTips = () => proTips.map(mapTip).filter(Boolean)

const dismissProTip = async (tipId) => {
  try {
    await hideProTip(tipId)
  } catch (error) {
    handleError(error, 'Error closing pro tip')
  }
}

export const ProTip = ({ canDismiss, id, ...props }) => {
  const [hideProTips] = usePreference('hideProTips')
  const isHidden = hideProTips.includes(id) && canDismiss
  if (isHidden) return null

  return (
    <If condition={!isHidden}>
      <BaseProTip
        {...props}
        data-tip-id={id}
        onDismiss={!canDismiss ? undefined : () => dismissProTip(id)}
      />
    </If>
  )
}

export const ProTipTooltip = ({ children, theme, tip, ...props }) => {
  const [hideProTips] = usePreference('hideProTips')
  tip = mapTip(tip)
  return (
    <Choose>
      <When condition={!tip || hideProTips.includes(tip.id)}>{children}</When>
      <Otherwise>
        <BaseProTipTooltip
          {...props}
          tip={{
            ...tip,
            onDismiss: () => dismissProTip(tip.id),
            theme,
          }}
        >
          {children}
        </BaseProTipTooltip>
      </Otherwise>
    </Choose>
  )
}
