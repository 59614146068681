import { useForceRender } from '@blissbook/ui/hooks/useForceRender'
import { UserProfilesView } from '@blissbook/ui/lib/profile'
import type Sessions from '@ckeditor/ckeditor5-real-time-collaboration/src/realtimecollaborativeediting/sessions'
import { cx } from '@emotion/css'
import type { DecoupledEditor } from 'ckeditor5'
import type { User } from 'ckeditor5-premium-features'
import { useEffect } from 'react'

export function CKEditorPresenceView({
  channelId,
  className,
  editor,
}: {
  channelId: string
  className?: string
  editor: DecoupledEditor | undefined
}) {
  const forceRender = useForceRender()
  const plugin = editor.plugins.get('Sessions') as Sessions
  const sessions = plugin.channelSessions.get(channelId)

  useEffect(() => {
    plugin.allConnectedUsers.on('add', forceRender)
    plugin.allConnectedUsers.on('remove', forceRender)

    sessions?.on('add', forceRender)
    sessions?.on('remove', forceRender)

    return () => {
      plugin.allConnectedUsers.off('add', forceRender)
      plugin.allConnectedUsers.off('remove', forceRender)

      sessions?.off('add', forceRender)
      sessions?.off('remove', forceRender)
    }
  }, [forceRender, plugin, sessions])

  // Access the presence list plugin
  if (!sessions) return null

  const users = [...sessions]
    .map((s) => s.user as unknown as User)
    .filter((u) => !u.isMe)

  return (
    <div className={cx('tw-flex tw-items-center ck', className)}>
      <UserProfilesView
        size={32}
        users={users.map((user) => ({
          avatarURL: user.avatar,
          className: user.color.getBackgroundColorClass(),
          fullName: user.name,
          initials: user.initials,
          personId: Number.parseInt(user.id, 10),
        }))}
      />
    </div>
  )
}
