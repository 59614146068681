import React from 'react'
import { EmptyPage } from './EmptyPage'

export const ErrorPage = ({
  children,
  ...props
}: {
  children: React.ReactNode
  className?: string
}) => (
  <EmptyPage {...props}>
    <h1>{children}</h1>
  </EmptyPage>
)

export const NotFoundPage = () => (
  <ErrorPage>Sorry, we couldn't find the page you're looking for.</ErrorPage>
)
