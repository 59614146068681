import { type Annotation, parseAnnotationIds } from '@blissbook/lib/blissbook'
import { useMemo } from 'react'

function getActiveAnnotation(
  annotations: Annotation[],
  html: string | undefined,
) {
  if (!html) return []

  const activeAnnotationIds = parseAnnotationIds(html)
  return activeAnnotationIds
    .map((id) => annotations.find((annotation) => annotation.id === id))
    .filter(Boolean)
}

export function useActiveAnnotations(
  annotations: Annotation[],
  html: string | undefined,
) {
  return useMemo(
    () => getActiveAnnotation(annotations, html),
    [annotations, html],
  )
}
