import { Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import React from 'react'
import {
  BillingEmailLayout,
  type BillingEmailProps,
  getBillingUrl,
} from '../template'

export const type = 'billing/credit-card/renewal'

export function renderEmail(props: BillingEmailProps) {
  const { organization, to } = props
  const billingUrl = getBillingUrl(organization)
  return {
    subject: 'Your Blissbook subscription renews in ~7 days',
    body: (
      <BillingEmailLayout {...props}>
        <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
        <Paragraph />
        <Paragraph>
          This is a reminder that the subscription for your Blissbook
          organization {organization.name} will automatically renew in about 7
          days.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          To continue using Blissbook as-is, do nothing. We’ll process the
          credit card on file and send a payment receipt on your renewal date.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          To change your subscription or to see a cost estimate, visit your
          organization’s subscription page and click the Change Plan button:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={billingUrl}>{billingUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          If you have any questions or need help, please reply to this email or
          contact us at support@blissbook.com.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          Thank you - we hope Blissbook continues to serve you well!
        </Paragraph>
        <Paragraph />
        <Paragraph>The Blissbook Team</Paragraph>
      </BillingEmailLayout>
    ),
  }
}
