import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import { EmptyPage } from './EmptyPage'

export type LoadingProps = {
  children?: React.ReactNode
  className?: string
  fullscreen?: boolean
  style?: React.CSSProperties
}

export const Loading = ({
  children,
  className,
  fullscreen,
  ...props
}: LoadingProps) => (
  <div
    {...props}
    className={classnames(
      'loading',
      fullscreen && 'loading-fullscreen',
      className,
    )}
  >
    {children}
    <FontAwesomeIcon className='tw-ml-4' icon='spinner' spin />
  </div>
)

export const LoadingPage = (props: LoadingProps) => (
  <EmptyPage>
    <Loading {...props} />
  </EmptyPage>
)
