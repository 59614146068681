import { useEffect, useState } from 'react'
import type { BlissbookEditor } from '../BlissbookEditor'

function getEditorData(editor: BlissbookEditor | undefined) {
  return editor?.getData()
}

export function useEditorData(editor: BlissbookEditor | undefined) {
  const [data, setData] = useState(() => getEditorData(editor))

  useEffect(() => {
    if (!editor) return

    function handleChange() {
      const state = getEditorData(editor)
      setData(state)
    }

    handleChange()

    const { document } = editor.model
    document.on('change:data', handleChange)
    return () => {
      document.off('change:data', handleChange)
    }
  }, [editor])

  return data
}
