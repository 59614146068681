import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons/faClockRotateLeft'
import { ButtonView, Plugin } from 'ckeditor5'
import { getIconPath } from './util'

export class RevisionHistoryUI extends Plugin {
  init() {
    const { editor } = this
    const { t } = editor

    // Add the "myCustomToggleTrackChanges" button to the editor UI
    editor.ui.componentFactory.add('revisionHistory', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        icon: getIconPath(faClockRotateLeft),
        label: t('Open revision history'),
        tooltip: true,
      })

      // Callback executed once the button is clicked
      view.on('execute', () => {
        // @ts-ignore: https://ckeditor.com/docs/ckeditor5/latest/api/module_revision-history_revisionhistoryconfig-RevisionHistoryConfig.html#member-showRevisionViewerCallback
        editor.config.get('revisionHistory').showRevisionViewerCallback()
      })

      return view
    })
  }
}
