import type { WhodunnitPerson } from '@blissbook/application/graph'
import { getFirstName } from '@blissbook/lib/name'
import moment from 'moment'
import React from 'react'

type DateFormat = 'relative' | 'absolute' | string

type Variant = 'name-first' | 'time-first'

export function formatDate(date: Date, format: DateFormat = 'relative') {
  if (format === 'absolute') {
    return `${moment(date).format('M/D/YY')} at ${moment(date).format('h:mma')}`
  }

  if (format === 'relative') {
    return moment(date).fromNow()
  }

  return moment(date).format(format)
}

export function formatWhodunnitDisplayName(
  whodunnit: WhodunnitPerson | undefined,
) {
  if (!whodunnit) return 'Someone'
  const { fullName, isBlissbook } = whodunnit
  return isBlissbook ? `${getFirstName(fullName)} from Blissbook` : fullName
}

export function WhodunnitView({
  compact,
  dateFormat,
  timestamp,
  whodunnit,
  variant = 'time-first',
  ...props
}: {
  className?: string
  compact?: boolean
  dateFormat?: DateFormat
  timestamp: Date
  whodunnit: WhodunnitPerson | undefined
  variant?: Variant
}) {
  const displayName = formatWhodunnitDisplayName(whodunnit)
  let title = formatDate(timestamp, 'absolute')
  if (displayName) title += ` by ${displayName}`

  if (variant === 'name-first') {
    return (
      <span {...props} title={title}>
        {displayName}{' '}
        <span className='tw-text-gray-500 tw-text-sm'>
          {formatDate(timestamp, dateFormat)}
        </span>
      </span>
    )
  }

  return (
    <span {...props} title={title}>
      {formatDate(timestamp, dateFormat)}
      <If condition={displayName && !compact}> by {displayName}</If>
    </span>
  )
}
