import { Plugin } from 'ckeditor5'
import { AdjustHeadingsCommand } from './AdjustHeadingsCommand'

export class HeadingsEditing extends Plugin {
  init() {
    this.editor.commands.add(
      'adjustHeadings',
      new AdjustHeadingsCommand(this.editor),
    )
  }
}
