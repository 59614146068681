import { useOrganization, useSession } from '@blissbook/application/hooks'
import config from '@blissbook/ui-config'
import { Dropdown, Link, SignOutLink, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'

export const SessionMenu = ({ className, ...props }) => {
  const organization = useOrganization()
  const session = useSession()
  return (
    <Dropdown.Provider>
      <Dropdown.ToggleButton
        {...props}
        caret={false}
        className={cx('tw-flex tw-items-center btn-link', className)}
        css={{
          color: 'inherit',
          '&:hover': {
            color: 'inherit',
            textDecoration: 'none',
          },
        }}
      >
        <div className='tw-flex tw-flex-col tw-items-end'>
          <div css={{ fontWeight: 600, marginBottom: -2 }}>
            {session.firstName}
            <If condition={session.isImpersonating}>
              <FontAwesomeIcon
                className='tw-ml-2'
                icon={['far', 'flask-potion']}
                style={{ fontSize: 14 }}
              />
            </If>
          </div>
          <div
            className='font-italic tw-text-gray-500'
            style={{ fontSize: 12, marginTop: 2 }}
          >
            {organization ? organization.name : 'Blissbook'}
          </div>
        </div>
        <FontAwesomeIcon
          className='tw-text-gray-700 tw-mt-2'
          css={{ fontSize: 12, marginLeft: '.75rem' }}
          icon={['far', 'chevron-down']}
        />
      </Dropdown.ToggleButton>
      <Dropdown.Menu placement='bottom-end'>
        <a
          className='dropdown-item'
          href={config.origin + '/terms-of-use'}
          rel='noopener noreferrer'
          target='_blank'
        >
          Terms of Use
        </a>
        <a
          className='dropdown-item'
          href={config.origin + '/privacy-policy'}
          rel='noopener noreferrer'
          target='_blank'
        >
          Privacy Policy
        </a>
        <If condition={session.person?.adminRoleId}>
          <a
            className='dropdown-item'
            href='https://blissbook.com/newsletter'
            rel='noopener noreferrer'
            target='_blank'
          >
            Blissbook Newsletter
          </a>
        </If>
        <div className='dropdown-divider' />
        <SignOutLink className='dropdown-item'>Sign Out</SignOutLink>
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}

export const SidebarIconsNav = ({ className, ...props }) => (
  <nav {...props} className={classnames('sidebar-icons', className)} />
)

SidebarIconsNav.Link = ({
  active,
  activeTop,
  className,
  icon,
  title,
  ...props
}) => (
  <Tooltip content={title} placement='right'>
    <Link
      {...props}
      className={classnames(
        'nav-link',
        active && 'active',
        activeTop && 'active-top',
        className,
      )}
    >
      <Choose>
        <When condition={React.isValidElement(icon)}>{icon}</When>
        <Otherwise>
          <FontAwesomeIcon icon={icon} />
        </Otherwise>
      </Choose>
    </Link>
  </Tooltip>
)
