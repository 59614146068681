import { cx } from '@emotion/css'
import React, { forwardRef } from 'react'

export const Typography = forwardRef(
  ({ className, size, tagName, variant, ...props }, ref) => {
    const TagName = tagName || variant
    return (
      <TagName
        {...props}
        className={cx(
          tagName && variant,
          (size || tagName) && [variant, size].filter(Boolean).join('-'),
          className,
        )}
        ref={ref}
      />
    )
  },
)
