import {
  annotationSchema,
  getContentFromProsemirrorDocNode,
  isContentEmpty,
} from '@blissbook/lib/document'
import { useEditor } from '@blissbook/ui/editor'
import React, { useEffect, useMemo, useState } from 'react'

export function useAnnotationEditor(initialHtml = '') {
  const [content, setContent] = useState(() =>
    annotationSchema.htmlToJSON(initialHtml),
  )
  const isEmpty = useMemo(() => isContentEmpty(content), [content])
  const html = useMemo(
    () =>
      annotationSchema.jsonToHTML(content).replace(/<p><\/p>/g, '<p><br></p>'),
    [content],
  )

  const pmEditor = useEditor({
    content: { type: 'doc', content },
    schema: annotationSchema,
  })

  useEffect(() => {
    if (!pmEditor) return

    function onEditorUpdate() {
      const { doc } = pmEditor.state
      const content = getContentFromProsemirrorDocNode(doc)
      setContent(content)
    }

    pmEditor.on('update', onEditorUpdate)
    return () => {
      pmEditor.off('update', onEditorUpdate)
    }
  }, [pmEditor, setContent])

  return { isEmpty, pmEditor, html }
}
