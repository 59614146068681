import { immerable } from 'immer'
import { fromJSON, mapJSONValues } from '../util/json'

export class HandbookNotification {
  id: number
  email: string
  toName: string
  fromEmail: string
  fromName: string
  subject: string
  body: string
  createdAt: Date

  static get ATTRIBUTES() {
    return {
      createdAt: fromJSON.date,
    }
  }

  static mapJSON(json: any) {
    return mapJSONValues(json, HandbookNotification.ATTRIBUTES)
  }

  static fromJSON(json: any) {
    return Object.assign(
      new HandbookNotification(),
      HandbookNotification.mapJSON(json),
    )
  }

  get from() {
    return formatEmailAndName(this.fromEmail, this.fromName)
  }

  get to() {
    return formatEmailAndName(this.email, this.toName)
  }
}

// @ts-ignore: immerable
HandbookNotification[immerable] = true

// Determine the full email format
export function formatEmailAndName(email: string, name: string) {
  if (!email) return
  if (name) return `${name} <${email}>`
  return email
}
