import React, {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

export type CKEditorContext = {
  showAnnotations: boolean
  setShowAnnotations: (show: boolean) => void
  setTrackingChanges: (tracking: boolean) => void
  trackingChanges: boolean
}

const OperandContext = createContext<CKEditorContext>(undefined)

export const CKEditorContextProvider = ({
  children,
  onShowAnnotations,
}: {
  children: ReactNode
  onShowAnnotations?: (show: boolean) => void
}) => {
  const [showAnnotations, setShowAnnotations] = useState(false)
  const [trackingChanges, setTrackingChanges] = useState(false)

  const value = useMemo(
    () => ({
      showAnnotations,
      setShowAnnotations,
      setTrackingChanges,
      trackingChanges,
    }),
    [showAnnotations, setShowAnnotations, setTrackingChanges, trackingChanges],
  )

  // Fire the callback on changes, if provided
  useEffect(() => {
    onShowAnnotations?.(showAnnotations)
  }, [showAnnotations])

  return (
    <OperandContext.Provider value={value}>{children}</OperandContext.Provider>
  )
}

export function useCKEditorContext() {
  return useContext(OperandContext)
}
