import {
  archiveAsset,
  archiveHandbookAsset,
  getAssets,
  getHandbookAssets,
} from '@blissbook/application/api'
import { storeAssetUrl } from '@blissbook/application/assets'
import { stockImages } from '@blissbook/lib/blissbook/images'
import {
  imageMimeTypes,
  isValidHttpUrl,
  isValidHttpsUrl,
  pdfMimeTypes,
} from '@blissbook/lib/sanitize'
import config from '@blissbook/ui-config'
import { colors } from '@blissbook/ui/branding'
import {
  Button,
  Input,
  Modal,
  ScrollContainer,
  Tabs,
  Tooltip,
  getScrollNode,
} from '@blissbook/ui/lib'
import { PdfViewer } from '@blissbook/ui/lib/pdf'
import { handleError } from '@blissbook/ui/util/errors'
import { getImageDimensions, usePromise } from '@blissbook/ui/util/hooks'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { addToast } from '@blissbook/ui/util/toaster'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import find from 'lodash/find'
import keyBy from 'lodash/keyBy'
import last from 'lodash/last'
import pluralize from 'pluralize'
import React, { forwardRef, useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import tinycolor from 'tinycolor2'

const borderWidth = 4

// Determine an image's dimensions
export const getAssetDimensions = async (asset) => {
  // No asset?
  if (!asset) return

  // Not image?
  if (asset.type !== 'image') return

  // Pre-calculated?
  const { width, height } = asset
  if (width && height) return { width, height }

  // Calculate in the browser
  return getImageDimensions(asset.url)
}

const useAssetDimensions = (asset) => {
  const [dimensions] = usePromise(() => getAssetDimensions(asset), [asset])
  return dimensions
}

const useAssets = (getAssets, ctx) => {
  const state = useState()
  useEffect(() => {
    getAssets(ctx).then(state[1])
  }, [])
  return state
}

const getUploadedAssets = async (ctx) => {
  const { handbookId, ...variables } = ctx
  if (handbookId) return getHandbookAssets(handbookId, variables)
  return getAssets(variables)
}

const useUploadedAssets = (ctx) => useAssets(getUploadedAssets, ctx)

const uploadAsset = async (file, ctx, config) => {
  const { handbookId, tag, type } = ctx
  const uploadUrl = '/' + pluralize(type)

  const formData = new FormData()
  formData.append('file', file)
  if (tag) formData.append('tag', tag)
  const res = await axios.post(
    handbookId ? `/handbooks/${handbookId}` + uploadUrl : uploadUrl,
    formData,
    config,
  )

  storeAssetUrl(res.data)

  return res.data
}

const isValidAssetUrl = config.env.local ? isValidHttpUrl : isValidHttpsUrl
const isValidAsset = (asset) => (!asset ? false : isValidAssetUrl(asset.url))

const archiveUploadedAsset = (assetId, ctx) => {
  const { handbookId } = ctx
  if (handbookId) return archiveHandbookAsset(handbookId, assetId)
  return archiveAsset(assetId)
}

const UploadFileButton = ({
  accept,
  children,
  className,
  disabled,
  onUpload,
  size,
  ...props
}) => (
  <div
    {...props}
    className={cx(
      'btn btn-file',
      size && `btn-${size}`,
      { disabled },
      className,
    )}
    disabled={disabled}
  >
    <span>{children}</span>
    <input
      accept={accept}
      disabled={disabled}
      name='file'
      onChange={onUpload}
      onClick={(event) => {
        event.target.value = null
      }}
      type='file'
    />
  </div>
)

const AssetThumbnail = forwardRef(
  (
    {
      active,
      asset,
      className,
      disabled,
      height,
      onSelect,
      onSubmit,
      ...props
    },
    ref,
  ) => (
    <div
      {...props}
      className={cx(
        'bg-checkerboard tw-flex tw-items-center tw-justify-center',
        'tw-border-4',
        active ? 'tw-border-blurple-500' : 'tw-border-white',
        !active && !disabled && 'hover:tw-border-blurple-300',
        disabled
          ? 'tw-cursor-not-allowed tw-opacity-40'
          : 'tw-cursor-pointer tw-opacity-100',
        className,
      )}
      style={{ height }}
      onClick={() => {
        if (!disabled) onSelect(asset)
      }}
      onDoubleClick={() => {
        if (!disabled) onSubmit(asset)
      }}
      ref={ref}
    >
      <Choose>
        <When condition={asset.type === 'pdf'}>
          <PdfViewer
            className='tw-h-full tw-w-full'
            disabled
            thumbnailUrl={asset.thumbnailUrl || undefined}
            url={asset.url}
          />
        </When>
        <Otherwise>
          <img
            alt={asset.filename}
            className='tw-max-h-full tw-max-w-full'
            loading='lazy'
            src={asset.url}
          />
        </Otherwise>
      </Choose>
    </div>
  ),
)

const AssetUploadProgress = ({ height, percentage, ...props }) => (
  <div
    {...props}
    css={{
      alignItems: 'center',
      border: `${borderWidth}px solid ${colors['blurple-200']}`,
      display: 'flex',
      flexDirection: 'column',
      height: height + borderWidth * 2,
      justifyContent: 'center',
      padding: borderWidth,
      width: '100%',
    }}
  >
    <div
      className='progress tw-mt-2 tw-mb-1'
      style={{ height: 8, width: '90%' }}
    >
      <div
        className='progress-bar progress-bar-striped progress-bar-animated'
        style={{
          backgroundColor: colors['blurple-500'],
          width: `${percentage}%`,
        }}
      />
    </div>
    {percentage}%
  </div>
)

const scrollToAsset = (node) => {
  const scrollNode = getScrollNode(node)
  const scrollTop = Math.max(0, node.offsetTop - node.offsetHeight / 2)
  $(scrollNode).animate({ scrollTop })
}

const AssetGallery = ({
  activeId,
  assets,
  maxFilesize,
  onSelect,
  onSubmit,
  thumbHeight,
  uploadPercentage,
  ...props
}) => {
  const [node, setNode] = useState()
  const [uploadingNode, setUploadingNode] = useState()

  // Scroll to current asset
  useEffect(() => {
    if (!node) return

    // Find the asset
    const asset = assets.find((asset) => asset.id === activeId)
    if (!asset) return

    // Scroll to the asset
    const index = assets.indexOf(asset)
    const assetNode = node.children[index]
    if (assetNode) scrollToAsset(assetNode, thumbHeight)
  }, [node, assets])

  // Scroll to uploading
  useEffect(() => {
    if (!uploadingNode) return
    scrollToAsset(uploadingNode, thumbHeight)
  }, [uploadingNode])

  return (
    <div {...props} className='tw-gap-0.5 tw-grid tw-grid-cols-6' ref={setNode}>
      {assets.map((asset, index) => {
        let thumbnail = (
          <AssetThumbnail
            active={asset.id === activeId}
            asset={asset}
            height={thumbHeight}
            disabled={asset.filesize > maxFilesize}
            onSelect={onSelect}
            onSubmit={onSubmit}
          />
        )

        if (asset.filesize > maxFilesize) {
          thumbnail = (
            <Tooltip
              content={`${asset.filename} (${renderFilesize(
                asset.filesize,
              )}) is greater than the maximum allowed filesize: ${renderFilesize(
                maxFilesize,
              )}`}
              maxWidth={240}
            >
              {thumbnail}
            </Tooltip>
          )
        }

        return (
          <div
            className={cx(asset.archived ? 'tw-hidden' : undefined)}
            key={index}
          >
            {thumbnail}
          </div>
        )
      })}

      <If condition={uploadPercentage}>
        <div ref={setUploadingNode}>
          <AssetUploadProgress
            height={thumbHeight}
            percentage={uploadPercentage}
          />
        </div>
      </If>
    </div>
  )
}

const UploadedAssetPicker = ({
  maxFilesize,
  onChange,
  onSubmit,
  thumbHeight,
  uploadedAssets,
  uploadPercentage,
  value,
}) => {
  return (
    <AssetGallery
      activeId={value?.id}
      assets={uploadedAssets}
      maxFilesize={maxFilesize}
      onSelect={onChange}
      onSubmit={onSubmit}
      thumbHeight={thumbHeight}
      uploadPercentage={uploadPercentage}
    />
  )
}

const StockAssetPicker = ({
  maxFilesize,
  onChange,
  onSubmit,
  stockAssets,
  thumbHeight,
  value,
}) => (
  <AssetGallery
    activeId={value?.id}
    assets={stockAssets}
    maxFilesize={maxFilesize}
    onSelect={onChange}
    onSubmit={onSubmit}
    thumbHeight={thumbHeight}
  />
)

const ExternalAssetPicker = ({ exampleUrl, onChange, type, value }) => (
  <div>
    <label>URL</label>
    <div>
      <Input
        className={cx('form-control', {
          'has-error': value && !isValidHttpsUrl(value.url),
        })}
        emptyValue={null}
        name='url'
        onChangeValue={(url) => onChange({ type, url })}
        placeholder={`e.g. ${exampleUrl}`}
        value={value ? value.url : ''}
      />
    </div>
  </div>
)

const renderFilesize = (bytes) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / 1024 ** i).toFixed(1) + ['B', 'KB', 'MB', 'GB', 'TB'][i]
}

// Metadata for the different asset types
const assetTypes = [
  {
    type: 'image',
    accept: imageMimeTypes,
    exampleUrl:
      'https://upload.wikimedia.org/wikipedia/commons/8/8a/2006_Ojiya_balloon_festival_011.jpg',
    icon: ['far', 'image'],
    label: 'Image',
    maxFilesize: 10 * 1024 * 1024, // 10MB
    stockAssets: stockImages,
    thumbHeight: 110,
    title: 'Select an Image',
  },
  {
    type: 'pdf',
    accept: pdfMimeTypes,
    icon: ['far', 'file-pdf'],
    label: 'PDF',
    maxFilesize: 25 * 1024 * 1024, // 25MB
    thumbHeight: 169,
    title: 'Select a PDF',
  },
]
const assetTypesByType = keyBy(assetTypes, 'type')

// Determine what sources are available
const sources = [
  {
    id: 'uploaded',
    getLabel: (label) => `Your ${pluralize(label)}`,
    AssetPicker: UploadedAssetPicker,
    canUpload: true,
    show: ({ uploadedAssets }) => uploadedAssets,
  },
  {
    id: 'stock',
    getLabel: (label) => `Stock ${pluralize(label)}`,
    AssetPicker: StockAssetPicker,
    show: ({ stockAssets }) => stockAssets && stockAssets.length > 0,
  },
  {
    id: 'external',
    getLabel: () => 'Externally Linked',
    AssetPicker: ExternalAssetPicker,
    show: ({ exampleUrl, handbookId }) => exampleUrl && handbookId,
  },
].filter(Boolean)

const getSources = (props) => sources.filter((source) => source.show(props))

export const AssetPickerModal = Modal.wrap(
  ({
    accept,
    assetName,
    handbookId,
    initialValue,
    maxFilesize,
    onChange,
    onChangeId,
    subtitle,
    tag,
    type,
    ...props
  }) => {
    const { onClose } = props

    // Get metadata
    const metadata = assetTypesByType[type]
    const { exampleUrl, icon, label, thumbHeight, title } = metadata
    accept = accept || metadata.accept
    maxFilesize = maxFilesize || metadata.maxFilesize

    // Determine what assets to allow the user to choose
    const ctx = { handbookId, tag, type }

    // Load the assets
    const stockAssets = handbookId ? metadata.stockAssets : undefined
    const [uploadedAssets, setUploadedAssets] = useUploadedAssets(ctx)

    // Determine what sources are available
    const [sourceId, setSourceId] = useState()
    const sources = getSources({
      exampleUrl,
      handbookId,
      stockAssets,
      uploadedAssets,
    })
    const source = sources.find((source) => source.id === sourceId)
    const { canUpload = false } = source || {}

    // Determine if we are loaded
    const isLoaded = uploadedAssets !== undefined

    // Get / Set current assets
    const [assetsById, setAssetsById] = useState({})
    const asset = assetsById[sourceId] || null
    const setAsset = (asset) =>
      setAssetsById({
        ...assetsById,
        [sourceId]: asset,
      })

    // Get the asset dimensions
    const dimensions = useAssetDimensions(asset)

    // Initialize
    useEffect(() => {
      if (!isLoaded) return

      // Determine the initial values
      const assetsById = {}
      if (initialValue) {
        const initialValueLower = initialValue.toLowerCase()
        const stockAsset = find(stockAssets, (a) => a.url === initialValue)
        const uploadedAsset = find(
          uploadedAssets,
          (a) => a.path === initialValueLower,
        )

        if (uploadedAsset) {
          if (!uploadedAsset.archived) assetsById.uploaded = uploadedAsset
        } else if (stockAsset) {
          if (!stockAsset.archived) assetsById.stock = stockAsset
        } else {
          assetsById.external = { type, url: initialValue }
        }
      }

      // Set the initial state
      const sourceId = Object.keys(assetsById)[0] || sources[0].id
      setAssetsById(assetsById)
      setSourceId(sourceId)
    }, [isLoaded])

    // Upload asset
    const [uploadPercentage, setUploadPercentage] = useState()
    const isUploading = uploadPercentage !== undefined
    const onUploadFile = async (file) => {
      if (!file) {
        addToast('No file provided', { type: 'error' })
        return
      }
      // Check the MIME Type
      if (!accept.includes(file.type)) {
        addToast(`${file.type} is not a supported format`, { type: 'error' })
        return
      }

      // Check the filesize
      if (file.size > maxFilesize) {
        addToast(
          `${
            assetName ? `${assetName} ${label}` : label
          } cannot be greater than ${renderFilesize(maxFilesize)}`,
          { type: 'error' },
        )
        return
      }

      setUploadPercentage(0)

      try {
        // Upload the asset
        const uploadedAsset = await uploadAsset(file, ctx, {
          onUploadProgress: (event) => {
            const { loaded, total } = event
            const percentage = Math.round((loaded / total) * 100)
            setUploadPercentage(percentage)
          },
        })

        // Set the current asset
        setAsset(uploadedAsset)
        setUploadedAssets([...uploadedAssets, uploadedAsset])
      } catch (error) {
        handleError(error, `${label} upload failed`)
      }

      setUploadPercentage()
    }

    // Drop asset
    const [{ isOver }, dropRef] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        canDrop: () => canUpload,
        drop(item) {
          const [file] = item.files
          onUploadFile(file).catch(logUIError)
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
        }),
      }),
      [canUpload, uploadedAssets],
    )

    const onRemoveAsset = async () => {
      // Reset the current url
      setAsset(null)

      // Archive the asset in the db
      await archiveUploadedAsset(asset.id, ctx)

      // Archive the asset in the gallery
      const index = uploadedAssets.indexOf(asset)
      const newUploadedAssets = [...uploadedAssets]
      newUploadedAssets[index] = { ...asset, archived: true }
      setUploadedAssets(newUploadedAssets)
    }

    const onSubmit = async () => {
      try {
        const value = asset.path || asset.url
        if (onChange) await onChange(value)
        if (onChangeId) await onChangeId(asset.id)
        onClose()
      } catch (error) {
        handleError(error)
      }
    }

    const renderSource = (source) => (
      <source.AssetPicker
        exampleUrl={exampleUrl}
        maxFilesize={maxFilesize}
        onChange={setAsset}
        onSubmit={onSubmit}
        stockAssets={stockAssets}
        thumbHeight={thumbHeight}
        type={type}
        uploadedAssets={uploadedAssets}
        uploadPercentage={uploadPercentage}
        value={assetsById[sourceId]}
      />
    )

    return (
      <Modal.Component
        {...props}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        width={860}
      >
        <Modal.Content ref={dropRef}>
          <Modal.Header closeButtonText='cancel' hideCloseButton={isUploading}>
            <Modal.Title>
              {title}

              <If condition={canUpload}>
                <UploadFileButton
                  accept={accept}
                  className='btn-outline-primary tw-ml-4'
                  css={{ marginTop: -5 }}
                  disabled={isUploading}
                  onUpload={(event) => onUploadFile(event.target.files[0])}
                  size='sm'
                >
                  {isUploading ? 'Uploading...' : 'Upload'}
                </UploadFileButton>
              </If>
            </Modal.Title>

            <If condition={subtitle}>
              <Modal.Subtitle>{subtitle}</Modal.Subtitle>
            </If>
          </Modal.Header>

          <Modal.Body>
            <Choose>
              <When condition={!isLoaded} />
              <When condition={sources.length === 1}>
                {renderSource(sources[0])}
              </When>
              <Otherwise>
                <Tabs activeId={sourceId} onSelectId={setSourceId}>
                  {sources.map((source) => (
                    <Tabs.Item
                      forceRender
                      id={source.id}
                      innerClassName='tw-flex tw-flex-col'
                      key={source.id}
                      label={source.getLabel(label)}
                    >
                      <ScrollContainer css={{ flex: 1, minHeight: 0 }}>
                        {renderSource(source)}
                      </ScrollContainer>
                    </Tabs.Item>
                  ))}
                </Tabs>
              </Otherwise>
            </Choose>
          </Modal.Body>

          <Modal.Footer
            className='tw-flex tw-items-center tw-justify-between tw-py-4'
            css={{
              background: colors['gray-100'],
              borderTop: `1px solid ${colors['gray-400']}`,
            }}
          >
            <div
              className='tw-flex tw-items-center'
              css={{ flexShrink: 1, minWidth: 0 }}
            >
              <Button
                className='tw-mr-4 tw-whitespace-nowrap'
                color='primary'
                disabled={!isValidAsset(asset) || isUploading}
                onClick={onSubmit}
              >
                Select {label}
              </Button>

              <If condition={asset}>
                <If condition={asset.filename}>
                  <AssetFilename
                    css={{ flexShrink: 1, minWidth: 0 }}
                    filename={asset.filename}
                  />
                </If>

                <If condition={asset.id}>
                  <div className='tw-text-gray-500 tw-ml-2'>#{asset.id}</div>
                </If>

                <If condition={asset.filesize}>
                  <div className='tw-text-gray-500 tw-ml-2'>
                    {renderFilesize(asset.filesize)}
                  </div>
                </If>

                <If condition={dimensions}>
                  <div className='tw-text-gray-500 tw-ml-2'>
                    {dimensions.width}x{dimensions.height}
                  </div>
                </If>
              </If>
            </div>

            <If condition={asset && canUpload}>
              <Button
                className='tw-ml-6 tw-whitespace-nowrap'
                color='danger'
                onClick={onRemoveAsset}
                outline
                size='sm'
              >
                Remove from Gallery
              </Button>
            </If>
          </Modal.Footer>

          <If condition={canUpload}>
            <div
              className='tw-flex tw-flex-col tw-items-center tw-justify-center'
              css={{
                background: tinycolor(colors['gray-600'])
                  .setAlpha(0.75)
                  .toRgbString(),
                bottom: 0,
                color: 'white',
                fontSize: 32,
                fontWeight: 700,
                left: 0,
                opacity: isOver ? 1 : 0,
                pointerEvents: 'none',
                position: 'absolute',
                top: 0,
                transition: 'opacity 200ms linear',
                right: 0,
                zIndex: 10,
              }}
            >
              <FontAwesomeIcon
                className='tw-mb-4'
                css={{ fontSize: 120 }}
                icon={icon}
              />
              Release to Upload
            </div>
          </If>
        </Modal.Content>
      </Modal.Component>
    )
  },
)

const AssetFilename = ({ filename, maxLength = 24, ...props }) => {
  const parts = filename.split('.')
  const extension = parts.length > 1 ? last(parts) : ''
  return (
    <Tooltip
      content={filename}
      disabled={filename.length < maxLength}
      maxWidth={240}
    >
      <div {...props}>
        <Choose>
          <When condition={filename.length < maxLength}>{filename}</When>
          <Otherwise>
            {filename.slice(0, maxLength - extension.length)}
            {`...${extension}`}
          </Otherwise>
        </Choose>
      </div>
    </Tooltip>
  )
}
