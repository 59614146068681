import { gql } from '@apollo/client'
import BillingEvent from '@blissbook/common/billing/event'
import Invoice from '@blissbook/common/billing/invoice'
import { BillingFragmentDoc } from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'
import axios from 'axios'

// Credits --------------------------------------------------------------------

export const getCreditHistory = () =>
  axios.get('/credits').then((res) => {
    const { data } = res
    data.history = data.history.map(BillingEvent.fromJSON)
    return data
  })

export const addCredits = (params) =>
  axios.post('/credits', params).then((res) => BillingEvent.fromJSON(res.data))

// Invoices -------------------------------------------------------------------

export const getInvoice = (invoiceId) =>
  axios.get(`/invoices/${invoiceId}`).then((res) => Invoice.fromJSON(res.data))
export const setInvoicePaid = (invoiceId, checkId) =>
  axios
    .post(`/invoices/${invoiceId}/paid`, { checkId })
    .then((res) => Invoice.fromJSON(res.data))

// updateBilling --------------------------------------------------------------

export const UPDATE_BILLING = gql`
  mutation updateBilling($input: BillingInput!) {
    billing: updateBilling(input: $input) {
      ...billing
    }
  }

  ${BillingFragmentDoc}
`

export const updateBilling = async (input) => {
  const { data } = await client.mutate({
    mutation: UPDATE_BILLING,
    variables: { input },
  })
  return data.billing
}

// updateBillingAdmin ---------------------------------------------------------

export const UPDATE_BILLING_ADMIN = gql`
  mutation updateBillingAdmin($input: BillingAdminInput!) {
    billing: updateBillingAdmin(input: $input) {
      ...billing
    }
  }

  ${BillingFragmentDoc}
`

export const updateBillingAdmin = async (input) => {
  const { data } = await client.mutate({
    mutation: UPDATE_BILLING_ADMIN,
    variables: { input },
  })
  return data.billing
}

// updateSubscription ---------------------------------------------------------

export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($input: SubscriptionInput!) {
    billing: updateSubscription(input: $input) {
      ...billing
    }
  }

  ${BillingFragmentDoc}
`

export const updateSubscription = async (input) => {
  const { data } = await client.mutate({
    mutation: UPDATE_SUBSCRIPTION,
    variables: { input },
  })
  return data.billing
}

// setPayByCheck --------------------------------------------------------------

export const SET_PAY_BY_CHECK = gql`
  mutation setPayByCheck {
    billing: setPayByCheck {
      ...billing
      creditCard
    }
  }

  ${BillingFragmentDoc}
`

export const setPayByCheck = async () => {
  const { data } = await client.mutate({
    mutation: SET_PAY_BY_CHECK,
  })
  return data.billing
}

// setPayByCreditCard ---------------------------------------------------------

export const SET_PAY_BY_CREDIT_CARD = gql`
  mutation setPayByCreditCard($token: String!) {
    billing: setPayByCreditCard(token: $token) {
      ...billing
      creditCard
    }
  }

  ${BillingFragmentDoc}
`

export const setPayByCreditCard = async (token) => {
  const { data } = await client.mutate({
    mutation: SET_PAY_BY_CREDIT_CARD,
    variables: { token },
  })
  return data.billing
}

// cancelSubscription ---------------------------------------------------------

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription {
    billing: cancelSubscription {
      ...billing
      creditCard
    }
  }

  ${BillingFragmentDoc}
`

export const cancelSubscription = async () => {
  const { data } = await client.mutate({
    mutation: CANCEL_SUBSCRIPTION,
  })
  return data.billing
}
