import { store } from '@blissbook/application/store'
import {
  type BuildHandbookNotificationArgs,
  handbookEmailTemplates,
} from '@blissbook/lib/email/templates'

export function buildHandbookNotification(
  type: string,
  props: Omit<BuildHandbookNotificationArgs, 'from' | 'organization'>,
) {
  const { organization, session } = store.state
  const template = handbookEmailTemplates.find((t) => t.type === type)
  return template.buildNotification({
    ...props,
    from: session.person,
    organization,
  })
}
