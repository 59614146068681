import { resolveAssetUrl } from '@blissbook/application/assets'
import { ImagePickerModal } from '@blissbook/application/editor'
import type { Image } from '@blissbook/application/graph'
import addImageIcon from '@blissbook/assets/images/add-image-graybg.png'
import { imageMimeTypes } from '@blissbook/lib/sanitize'
import { colors } from '@blissbook/ui/branding'
import { BackgroundImage, Button, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import isString from 'lodash/isString'
import React from 'react'
import { useState } from 'react'

type ImageValue = Image | string | null | undefined

type ResolvedImage = {
  url: string
  value: ImageValue
}

function resolveImage(value: ImageValue): ResolvedImage {
  if (!value) return

  // URL
  if (isString(value)) {
    return {
      url: resolveAssetUrl(value),
      value,
    }
  }
  // Asset

  return {
    url: value.url,
    value: value.path,
  }
}

export type ImagePickerVariant = 'logo'

type ImagePickerImageProps = {
  className?: string
  disabled?: boolean
  hideCheckerboard?: boolean
  image: ResolvedImage | undefined
  onClick?: () => void
  variant?: ImagePickerVariant
}

function ImagePickerImage({
  className,
  disabled,
  hideCheckerboard,
  image,
  variant,
  ...props
}: ImagePickerImageProps) {
  return (
    <Choose>
      <When condition={disabled && !image}>
        <div
          {...props}
          className='tw-flex tw-items-center tw-justify-center tw-h-full tw-w-full tw-text-gray-500'
        >
          No Image
        </div>
      </When>
      <When condition={!image}>
        <Tooltip content='Upload an image' disabled={disabled}>
          <Choose>
            <When condition={variant === 'logo'}>
              <div
                {...props}
                className={cx(
                  'tw-flex tw-items-center tw-justify-center tw-border tw-bg-white tw-border-blurple-500 tw-text-blurple-500 tw-text-xs',
                  className,
                )}
              >
                <FontAwesomeIcon icon='building' />
              </div>
            </When>
            <Otherwise>
              <BackgroundImage
                {...props}
                className={cx('tw-bg-gray-200', className)}
                css={{
                  backgroundPosition: '57% 57%',
                  backgroundSize: '35%',
                  border: '3px solid white',
                  borderRadius: 2,
                  boxShadow: `${colors['gray-400']} 0 0 0 1px`,
                  margin: 1,
                }}
                src={addImageIcon}
              />
            </Otherwise>
          </Choose>
        </Tooltip>
      </When>
      <Otherwise>
        <Tooltip content='Select a different image' disabled={disabled}>
          <div
            {...props}
            className={classnames(
              'tw-flex tw-items-center tw-justify-center',
              !hideCheckerboard && 'bg-checkerboard',
              className,
            )}
          >
            <img
              alt={image.url}
              src={image.url}
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
              }}
            />
          </div>
        </Tooltip>
      </Otherwise>
    </Choose>
  )
}

export function ImagePicker({
  accept = imageMimeTypes,
  className,
  disabled,
  handbookId,
  imageClassName,
  maxFilesize,
  modalProps,
  onChange,
  onChangeId,
  hideCheckerboard,
  tag,
  value,
  variant,
  ...props
}: {
  accept?: string[]
  className?: string
  disabled?: boolean
  handbookId?: number
  imageClassName?: string
  maxFilesize?: number
  modalProps?: any
  onChange?: (image: string) => void
  onChangeId?: (imageId: number) => void
  hideCheckerboard?: boolean
  style?: React.CSSProperties
  tag?: string
  value: ImageValue
  variant?: ImagePickerVariant
}) {
  const [isOpen, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const image = resolveImage(value)
  return (
    <>
      <div
        {...props}
        className={cx(
          'tw-group tw-flex tw-items-center tw-relative tw-transition-opacity',
          className,
        )}
        css={{
          height: 80,
          width: 100,
        }}
      >
        <ImagePickerImage
          className={cx(
            'tw-h-full tw-w-full',
            !disabled && 'hover:tw-cursor-pointer hover:tw-opacity-80',
            imageClassName,
          )}
          disabled={disabled}
          hideCheckerboard={hideCheckerboard}
          image={image}
          onClick={disabled ? undefined : onOpen}
          variant={variant}
        />

        <If condition={image && !disabled}>
          <Button
            className='btn-icon btn-hover-danger tw-absolute tw-bg-white tw-ml-2 tw-transition-opacity tw-opacity-0 group-hover:tw-opacity-100'
            css={{
              borderRadius: 8,
              fontSize: 16,
              right: -8,
              top: -8,
            }}
            onClick={() => {
              if (onChange) onChange(null)
              if (onChangeId) onChangeId(null)
            }}
          >
            <FontAwesomeIcon icon={['far', 'times-circle']} />
          </Button>
        </If>
      </div>

      <ImagePickerModal.Animated
        {...modalProps}
        accept={accept}
        handbookId={handbookId}
        initialValue={image?.value}
        isOpen={isOpen}
        maxFilesize={maxFilesize}
        onChange={onChange}
        onChangeId={onChangeId}
        onClose={() => setOpen(false)}
        tag={tag}
      />
    </>
  )
}
