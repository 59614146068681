import { emailSchema, textSchema } from '@blissbook/lib/document'
import { renderHandbookEmail, toViewer } from './template'
import type { HandbookEmailInput } from './types'

export const type = 'reminder-view'
export const editButtonText =
  'Customize the reminder sent to someone who only needs to view'
export const title = 'Manual Reminder to View'
export const to = toViewer
export const subjectContent = textSchema.htmlToJSON(
  'Reminder: You were invited to view the <variable name="handbook.name"></variable>',
)
export const ccManagers = false
export const buttonContent = textSchema.htmlToJSON(
  'View the <variable name="handbook.name"></variable>',
)

export const messageContent = emailSchema.htmlToJSON(`
  <p>You were invited to view the <variable name="organization.name"></variable> <variable name="handbook.name"></variable>. To do so, click the button above.</p>
  <hr />
  <p>Hi <variable name="to.firstName"></variable>,</p>
  <p></p>
  <p>Please take some time to view the <variable name="handbook.name"></variable>.</p>
  <p></p>
  <p>You can always access the <variable name="handbook.name"></variable> by signing in at <variable name="handbook.viewingLink"></variable>.</p>
  <p></p>
  <p>If you have any questions, let us know!</p>
  <p></p>
  <p>Have a great day,</p>
  <p>Your HR Team</p>
`)

export function renderEmail(props: HandbookEmailInput) {
  const { handbook } = props
  return renderHandbookEmail({
    ...props,
    authUrl: handbook.publishedUrl,
  })
}
