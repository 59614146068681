import { Command, type Editor, type Element } from 'ckeditor5'

export const MIN_HEADING_LEVEL = 1
export const MAX_HEADING_LEVEL = 4

function getHeadingLevel(element: Element) {
  if (!element.name.startsWith('heading')) return
  return Number.parseInt(element.name.replace('heading', ''), 10)
}

type AdjustHeadingsValue = {
  maxHeadingLevel: number
  minHeadingLevel: number
}

const UNKNOWN_VALUE: AdjustHeadingsValue = {
  maxHeadingLevel: Number.NEGATIVE_INFINITY,
  minHeadingLevel: Number.POSITIVE_INFINITY,
}

export class AdjustHeadingsCommand extends Command {
  value: AdjustHeadingsValue

  constructor(editor: Editor) {
    super(editor)
    this.value = UNKNOWN_VALUE
  }

  execute({ delta }: { delta: number }) {
    const { model } = this.editor
    model.change((writer) => {
      const root = model.document.getRoot()

      // Iterate through all elements in the document
      const changes: { element: Element; level: number }[] = []
      for (const child of root.getChildren()) {
        // Extract the heading level from this element, if any
        if (!child.is('element')) continue
        const headingLevel = getHeadingLevel(child)
        if (headingLevel === undefined) continue

        // If the new level is invalid, stop everything
        const level = headingLevel + delta
        if (level < MIN_HEADING_LEVEL || level > MAX_HEADING_LEVEL) return

        // Add to the list
        changes.push({ element: child, level })
      }

      // Now, perform all changes
      for (const change of changes) {
        const { element, level } = change
        writer.rename(element, `heading${level}`)
      }
    })
  }

  refresh() {
    const { model } = this.editor
    const root = model.document.getRoot()

    // Iterate through all elements in the document, finding the min and max heading levels
    const value = { ...UNKNOWN_VALUE }
    for (const child of root.getChildren()) {
      // Extract the heading level from this element, if any
      if (!child.is('element')) continue
      const headingLevel = getHeadingLevel(child)
      if (headingLevel === undefined) continue

      // Update value
      value.maxHeadingLevel = Math.max(value.maxHeadingLevel, headingLevel)
      value.minHeadingLevel = Math.min(value.minHeadingLevel, headingLevel)
    }

    this.isEnabled = true
    this.value = value
  }
}
