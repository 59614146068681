import { AssetPickerModal } from '@blissbook/application/lib/assets'
import React from 'react'
import type { AssetPickers } from '../hooks'
import { ImagePickerModal } from './ImagePickerModal'
import { MediaPickerModal } from './MediaPickerModal'

type AssetPickersViewProps = Partial<AssetPickers>

export function AssetPickersView({
  imagePicker,
  mediaPicker,
  pdfPicker,
}: AssetPickersViewProps) {
  return (
    <>
      <AssetPickerModal.Animated
        {...pdfPicker.modalState}
        isOpen={!!pdfPicker.modalState}
        type='pdf'
      />

      <ImagePickerModal.Animated
        {...imagePicker.modalState}
        isOpen={!!imagePicker.modalState}
      />

      <MediaPickerModal.Animated
        {...mediaPicker.modalState}
        isOpen={!!mediaPicker.modalState}
        videoOnly={mediaPicker.videoOnly}
      />
    </>
  )
}
