import { cx } from '@emotion/css'
import React from 'react'

export function EmptyPage({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div
      className={cx(
        'tw-flex tw-items-center tw-justify-center tw-h-screen tw-px-8',
        className,
      )}
    >
      {children}
    </div>
  )
}
