import { MediaEmbedEditing, Widget } from 'ckeditor5'

export class MediaEditing extends MediaEmbedEditing {
  static get requires() {
    return [Widget]
  }

  init() {
    super.init()
    this._defineSchema()
    this._defineConverters()
  }

  _defineSchema() {
    const { schema } = this.editor.model

    schema.extend('media', {
      allowAttributes: ['width'],
    })
  }

  _defineConverters() {
    const { conversion } = this.editor

    conversion.for('downcast').add((dispatcher) => {
      dispatcher.on('attribute:width:media', (_event, data, conversionApi) => {
        const viewWriter = conversionApi.writer
        const viewElement = conversionApi.mapper.toViewElement(data.item)

        // If the width was removed (set to null), remove the style from the view.
        if (data.attributeNewValue) {
          viewWriter.setStyle('width', data.attributeNewValue, viewElement)
        } else {
          viewWriter.removeStyle('width', viewElement)
        }
      })
    })
  }
}
