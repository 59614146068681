import { sendTestEmail } from '@blissbook/application/actions'
import { useSession } from '@blissbook/application/hooks'
import { Button } from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import { addToast } from '@blissbook/ui/util/toaster'
import React from 'react'

export const TestEmailButton = ({ disabled, fromEmail, ...props }) => {
  const session = useSession()
  const toEmail = session.email

  const message = [
    'Sent test email',
    fromEmail && `from ${fromEmail}`,
    toEmail && `to ${toEmail}`,
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <Button
      {...props}
      disabled={disabled || !toEmail}
      onClick={async () => {
        try {
          await sendTestEmail({ fromEmail })
          addToast(message, { type: 'success' })
        } catch (error) {
          handleError(error, 'Test Email Failed!')
        }
      }}
      type='button'
    >
      Send a Test Email
    </Button>
  )
}
