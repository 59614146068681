import {
  Alignment,
  AutoImage,
  BalloonToolbar,
  Bold,
  CloudServices,
  type EditorConfig,
  Essentials,
  Font,
  Heading,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  Indent,
  IndentBlock,
  Italic,
  LinkEditing,
  List,
  MediaEmbedToolbar,
  Mention,
  Paragraph,
  PictureEditing,
  RemoveFormat,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from 'ckeditor5'

import {
  Comments,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeEditing,
  RealTimeCollaborativeRevisionHistory,
  RealTimeCollaborativeTrackChanges,
  RevisionHistory,
  SlashCommand,
  TrackChanges,
  TrackChangesData,
} from 'ckeditor5-premium-features'

import { AnnotationEditing } from './Annotation'
import { HeadingsEditing } from './Headings'
import { ImageEditing } from './Image'
import { MediaEditing } from './Media'
import { PdfEditing } from './Pdf'
import { ReadMoreEditing } from './ReadMore'

export const basePlugins = [
  Alignment,
  AnnotationEditing,
  AutoImage,
  BalloonToolbar,
  Bold,
  Essentials,
  Font,
  Heading,
  HeadingsEditing,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageEditing,
  Indent,
  IndentBlock,
  Italic,
  LinkEditing,
  List,
  MediaEditing,
  MediaEmbedToolbar,
  Mention,
  Paragraph,
  PdfEditing,
  PictureEditing,
  ReadMoreEditing,
  RemoveFormat,
  SlashCommand,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
]

export const collabPlugins = [
  CloudServices,
  Comments,
  RealTimeCollaborativeComments,
  RealTimeCollaborativeEditing,
  RealTimeCollaborativeRevisionHistory,
  RealTimeCollaborativeTrackChanges,
  RevisionHistory,
  TrackChanges,
  TrackChangesData,
]

export const defaultConfig: EditorConfig = {
  alignment: {
    options: ['left', 'center', 'right'],
  },

  fontSize: {
    options: [
      {
        title: '2XS (10px)',
        model: '10px',
      },
      {
        title: 'Extra Small (12px)',
        model: '12px',
      },
      {
        title: 'Small (14px)',
        model: '14px',
      },
      'default',
      {
        title: 'Large (20px)',
        model: '20px',
      },
      {
        title: 'Extra Large (24px)',
        model: '24px',
      },
      {
        title: '2XL (28px)',
        model: '28px',
      },
      {
        title: '3XL (36px)',
        model: '36px',
      },
      {
        title: '4XL (48px)',
        model: '48px',
      },
      {
        title: '5XL (60px)',
        model: '60px',
      },
      {
        title: '6XL (72px)',
        model: '72px',
      },
      {
        title: '7XL (96px)',
        model: '96px',
      },
      {
        title: '8XL (120px)',
        model: '120px',
      },
    ],
    supportAllValues: true,
  },

  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph',
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
    ],
  },
}
