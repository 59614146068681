import { gql } from '@apollo/client'
import { Billing } from '@blissbook/common/billing'
import Invoice from '@blissbook/common/billing/invoice'
import { Group } from '@blissbook/common/group'
import Handbook from '@blissbook/common/handbook'
import { Integration } from '@blissbook/common/integration'
import Organization from '@blissbook/common/organization'
import { Session } from '@blissbook/common/session'
import {
  BillingFragmentDoc,
  GroupFragmentDoc,
  InvoiceFragmentDoc,
  OrganizationFragmentDoc,
} from '@blissbook/ui/application/graph'
import isArray from 'lodash/isArray'
import mapValues from 'lodash/mapValues'

export const BILLING_SUMMARY_QUERY = gql`
  query {
    billing {
      ...billing
    }
  }
  ${BillingFragmentDoc}
`

export const BILLING_DETAILS_QUERY = gql`
  query {
    billing {
      ...billing
      creditCard
    }
    invoices {
      ...invoice
    }
  }

  ${BillingFragmentDoc}
  ${InvoiceFragmentDoc}
`

export const GET_PEOPLE_COUNTS = gql`
  query getPeopleCounts {
    handbooks {
      id
      viewersCount
    }
    groups {
      ...group
    }
    organization {
      ...organization
    }
  }

  ${GroupFragmentDoc}
  ${OrganizationFragmentDoc}
`

// Query ----------------------------------------------------------------------

const QUERY_RESPONSE_TYPES = {
  billing: Billing,
  groups: Group,
  handbook: Handbook,
  handbooks: Handbook,
  integrations: Integration,
  invoices: Invoice,
  organization: Organization,
  session: Session,
}

export const parseQueryResponseData = (data) =>
  mapValues(data, (json, key) => {
    const Type = QUERY_RESPONSE_TYPES[key]
    if (!Type) return json
    return isArray(json) ? json.map(Type.fromJSON) : Type.fromJSON(json)
  })
