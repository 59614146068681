import { getDaysDiff } from '@blissbook/common/handbook/util/signatures'
import { emailSchema, textSchema } from '@blissbook/lib/document'
import { Table } from '@blissbook/lib/email/renderer'
import moment, { type MomentInput } from 'moment'
import React from 'react'
import type { CSSProperties } from 'react'
import { HandbookEmailBody, renderTextContent, toManager } from './template'
import type { HandbookEmailInput } from './types'

type ManagerDigestDate = 'due' | 'invitation'

type ManagerDigestSettings = {
  date: ManagerDigestDate
}

const getDaysDiffText = (dueDate: MomentInput) => {
  const daysDiff = getDaysDiff(dueDate)
  if (daysDiff === 1) return 'Tomorrow'
  if (daysDiff === 0) return 'Today'
  if (daysDiff === -1) return 'Yesterday'
  return daysDiff < 0 ? `${-daysDiff} days ago` : `In ${daysDiff} days`
}

const cellPadding = '.5em 1em .5em 0'

const TableHeader: React.FC<{
  style?: CSSProperties
}> = ({ style, ...props }) => (
  <th
    {...props}
    align='left'
    style={{
      ...style,
      borderBottom: '2px solid rgb(235, 238, 240)',
      padding: cellPadding,
      verticalAlign: 'top',
    }}
  />
)

const TableCell: React.FC<{
  style?: CSSProperties
}> = ({ style, ...props }) => (
  <td
    {...props}
    align='left'
    valign='top'
    style={{
      padding: cellPadding,
    }}
  />
)

const nameColumnStyle = {
  maxWidth: 300,
  minWidth: 200,
}
const nameColumn = {
  Header: () => (
    <TableHeader
      style={{ ...nameColumnStyle, width: nameColumnStyle.maxWidth }}
    >
      Name
    </TableHeader>
  ),
  Cell: ({ recipient }: { recipient: IHandbookRecipient }) => (
    <TableCell>
      <div
        children={recipient.fullName}
        style={{
          maxWidth: nameColumnStyle.maxWidth,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        title={recipient.fullName}
      />
    </TableCell>
  ),
}

const dateColumnWidth = 180
const dateColumnStyle = {
  maxWidth: dateColumnWidth,
  minWidth: dateColumnWidth,
  whitespace: 'nowrap',
  width: dateColumnWidth,
}

const invitationDateColumn = {
  Header: () => (
    <TableHeader style={dateColumnStyle}>Invited to Sign Date</TableHeader>
  ),
  Cell: ({ recipient }: { recipient: IHandbookRecipient }) => {
    const invitationDate = moment(recipient.signatureRequestedAt).startOf('day')
    return (
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {getDaysDiffText(invitationDate)} ({invitationDate.format('M/D/YY')})
      </TableCell>
    )
  },
}

const signatureDueDateColumn = {
  Header: () => (
    <TableHeader style={dateColumnStyle}>Signature Due Date</TableHeader>
  ),
  Cell: ({ recipient }: { recipient: IHandbookRecipient }) => {
    const dueDate = moment(recipient.signatureDueDate).startOf('day')
    return (
      <TableCell style={{ whiteSpace: 'nowrap' }}>
        {getDaysDiffText(dueDate)} ({dueDate.format('M/D/YY')})
      </TableCell>
    )
  },
}

const emptyColumn = {
  Header: () => <TableHeader style={{ width: 0 }} />,
  Cell: () => <TableCell />,
}

const getColumns = (digestSettings: ManagerDigestSettings) =>
  [
    nameColumn,
    digestSettings.date === 'invitation' && invitationDateColumn,
    digestSettings.date === 'due' && signatureDueDateColumn,
    emptyColumn,
  ].filter(Boolean)

const getRecipientStatus = (
  recipient: IHandbookRecipient,
  digestSettings: ManagerDigestSettings,
) => ({
  isOverdue:
    digestSettings.date === 'due' &&
    getDaysDiff(recipient.signatureDueDate) <= 0,
})

export const title = 'Manager Digest Signature Reminder'
export const to = toManager
export const subjectContent = textSchema.htmlToJSON(
  'Reminder: Some members of your staff still need to sign the <variable name="handbook.name" />',
)
export const buttonContent = textSchema.htmlToJSON(
  'Go to your Manager Dashboard',
)

export const messageContent = emailSchema.htmlToJSON(`
  <p>Hi <variable name="to.firstName"></variable>,</p>
  <p></p>
  <p>Some members of your staff still need to acknowledge their receipt of the <variable name="organization.name"></variable> <variable name="handbook.name"></variable>, which can be accessed and acknowledged at <variable name="handbook.viewingLink"></variable>.</p>
`)

export const RecipientsTable = ({
  digestSettings,
  recipients,
}: {
  digestSettings: ManagerDigestSettings
  recipients: IHandbookRecipient[]
}) => {
  const columns = getColumns(digestSettings)
  return (
    <Table id='handbook-recipients'>
      <tbody>
        <tr>
          {columns.map((column, colIndex) => (
            <column.Header key={colIndex} />
          ))}
        </tr>

        {recipients.map((recipient, index) => {
          const { isOverdue } = getRecipientStatus(recipient, digestSettings)
          return (
            <tr
              key={index}
              style={{ fontWeight: isOverdue ? 'bold' : 'normal' }}
            >
              {columns.map((column, colIndex) => (
                <column.Cell key={colIndex} recipient={recipient} />
              ))}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export type ManagerDigestEmailInput = HandbookEmailInput & {
  digestSettings: ManagerDigestSettings
  recipients: IHandbookRecipient[]
}

export function renderEmail({
  buttonContent,
  digestSettings,
  subjectContent,
  messageContent,
  recipients,
  ...props
}: ManagerDigestEmailInput) {
  const { handbook } = props
  return {
    subject: renderTextContent(subjectContent, props),
    body: (
      <HandbookEmailBody
        {...props}
        authUrl={handbook.url + '/manager'}
        buttonContent={buttonContent}
        messageContent={messageContent}
        table={
          <RecipientsTable
            digestSettings={digestSettings}
            recipients={recipients}
          />
        }
      />
    ),
  }
}
