import { Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import React from 'react'
import {
  BillingEmailLayout,
  type BillingEmailProps,
  getBillingUrl,
  getInvoicePdfUrl,
} from './template'

export const type = 'billing/receipt'

export function renderEmail(props: BillingEmailProps) {
  const { invoice, organization, to } = props
  const billingUrl = getBillingUrl(organization)
  const invoicePdfUrl = getInvoicePdfUrl(organization, invoice)
  return {
    subject: 'Blissbook Paid Invoice/Receipt',
    body: (
      <BillingEmailLayout {...props}>
        <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
        <Paragraph />
        <Paragraph>
          Thank you so much for your recent Blissbook purchase! We love working
          on Blissbook and without your investment, that wouldn’t be possible.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          A receipt for your purchase is attached. If you're an Admin on your
          Blissbook account, you can also view, download, or print this receipt
          here:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={invoicePdfUrl}>{invoicePdfUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          To see all of your subscription details, head to your subscription
          page:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={billingUrl}>{billingUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          If you have any questions or need help, just reply to this email or
          contact us at support@blissbook.com.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          Thank you - we hope Blissbook is making all your dreams come true!
        </Paragraph>
        <Paragraph />
        <Paragraph>The Blissbook Team</Paragraph>
      </BillingEmailLayout>
    ),
  }
}
