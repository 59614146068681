import type { DecoupledEditor } from 'ckeditor5'
import debounce from 'lodash/debounce'
import React, { useEffect, useState } from 'react'

function getSelectionKey(editor: DecoupledEditor) {
  const { selection } = editor.model.document
  return selection.anchor.offset + '-' + selection.focus.offset
}

export function useSelectionKey(editor: DecoupledEditor) {
  const [selectionKey, setSelectionKey] = useState(() =>
    getSelectionKey(editor),
  )

  useEffect(() => {
    function onSelectionChange() {
      const selectionKey = getSelectionKey(editor)
      setSelectionKey(selectionKey)
    }

    const handleSelectionChange = debounce(onSelectionChange, 10)

    const { selection } = editor.model.document
    selection.on('change:range', handleSelectionChange)
    return () => {
      selection.off('change:range', handleSelectionChange)
    }
  }, [editor])

  return selectionKey
}
