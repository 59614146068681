import { fromJSON } from '@blissbook/common/util/json'
// NOTE: This file should probably be done in a different way, but it has dependencies all over the place
import moment from 'moment'

declare global {
  interface Date {
    format(format: string): string
    fromNow(): string
  }

  interface DateConstructor {
    fromJSON(value: any): Date
  }
}

Date.fromJSON = (value: any) => fromJSON.date(value)

Date.prototype.format = function (format: string) {
  return moment(this).format(format)
}

Date.prototype.fromNow = function () {
  return moment(this).fromNow()
}
