import { useSavedSegments } from '@blissbook/application/graph'
import { useGroupsById } from '@blissbook/application/hooks'
import { Variant, type VariantType } from '@blissbook/lib/expression'
import { Badge, Button, Tooltip } from '@blissbook/ui/lib'
import { useClick } from '@blissbook/ui/util/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useMemo, useState } from 'react'
import { VariantSearchInput } from './people'

type VariantValue = {
  id?: number
  name: string
}

function VariantsInput({
  allowNewValues,
  className,
  onChange,
  options,
  values,
  variantType,
  ...props
}: {
  allowNewValues?: boolean
  className?: string
  onChange: (values: VariantValue[]) => void
  options: {
    groups?: boolean
    savedSegments?: boolean
  }
  values?: VariantValue[]
  variantType: VariantType
}) {
  const [isAdding, setAdding] = useState(false)
  const [name, setName] = useState<string>()
  const [searchNode, setSearchNode] = useState<HTMLInputElement>()

  const notVariants = values.map((value) => new Variant(variantType, value.id))

  // Submit the current name
  function submitName() {
    if (!name) return

    const isNewValue = !values.some(
      (v) => v.name.toLowerCase() === name.toLowerCase(),
    )
    if (allowNewValues && isNewValue) {
      const newValues = [...values]
      newValues.push({ name })
      onChange(newValues)
    }

    setName(undefined)
  }

  function handleChange(_event: InputEvent, name: string) {
    setName(name)
  }

  function handleSelect(value: VariantValue) {
    const newValues = [...values, value]
    onChange(newValues)
    setName(undefined)
  }

  function handleSubmit(event: KeyboardEvent) {
    event.preventDefault()
    submitName()
  }

  // Handle body clicks
  useClick((event) => {
    // Find input
    if (!searchNode) return

    // @ts-ignore: Determine if within input
    if (searchNode.contains(event.target)) return

    // Submit what is left
    submitName()
    setAdding(false)
  })

  const marginBottom = 4
  const marginRight = 4
  return (
    <div {...props} className={classnames(className, 'clearfix')}>
      <div css={{ marginBottom: -marginBottom }}>
        {values.map((group, index) => (
          <Tooltip content='Remove' key={index}>
            <Badge
              className='tw-uppercase'
              css={{ marginBottom, marginRight }}
              onRemove={() => {
                const newValues = [...values]
                newValues.splice(index, 1)
                onChange(newValues)
              }}
            >
              {group.name}
            </Badge>
          </Tooltip>
        ))}

        <Choose>
          <When condition={isAdding}>
            <VariantSearchInput
              autoFocus
              className='tw-inline-block'
              clearIcon={false}
              dropdownWidth={320}
              inputStyle={{
                fontSize: 12,
                width: 160,
              }}
              onChange={handleChange}
              onSelect={handleSelect}
              onEnterKey={handleSubmit}
              onTabKey={handleSubmit}
              options={options}
              notVariants={notVariants}
              placeholder=''
              ref={setSearchNode}
              searchIcon={false}
              size='sm'
              style={{ marginTop: -1 }}
              value={name}
            />
          </When>

          <Otherwise>
            <Button
              className='btn-icon btn-primary tw-inline-block tw-items-center tw-text-sm'
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                setAdding(true)
              }}
              style={{ height: 18, marginBottom }}
            >
              <FontAwesomeIcon icon='plus' />
              <If condition={!values.length}>
                <span className='tw-ml-1'>Add</span>
              </If>
            </Button>
          </Otherwise>
        </Choose>
      </div>
    </div>
  )
}

export function GroupsInput({
  groupIds,
  groupNames,
  onChangeGroupIds,
  onChangeGroupNames,
  ...props
}: {
  className?: string
  groupIds: number[]
  groupNames?: string[]
  onChangeGroupIds: (groupIds: number[]) => void
  onChangeGroupNames?: (groupNames: string[]) => void
}) {
  const groupsById = useGroupsById()

  const values = useMemo(() => {
    const values: VariantValue[] = []
    for (const id of groupIds) {
      const group = groupsById[id]
      if (group) values.push(group)
    }
    if (groupNames) {
      groupNames.forEach((name) => values.push({ name }))
    }
    return values
  }, [groupIds, groupNames, groupsById])

  return (
    <VariantsInput
      {...props}
      allowNewValues={groupNames !== undefined}
      onChange={(newValues) => {
        const newGroupIds = []
        const newGroupNames = []
        for (const value of newValues) {
          if (value.id) {
            newGroupIds.push(value.id)
          } else {
            newGroupNames.push(value.name)
          }
        }
        onChangeGroupIds(newGroupIds)
        onChangeGroupNames?.(newGroupNames)
      }}
      options={{
        groups: true,
      }}
      values={values}
      variantType='group'
    />
  )
}
