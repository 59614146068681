import {
  type IEmailOrganization,
  type IEmailPerson,
  blissbookEmailBranding,
} from '@blissbook/lib/email'
import { EmailLayout, Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import React from 'react'

export type CustomDomainEmailProps = {
  organization: IEmailOrganization
  to: IEmailPerson
}

const CustomDomainEmailLayout: React.FC = (props) => (
  <EmailLayout
    {...props}
    branding={{
      ...blissbookEmailBranding,
      footerText: (
        <>
          Blissbook: Online employee handbooks &amp; digital policy management.
          <br />
          You received this email because you're an admin on a Blissbook
          account.
        </>
      ),
    }}
  />
)

export const customDomainEmails = [
  {
    days: 1,
    renderEmail({ organization, to }: CustomDomainEmailProps) {
      return {
        subject: 'Your custom domain SSL certificate will expire tomorrow',
        body: (
          <CustomDomainEmailLayout>
            <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
            <Paragraph />
            <Paragraph>
              Your custom domain SSL certificate will expire{' '}
              <strong>TOMORROW</strong>! You can edit your custom domain and add
              a new certificate here:
            </Paragraph>
            <Paragraph />
            <Paragraph>
              <Link href={`${organization.origin}/settings`}>
                {organization.origin}/settings
              </Link>
            </Paragraph>
            <Paragraph />
            <Paragraph>
              If you have any questions or need help, please reply to this email
              or contact us at support@blissbook.com.
            </Paragraph>
            <Paragraph />
            <Paragraph>Sincerely,</Paragraph>
            <Paragraph />
            <Paragraph>The Blissbook Team</Paragraph>
          </CustomDomainEmailLayout>
        ),
      }
    },
    type: 'customDomain/expires-1-day',
  },
  {
    days: 7,
    renderEmail({ organization, to }: CustomDomainEmailProps) {
      return {
        subject: 'Your custom domain SSL certificate will expire in 1 week',
        body: (
          <CustomDomainEmailLayout>
            <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
            <Paragraph />
            <Paragraph>
              Your custom domain SSL certificate will expire in 7 days! You can
              edit your custom domain and add a new certificate here:
            </Paragraph>
            <Paragraph />
            <Paragraph>
              <Link href={`${organization.origin}/settings`}>
                {organization.origin}/settings
              </Link>
            </Paragraph>
            <Paragraph />
            <Paragraph>
              If you have any questions or need help, please reply to this email
              or contact us at support@blissbook.com.
            </Paragraph>
            <Paragraph />
            <Paragraph>Sincerely,</Paragraph>
            <Paragraph />
            <Paragraph>The Blissbook Team</Paragraph>
          </CustomDomainEmailLayout>
        ),
      }
    },
    type: 'customDomain/expires-7-days',
  },
  {
    days: 30,
    renderEmail({ organization, to }: CustomDomainEmailProps) {
      return {
        subject: 'Your custom domain SSL certificate will expire in 30 days',
        body: (
          <CustomDomainEmailLayout>
            <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
            <Paragraph />
            <Paragraph>
              Your custom domain SSL certificate will expire soon! You can edit
              your custom domain and add a new certificate here:
            </Paragraph>
            <Paragraph />
            <Paragraph>
              <Link href={`${organization.origin}/settings`}>
                {organization.origin}/settings
              </Link>
            </Paragraph>
            <Paragraph />
            <Paragraph>
              If you have any questions or need help, please reply to this email
              or contact us at support@blissbook.com.
            </Paragraph>
            <Paragraph />
            <Paragraph>Sincerely,</Paragraph>
            <Paragraph />
            <Paragraph>The Blissbook Team</Paragraph>
          </CustomDomainEmailLayout>
        ),
      }
    },
    type: 'customDomain/expires-30-days',
  },
]
