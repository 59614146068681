import { isCreditCardExpired } from '@blissbook/common/billing/creditCard'
import { Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import moment from 'moment'
import pluralize from 'pluralize'
import React from 'react'
import {
  BillingEmailLayout,
  type BillingEmailProps,
  getBillingUrl,
} from '../template'

export const type = 'billing/credit-card/expires-1-week'

export function renderEmail(props: BillingEmailProps) {
  const { billing, handbooksCount, organization, renewsAt, to } = props
  const { creditCard } = billing
  const billingUrl = getBillingUrl(organization)
  return {
    subject: 'Your Blissbook organization will likely expire in 1 week',
    body: (
      <BillingEmailLayout {...props}>
        <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
        <Paragraph />
        <Paragraph>
          It's likely that your Blissbook subscription will not be renewed on or
          around {moment(renewsAt).format('YYYY-MM-DD')}. Although we will
          attempt to charge the{' '}
          {creditCard ? `${creditCard.brand} credit card` : 'credit card'} on
          file, we expect it to fail because the card{' '}
          {isCreditCardExpired(creditCard)
            ? 'has expired'
            : 'will be expired by then'}
          .
        </Paragraph>
        <Paragraph />
        <Paragraph>
          If your subscription is not renewed, your users will no longer be able
          to access {organization.name}'s published{' '}
          {pluralize('handbook', handbooksCount)}.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          To change your credit card, click the red button on your
          organization’s subscription page:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={billingUrl}>{billingUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          If you have any questions or need help, please reply to this email or
          contact us at support@blissbook.com.
        </Paragraph>
        <Paragraph />
        <Paragraph>Thank you - we hope to continue serving you!</Paragraph>
        <Paragraph />
        <Paragraph>The Blissbook Team</Paragraph>
      </BillingEmailLayout>
    ),
  }
}
