import { AnnotationUI } from './AnnotationUI'
import { CommentUI } from './CommentUI'
import { HeadingsUI } from './HeadingsUI'
import { ImageUI } from './ImageUI'
import { IndentBlockUI } from './IndentBlockUI'
import { LinkUI } from './LinkUI'
import { MediaUI } from './MediaUI'
import { PdfUI } from './PdfUI'
import { ReadMoreUI } from './ReadMoreUI'
import { RevisionHistoryUI } from './RevisionHistoryUI'

export const uiPlugins = [
  AnnotationUI,
  CommentUI,
  HeadingsUI,
  ImageUI,
  IndentBlockUI,
  LinkUI,
  MediaUI,
  PdfUI,
  ReadMoreUI,
]

export const uiCollabPlugins = [RevisionHistoryUI]
