import { Plugin, type ViewElement, Widget, toWidget } from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'

export const pdfShowThumbnailClass = 'ck-pdf-show-thumbnail'

function getEmbedElement(figureEl: ViewElement) {
  for (const child of figureEl.getChildren()) {
    if (child.is('element') && child.name === 'oembed') {
      return child
    }
  }
}

export class PdfEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()
  }

  _defineSchema() {
    const { schema } = this.editor.model

    schema.register('pdf', {
      inheritAllFrom: '$blockObject',
      allowAttributes: ['aspectRatio', 'showThumbnail', 'url', 'width'],
    })
  }

  _defineConverters() {
    const editor = this.editor as BlissbookEditor
    const { conversion } = editor

    conversion.for('upcast').elementToElement({
      view: {
        name: 'figure',
        classes: 'pdf',
      },
      model(figureEl, { writer }) {
        const oembedEl = getEmbedElement(figureEl)
        if (!oembedEl) return null

        const aspectRatio = oembedEl.getStyle('aspect-ratio') as string
        const showThumbnail = oembedEl.hasClass(pdfShowThumbnailClass)
        const url = oembedEl.getAttribute('url') as string
        const width = figureEl.getStyle('width') as string

        return writer.createElement('pdf', {
          aspectRatio,
          showThumbnail,
          url,
          width,
        })
      },
    })

    conversion.for('dataDowncast').elementToStructure({
      model: 'pdf',
      view: (modelElement, { writer }) => {
        const aspectRatio = modelElement.getAttribute('aspectRatio') as string
        const showThumbnail = modelElement.getAttribute(
          'showThumbnail',
        ) as boolean
        const url = modelElement.getAttribute('url') as string
        const width = modelElement.getAttribute('width') as string

        const media = writer.createContainerElement('oembed', {
          class: showThumbnail ? pdfShowThumbnailClass : null,
          style: `aspect-ratio: ${aspectRatio}`,
          url,
        })

        return writer.createContainerElement(
          'figure',
          {
            class: 'pdf',
            style: `width: ${width}`,
          },
          [media],
        )
      },
    })

    conversion
      .for('editingDowncast')
      .elementToElement({
        model: 'pdf',
        view: (modelElement, { writer: viewWriter }) => {
          const aspectRatio = modelElement.getAttribute('aspectRatio') as string
          const showThumbnail = modelElement.getAttribute(
            'showThumbnail',
          ) as boolean
          const url = modelElement.getAttribute('url') as string
          const width = modelElement.getAttribute('width') as string
          const thumbnailUrl = url + '/thumbnail.png'

          const pdfView = viewWriter.createRawElement(
            'div',
            {
              class: `ck-pdf-viewer tw-h-full tw-w-full ${showThumbnail ? pdfShowThumbnailClass : ''}`,
              style: `aspect-ratio: ${aspectRatio};`,
            },
            (divElement) => {
              const timerId = setInterval(() => {
                editor.renderPdf(
                  {
                    width: divElement.offsetWidth,
                    thumbnailUrl: showThumbnail ? thumbnailUrl : undefined,
                    url,
                  },
                  divElement,
                )

                // Once remove from the DOM, stop the interval
                if (!document.contains(divElement)) {
                  clearInterval(timerId)
                }
              }, 1000)
            },
          )

          const figureEl = viewWriter.createContainerElement(
            'figure',
            {
              class: 'pdf',
              style: `width: ${width}`,
            },
            pdfView,
          )

          viewWriter.setCustomProperty('pdf', true, figureEl)

          return toWidget(figureEl, viewWriter, {
            hasSelectionHandle: true,
            label: 'pdf',
          })
        },
      })
      .add((dispatcher) => {
        dispatcher.on('attribute:width:pdf', (_event, data, conversionApi) => {
          const viewWriter = conversionApi.writer
          const viewElement = conversionApi.mapper.toViewElement(data.item)

          // If the width was removed (set to null), remove the style from the view.
          if (data.attributeNewValue) {
            viewWriter.setStyle('width', data.attributeNewValue, viewElement)
          } else {
            viewWriter.removeStyle('width', viewElement)
          }
        })
      })
      .add((dispatcher) => {
        dispatcher.on(
          'attribute:aspectRatio:pdf',
          (_event, data, conversionApi) => {
            const viewWriter = conversionApi.writer
            const viewElement = conversionApi.mapper.toViewElement(
              data.item,
            ) as ViewElement

            // If the width was removed (set to null), remove the style from the view.
            const embedElement = viewElement.getChild(1)
            if (!embedElement) return

            if (data.attributeNewValue) {
              viewWriter.setStyle(
                'aspect-ratio',
                data.attributeNewValue,
                embedElement,
              )
            } else {
              viewWriter.removeStyle('aspect-ratio', embedElement)
            }
          },
        )
      })
  }
}
