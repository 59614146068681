import { isValidCKEditorMediaUrl } from '@blissbook/ui/ckeditor/components'
// @ts-ignore: WIP imports
import { Field, Link, Modal } from '@blissbook/ui/lib'
import { Video, videoSources } from '@blissbook/ui/lib/video'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import compoundSubject from 'compound-subject'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

const sourceLabels = videoSources.map((source) => source.label)
const placeholder =
  compoundSubject(sourceLabels).delimitAll().endWith('or').make() + ' URL'

const ckeditorPlaceholder = 'Enter a supported media URL'

export const MediaPickerModal = Modal.wrap<{
  onChange: (value: string | undefined) => void
  value: string
  videoOnly?: boolean
}>(({ onChange, value, videoOnly, ...props }) => {
  const label = videoOnly ? 'Video' : 'Media'
  const { onClose } = props

  function onSubmit({ url }: { url: string }) {
    if (videoOnly) {
      url = Video.fromUrl(url).url
    }
    onChange(url)
    onClose()
  }

  const urlSchema = Yup.string()
    // @ts-ignore: custom httpsUrl is not typed
    .httpsUrl()
    .required()
    .test('is-valid-url', 'Invalid media URL', (url: string) => {
      return !videoOnly ? isValidCKEditorMediaUrl(url) : true
    })

  const videoSchema = urlSchema.test(
    'is-video-url',
    'Invalid Video URL',
    (url: string) =>
      videoOnly ? Video.fromUrl(url) : isValidCKEditorMediaUrl(url),
  )

  const validationSchema = Yup.object().shape({
    url: videoOnly ? videoSchema : urlSchema,
  })

  return (
    <Modal.Component {...props} width={500}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{value ? `Edit ${label}` : `Add ${label}`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            initialValues={{
              url: value,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form noValidate onSubmit={handleSubmit}>
                <fieldset disabled={isSubmitting}>
                  <Field
                    autoFocus
                    help={
                      <Link
                        className='btn-icon btn-icon-text'
                        href='https://help.blissbook.com/en/articles/1699964-add-video-to-your-handbook'
                      >
                        <FontAwesomeIcon
                          className='tw-mr-2'
                          icon={['far', 'question-circle']}
                        />
                        Supported Media
                      </Link>
                    }
                    label={`${label} URL`}
                    name='url'
                    placeholder={videoOnly ? placeholder : ckeditorPlaceholder}
                    type='text'
                  />

                  <Modal.Submit isSubmitting={isSubmitting}>
                    {value ? `Update ${label}` : `Add ${label}`}
                  </Modal.Submit>
                </fieldset>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal.Content>
    </Modal.Component>
  )
})
