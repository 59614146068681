type MediaProvider = {
  name: string
  url: RegExp[]
  html?: (match: RegExpMatchArray) => string
}

function generateIframeHTML(
  embedUrl: string,
  paddingBottom = '56.2493%',
): string {
  return (
    '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: ' +
    paddingBottom +
    ';">' +
    `<iframe src="${embedUrl}" ` +
    'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
    'frameborder="0" allowfullscreen allow="autoplay; encrypted-media">' +
    '</iframe>' +
    '</div>'
  )
}

export const VideoMediaProviders: MediaProvider[] = [
  {
    name: 'dailymotion',
    url: [/dailymotion\.com\/video\/(\w+)/, /dai\.ly\/(\w+)/],
    html: (match) =>
      generateIframeHTML(`https://www.dailymotion.com/embed/video/${match[1]}`),
  },
  {
    name: 'youtube',
    url: [
      /(?:m\.)?youtube\.com\/watch\?v=([\w-]+)(?:&t=(\d+))?/,
      /youtube\.com\/shorts\/([\w-]+)(?:\?t=(\d+))?/,
      /youtube\.com\/embed\/([\w-]+)(?:\?t=(\d+))?/,
      /youtu\.be\/([\w-]+)(?:\?t=(\d+))?/,
    ],
    html: (match) => {
      const id = match[1]
      const time = match[2] ? `?start=${match[2]}` : ''
      return generateIframeHTML(`https://www.youtube.com/embed/${id}${time}`)
    },
  },
  {
    name: 'vimeo',
    url: [
      /vimeo\.com\/(\d+)/,
      /vimeo\.com\/[^\r]+\/video\/(\d+)/,
      /vimeo\.com\/album\/[^\r]+\/video\/(\d+)/,
      /vimeo\.com\/groups\/[^\r]+\/videos\/(\d+)/,
      /player\.vimeo\.com\/video\/(\d+)/,
    ],
    html: (match) =>
      generateIframeHTML(`https://player.vimeo.com/video/${match[1]}`),
  },
  {
    name: 'vidyard',
    url: [
      /vidyard\.com\/watch\/([\w-]+)/,
      /embed\.vidyard\.com\/share\/([\w-]+)/,
    ],
    html: (match) => {
      const id = match[1]
      const embedUrl = `https://play.vidyard.com/${id}`
      return generateIframeHTML(embedUrl)
    },
  },
  {
    name: 'wistia',
    url: [
      /wistia\.com\/medias\/([\w-]+)/,
      /fast\.wistia\.com\/embed\/medias\/([\w-]+)/,
    ],
    html: (match) => {
      const id = match[1]
      const embedUrl = match[0].includes('medias')
        ? `https://fast.wistia.com/embed/medias/${id}`
        : match[0]
      return generateIframeHTML(embedUrl)
    },
  },
  {
    name: 'Loom',
    url: [
      /^https:\/\/(?:www\.)?loom\.com\/(?:share|embed)\/([a-zA-Z0-9-]+)(?:\?(?:sid=[a-zA-Z0-9-]+)?(?:&)?(?:hideEmbedTopBar=true)?(?:&)?(?:t=\d+s)?)?$/,
    ],
  },
]

export const DocMediaProviders: MediaProvider[] = [
  {
    name: 'googleDocs',
    url: [
      /docs\.google\.com\/document\/d\/([\w-]+)/,
      /docs\.google\.com\/spreadsheets\/d\/([\w-]+)/,
      /docs\.google\.com\/presentation\/d\/([\w-]+)/,
      /docs\.google\.com\/forms\/d\/([\w-]+)/,
      /docs\.google\.com\/drawings\/d\/([\w-]+)/,
    ],
    html: (match) => {
      const id = match[1]
      let embedUrl = ''
      if (match[0].includes('document'))
        embedUrl = `https://docs.google.com/document/d/${id}/preview`
      else if (match[0].includes('spreadsheets'))
        embedUrl = `https://docs.google.com/spreadsheets/d/${id}/preview`
      else if (match[0].includes('presentation'))
        embedUrl = `https://docs.google.com/presentation/d/${id}/preview`
      else if (match[0].includes('forms'))
        embedUrl = `https://docs.google.com/forms/d/e/${id}/viewform`
      else if (match[0].includes('drawings'))
        embedUrl = `https://docs.google.com/drawings/d/${id}/preview`
      return generateIframeHTML(embedUrl)
    },
  },
  {
    name: 'lucidchart',
    url: [
      /lucid\.app\/lucidchart\/([\w-]+)\/edit/,
      /lucid\.app\/documents\/embeddedchart\/([\w-]+)/,
    ],
    html: (match) =>
      generateIframeHTML(
        `https://lucid.app/documents/embeddedchart/${match[1]}`,
      ),
  },
  {
    name: 'instagram',
    url: [/instagram\.com\/p\/(\w+)/, /instagram\.com\/reel\/(\w+)/],
  },
  {
    name: 'googleMaps',
    url: [/google\.com\/maps/, /goo\.gl\/maps/, /maps\.app\.goo\.gl/],
  },
  {
    name: 'flickr',
    url: [/flickr\.com/],
  },
  {
    name: 'facebook',
    url: [/facebook\.com/],
  },
]

export const CKEditorMediaProviders: MediaProvider[] = [
  ...VideoMediaProviders,
  ...DocMediaProviders,
]

function mergeAllUrls(platforms: MediaProvider[]): RegExp[] {
  return platforms.flatMap((platform) => platform.url)
}

const validCKEditorsUrlPatterns: RegExp[] = mergeAllUrls(CKEditorMediaProviders)

export const isValidCKEditorMediaUrl = (url: string): boolean => {
  return validCKEditorsUrlPatterns.some((pattern) => pattern.test(url))
}
