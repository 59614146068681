import { gql } from '@apollo/client'
import { PreferencesFragmentDoc } from '@blissbook/ui/application/graph'
import { client } from '@blissbook/ui/util/apollo'

export const setPreferences = async (input) =>
  client.mutate({
    mutation: gql`
      mutation setPreferences($input: PreferencesInput!) {
        setPreferences(input: $input) {
          ...preferences
        }
      }

      ${PreferencesFragmentDoc}
    `,
    variables: { input },
  })

export const hideProTip = async (tipId) => {
  const { data } = await client.mutate({
    mutation: gql`
      mutation hideProTip($tipId: String!) {
        hideProTips: hideProTip(tipId: $tipId)
      }
    `,
    variables: { tipId },
  })

  return data.hideProTips
}
