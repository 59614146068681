import type { BlissbookEditor } from '../../BlissbookEditor'

export function showFakeSelection(editor: BlissbookEditor, markerName: string) {
  const { model } = editor

  model.change((writer) => {
    let range = model.document.selection.getFirstRange()
    if (range.start.isAtEnd) {
      const startPosition = range.start.getLastMatchingPosition(
        ({ item }) => !model.schema.isContent(item),
        { boundaries: range },
      )
      range = writer.createRange(startPosition, range.end)
    }

    writer.addMarker(markerName, {
      usingOperation: false,
      affectsData: false,
      range,
    })
  })
}

export function hideFakeSelection(editor: BlissbookEditor, markerName: string) {
  const { model } = editor

  model.change((writer) => {
    writer.removeMarker(markerName)
  })
}
