import { Link, Paragraph } from '@blissbook/lib/email/renderer'
import { renderGreeting } from '@blissbook/lib/name'
import pluralize from 'pluralize'
import React from 'react'
import {
  BillingEmailLayout,
  type BillingEmailProps,
  getBillingUrl,
} from '../template'

export const type = 'billing/check/renewal-7-days'

export function renderEmail(props: BillingEmailProps) {
  const { billing, organization, to } = props
  const billingUrl = getBillingUrl(organization)
  return {
    subject: 'Your Blissbook subscription renews in ~7 days',
    body: (
      <BillingEmailLayout {...props}>
        <Paragraph>{renderGreeting(to.fullName)},</Paragraph>
        <Paragraph />
        <Paragraph>
          The subscription for your Blissbook organization {organization.name}{' '}
          will automatically renew in about 7 days.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          To continue using Blissbook as-is, do nothing. We’ll send an invoice
          ON your renewal date (not before because your user count may change
          between now and then).
        </Paragraph>
        <Paragraph />
        <Paragraph>
          To change your subscription or to see a cost estimate, visit your
          organization’s subscription page and click the Change Plan button:
        </Paragraph>
        <Paragraph />
        <Paragraph>
          <Link href={billingUrl}>{billingUrl}</Link>
        </Paragraph>
        <Paragraph />
        <Paragraph>
          You may want to change your subscription if your organization’s growth
          rate will change a lot over the next{' '}
          {pluralize('month', billing.termMonths, true)}.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          If you have any questions or need help, please reply to this email or
          contact us at support@blissbook.com.
        </Paragraph>
        <Paragraph />
        <Paragraph>
          Thank you - we hope Blissbook continues to serve you well!
        </Paragraph>
        <Paragraph />
        <Paragraph>The Blissbook Team</Paragraph>
      </BillingEmailLayout>
    ),
  }
}
