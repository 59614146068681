import { emailSchema, textSchema } from '@blissbook/lib/document'
import { buildTextContent, renderHandbookEmail, toViewer } from './template'
import type { BuildHandbookNotificationArgs, HandbookEmailInput } from './types'

export const type = 'changes-view'
export const to = toViewer
export const subjectContent = textSchema.htmlToJSON(
  'The <variable name="handbook.name"></variable> has been updated',
)
export const ccManagers = false
export const buttonContent = textSchema.htmlToJSON(
  'View the <variable name="handbook.name"></variable>',
)

export const buildNotification = (args: BuildHandbookNotificationArgs) => {
  const { from, handbook, hasAnnotations } = args
  return {
    subjectContent: buildTextContent(subjectContent, args),
    buttonContent: buildTextContent(buttonContent, args),
    messageContent: emailSchema.htmlToJSON(`
      <p>Hi <variable name="to.firstName"></variable>,</p>
      <p></p>
      <p>Our ${handbook.name} has been updated.</p>
      <p></p>
      <p>Please take some time to view this most recent version. To do so, click the button above.</p>
      <p></p>
      ${
        !hasAnnotations
          ? ''
          : `
        <p>When you open the ${handbook.name}, a guide will walk you through all the major changes so you can quickly understand what's different.</p>
        <p></p>
      `
      }
      <p>Have a great day,</p>
      <p>${from ? from.fullName : 'Your HR Team'}</p>
    `),
  }
}

export function renderEmail(props: HandbookEmailInput) {
  const { handbook } = props
  return renderHandbookEmail({
    ...props,
    authUrl: handbook.publishedUrl,
  })
}
