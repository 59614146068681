export type ImageShapeId = 'circle' | 'rounded'

export type ImageShape = {
  id: ImageShapeId
  label: string
  className: string
}

export const roundedImageShape: ImageShape = {
  id: 'rounded',
  label: 'Rounded',
  className: 'img-rounded',
}

export const circleImageShape: ImageShape = {
  id: 'circle',
  label: 'Circle',
  className: 'img-circle',
}

export const imageShapes: ImageShape[] = [roundedImageShape, circleImageShape]

export const imageShapesById = new Map(
  imageShapes.map((shape) => [shape.id, shape]),
)
