import { getFirstName, getLastName } from '@blissbook/lib/name'
import { immerable } from 'immer'
import { fromJSON, mapJSONValues } from '../util/json'
import { HandbookNotification } from './notification'
import Session from './session'
import Signature from './signature'

export default class Recipient {
  static get ATTRIBUTES() {
    return {
      createdAt: (v) => fromJSON.date(v),
      updatedAt: (v) => fromJSON.date(v),
    }
  }

  static mapJSON(json) {
    json = mapJSONValues(json, Recipient.ATTRIBUTES)

    // All documentation
    if (json.notifications)
      json.notifications = json.notifications.map(HandbookNotification.fromJSON)
    if (json.sessions) json.sessions = json.sessions.map(Session.fromJSON)
    if (json.signatures)
      json.signatures = json.signatures.map(Signature.fromJSON)

    return json
  }

  static fromJSON(json) {
    return Object.assign(new Recipient(), Recipient.mapJSON(json))
  }

  get firstName() {
    return getFirstName(this.fullName)
  }

  get lastName() {
    return getLastName(this.fullName)
  }

  get url() {
    return `/handbooks/${this.handbookId}/people/${this.id}`
  }
}

Recipient[immerable] = true
