import { cx } from '@emotion/css'
import type { DecoupledEditor } from 'ckeditor5'
import React, { forwardRef } from 'react'

export type CKEditorSelectionProps = {
  className?: string
  editor: DecoupledEditor
  rangeClassName?: string
}

export const CKEditorSelection = forwardRef<
  HTMLDivElement,
  CKEditorSelectionProps
>(({ editor, rangeClassName = 'tw-bg-aqua-500/20', ...props }, ref) => {
  // Get the bounding rect of the editor container
  const editorContainer = editor.ui.getEditableElement()
  const editorRect = editorContainer.getBoundingClientRect()

  // Convert to DOM range
  const rects: DOMRect[] = []
  const { view } = editor.editing
  const { domConverter } = editor.editing.view
  const { selection } = view.document
  const ranges = selection.getRanges()

  for (const range of ranges) {
    const domRange = domConverter.viewRangeToDom(range)
    const rangeRects = domRange.getClientRects()
    rects.push(...rangeRects)
  }

  return (
    <div {...props}>
      {[...rects].map((rect, index) => (
        <div
          className={cx('tw-absolute', rangeClassName)}
          key={index}
          ref={index === 0 ? ref : undefined}
          style={{
            top: rect.top - editorRect.top,
            left: rect.left - editorRect.left,
            width: rect.width,
            height: rect.height,
          }}
        />
      ))}
    </div>
  )
})
