import { useEmailTemplate } from '@blissbook/application/hooks'
import { fromVariables, toVariables } from '@blissbook/lib/blissbook'
import { replaceVariables } from '@blissbook/lib/document'
import { employeeDigest } from '@blissbook/lib/email/templates/digests'
import type { ButtonProps } from '@blissbook/ui/lib'
import snakeCase from 'lodash/snakeCase'
import React from 'react'
import {
  EditNotificationModal,
  type NotificationValue,
} from './EditNotificationModal'

export const employeeDigestTemplateId = snakeCase(employeeDigest.type)

export type EditEmployeeDigestModalProps = {
  fromName?: string
  isSendingEmails?: boolean
  submitButtonText?: string
  title: string
  to: string
}

export type EditEmployeeDigestModalButtonProps = ButtonProps & {
  modalProps?: EditEmployeeDigestModalProps
  onSubmit?: (values: Partial<NotificationValue>) => void
}

export function EditEmployeeDigestModalButton({
  modalProps: { fromName, submitButtonText, ...modalProps },
  onSubmit,
  ...props
}: EditEmployeeDigestModalButtonProps) {
  const { isSendingEmails } = modalProps
  const { showSentByBlissbook } = employeeDigest
  const emailTemplate = useEmailTemplate(employeeDigestTemplateId)
  const { buttonContent, ccManagers, subjectContent } = emailTemplate
  const messageContent = replaceVariables(emailTemplate.messageContent, {
    'from.fullName': fromName,
  })

  const messageVariables = []
  if (!isSendingEmails) {
    messageVariables.push(...fromVariables)
  }
  messageVariables.push(...toVariables)

  return (
    <EditNotificationModal.Button
      {...props}
      modalProps={{
        ...modalProps,
        callToAction: {
          beforeEl: employeeDigest.renderBeforeCallToAction('Some' as any),
        },
        fromName: `${fromName || `(Sender's Name)`} (via Blissbook)`,
        messageVariables,
        notification: {
          buttonContent,
          ccManagers,
          messageContent,
          subjectContent,
        },
        onSubmit,
        showSentByBlissbook,
        submitButtonText,
      }}
    />
  )
}
