import { useState } from 'react'
import type { AssetPickerContext } from './AssetPickerContext'

export type PickImageFunction = (
  initialValue?: string,
) => Promise<string | undefined>

export type ImagePickerModalState = {
  initialValue?: string
  onChange?: (value: string) => void
  onClose: () => void
}

export type ImagePicker = {
  modalState: ImagePickerModalState
  pickValue: PickImageFunction
}

export function useImagePicker(context: AssetPickerContext): ImagePicker {
  const [modalState, setModalState] = useState<ImagePickerModalState>()

  const pickValue = (initialValue?: string) =>
    new Promise<string>((resolve) => {
      setModalState({
        ...context,
        initialValue,
        onChange: resolve,
        onClose: () => {
          setModalState(undefined)
          resolve(undefined)
        },
      })
    })

  return { modalState, pickValue }
}
