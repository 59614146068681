import { immerable } from 'immer'
import { fromJSON, mapJSONValues } from '../util/json'

// biome-ignore lint/complexity/noStaticOnlyClass: requires some rework, for models it has to be classes
export default class Session {
  static get ATTRIBUTES() {
    return {
      createdAt: (v) => fromJSON.date(v),
      lastViewedAt: (v) => fromJSON.date(v),
    }
  }

  static mapJSON(json) {
    return mapJSONValues(json, Session.ATTRIBUTES)
  }

  static fromJSON(json) {
    return Object.assign(new Session(), Session.mapJSON(json))
  }
}

Session[immerable] = true
