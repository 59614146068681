import {
  type Node,
  emailSchema,
  renderText,
  textSchema,
} from '@blissbook/lib/document'
import {
  type EmailBranding,
  type IEmailOrganization,
  type IEmailPerson,
  formatAuthUrl,
} from '@blissbook/lib/email'
import {
  EmailLayout,
  Paragraph,
  prependEmailMargin,
  renderCcEmailsNote,
  renderEmailContent,
} from '@blissbook/lib/email/renderer'
import { getFirstName } from '@blissbook/lib/name'
import pluralize from 'pluralize'
import React from 'react'

export const showSentByBlissbook = true
export const type = 'employee-digest'

export const renderBeforeCallToAction = (nonCompliantCount: number) => (
  <Paragraph style={{ fontWeight: 'bold' }}>
    You have {pluralize('document', nonCompliantCount, true)} to sign.
  </Paragraph>
)

export const subjectContent = textSchema.htmlToJSON(
  'Reminder: You still need to sign something!',
)

export const ccManagers = false

export const buttonContent = textSchema.htmlToJSON('Click here to View & Sign')

export const messageContent = emailSchema.htmlToJSON(`
  <hr />
  <p></p>
  <p>Hi <variable name="to.firstName"></variable>!</p>
  <p></p>
  <p>We noticed you haven't electronically signed all required acknowledgement forms yet. Signing is mandatory per the terms of your employment.</p>
  <p></p>
  <p>We appreciate your prompt action on this—it helps our organization achieve our compliance goals.</p>
  <p></p>
  <p>Have a great day,</p>
  <p></p>
  <p><strong>— <variable name="from.fullName"></variable></strong></p>
`)

export type EmployeeDigestPerson = IEmailPerson & {
  handbookStatistics: {
    nonCompliantCount: number
  }
}

export type EmployeeDigestVariablesArgs = {
  from: IEmailPerson
  organization: IEmailOrganization
  to: EmployeeDigestPerson
}

export type EmployeeDigestEmailProps = EmployeeDigestVariablesArgs & {
  branding: EmailBranding
  buttonContent: Node[]
  ccEmails?: string[]
  isAuthEnabled: boolean
  messageContent: Node[]
  subjectContent: Node[]
}

function buildVariables({
  from,
  organization,
  to,
}: EmployeeDigestVariablesArgs) {
  return {
    'from.firstName': getFirstName(from.fullName),
    'from.fullName': from.fullName,
    'organization.name': organization.name,
    'to.firstName': getFirstName(to.fullName),
    'to.fullName': to.fullName,
  }
}

export function renderEmail({
  branding,
  buttonContent,
  ccEmails,
  from,
  isAuthEnabled,
  messageContent,
  organization,
  subjectContent,
  to,
}: EmployeeDigestEmailProps) {
  const { nonCompliantCount } = to.handbookStatistics
  const ccEmailsNote = ccEmails && renderCcEmailsNote(ccEmails)
  const variables = buildVariables({ from, organization, to })
  return {
    subject: renderText(subjectContent),
    body: (
      <EmailLayout
        branding={branding}
        callToAction={{
          beforeEl: renderBeforeCallToAction(nonCompliantCount),
          href: formatAuthUrl(organization.origin, isAuthEnabled),
          text: renderText(buttonContent, variables),
        }}
        showSentByBlissbook={showSentByBlissbook}
      >
        {renderEmailContent(messageContent, { variables })}

        {ccEmailsNote && prependEmailMargin(ccEmailsNote)}
      </EmailLayout>
    ),
  }
}
